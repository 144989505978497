import { camelCase, startCase } from 'lodash'
import {
  FantasyLeagueType,
  FantasyPosition,
  FantasyDisplayPosition,
  FantasyRanker,
  FantasyScoringType,
  FantasyTag,
  FantasyScoringTypeEnum,
  FantasySharedAdpSourceEnum,
} from './fantasy'
import { Routes } from '../routes/routes'

export const ROSTER_POSITION_SORT_ORDER = {
  // Have this in the sort order of the roster positions
  QB: 1,
  RB: 2,
  WR: 3,
  TE: 4,
  FLX: 5,
  SFLX: 6,
  K: 7,
  DST: 8,
  BN: 9,
}

export const TEAM_ID_MAP = {
  1: 'ARI',
  2: 'ATL',
  3: 'BAL',
  4: 'BUF',
  5: 'CAR',
  6: 'CHI',
  7: 'CIN',
  8: 'CLE',
  9: 'DAL',
  10: 'DEN',
  11: 'DET',
  12: 'GB',
  13: 'HOU',
  14: 'IND',
  15: 'JAX',
  16: 'KC',
  17: 'MIA',
  18: 'MIN',
  19: 'NE',
  20: 'NO',
  21: 'NYG',
  22: 'NYJ',
  23: 'LV',
  24: 'PHI',
  25: 'PIT',
  26: 'LAR',
  27: 'LAC',
  28: 'SF',
  29: 'SEA',
  30: 'TB',
  31: 'TEN',
  32: 'WAS',
}

const POSITIONS = [
  FantasyPosition.QB,
  FantasyPosition.RB,
  FantasyPosition.WR,
  FantasyPosition.TE,
  FantasyPosition.FLEX,
  FantasyPosition.K,
  FantasyPosition.DEF,
]
// Todo: Remove IN_SEASON_POSITIONS when super flex position filter is implemented for draft experience
const IN_SEASON_POSITIONS = [
  FantasyPosition.QB,
  FantasyPosition.RB,
  FantasyPosition.WR,
  FantasyPosition.TE,
  FantasyPosition.FLEX,
  FantasyPosition.SFL,
  FantasyPosition.K,
  FantasyPosition.DEF,
]

const IN_SEASON_MATCHUP_POSITIONS = [FantasyPosition.WR, FantasyPosition.TE]

export const ORDERED_FANTASY_POSITIONS = [
  FantasyDisplayPosition.QB,
  FantasyDisplayPosition.RB,
  FantasyDisplayPosition.WR,
  FantasyDisplayPosition.TE,
  FantasyDisplayPosition.FLEX,
  FantasyDisplayPosition.SUPER_FLEX,
  FantasyDisplayPosition.WRRB_FLEX,
  FantasyDisplayPosition.REC_FLEX,
  FantasyDisplayPosition.K,
  FantasyDisplayPosition.DST,
  FantasyDisplayPosition.DEF,
  'D',
]

const TAGS = [
  FantasyTag.PFF_VALUE,
  FantasyTag.BREAKOUT,
  FantasyTag.SLEEPER,
  // FantasyTag.LEAGUE_WINNER,
  FantasyTag.BUST,
  FantasyTag.INJURY_HISTORY,
  FantasyTag.ROOKIE,
  // FantasyTag.PLUS_UTILIZATION,
  // FantasyTag.TALENTED_AF,
]

const API_RANKERS_TYPE: any = {
  [FantasyRanker.PFF_CONSENSUS]: 'consensus',
  [FantasyRanker.DWAIN]: 'dwain',
  [FantasyRanker.IAN]: 'ian',
  [FantasyRanker.KEVIN]: 'kevin',
  [FantasyRanker.NATHAN]: 'nathan',
}

const RANKERS = [
  FantasyRanker.PFF_CONSENSUS,
  FantasyRanker.NATHAN,
  FantasyRanker.KEVIN,
  FantasyRanker.IAN,
  FantasyRanker.DWAIN,
]

const RANKERS_DESCRIPTION: any = {
  [FantasyRanker.PFF_CONSENSUS]:
    'PFF Consensus brings the approach from all of our analysts together into a comprehensive pick-by-pick draft strategy.',
  [FantasyRanker.DWAIN]:
    'Dwain focuses on kicking ass. More specifically, he focuses on positional strategy and feels strongly that each season is different, and every draft is a dynamic, living organism.',
  [FantasyRanker.IAN]: `Ian's approach is to win. It's a great day to be great.`,
  [FantasyRanker.KEVIN]:
    'Kevin is a numbers guy who believes in a methodical approach to player rankings and strategy, but is also willing to shift his opinions as the facts change.',
  [FantasyRanker.NATHAN]:
    'Nathan paints a more optimistic draft scenario that focuses on making sure the right player is picked at each draft slot to optimize the chances of winning.',
}

const API_SCORING_TYPE: any = {
  [FantasyScoringType.PPR]: 'ppr',
  [FantasyScoringType.ZERO_POINT_FIVE_PPR]: 'halfPPR',
  [FantasyScoringType.STANDARD]: 'standard',
}

const SCORING_TYPE = [
  FantasyScoringType.PPR,
  FantasyScoringType.ZERO_POINT_FIVE_PPR,
  FantasyScoringType.STANDARD,
  // TODO: Add back when ready
  // FantasyScoringType.TE_PREMIUM,
]

// TODO: Clean up
const API_LEAGUE_TYPE: any = {
  [FantasyLeagueType.STANDARD]: 'standard',
  [FantasyLeagueType.TWO_QB]: 'superflex',
}

const SHARED_SCORING_TYPE_ENUMS = Object.values(FantasyScoringTypeEnum)

const SHARED_ADP_SOURCE_ENUMS = Object.values(FantasySharedAdpSourceEnum)

const LEAGUE_TYPE = [
  FantasyLeagueType.STANDARD,
  FantasyLeagueType.TWO_QB,
  // TODO: Add back when ready
  // FantasyLeagueType.DYNASTY_STARTUP,
  // FantasyLeagueType.DYNASTY_ROOKIE,
  // FantasyLeagueType.IDP,
]

type RankEmojiMapType = {
  [key: string]: string
}

export const RANKER_EMOJI_MAP: RankEmojiMapType = {
  [FantasyRanker.PFF_CONSENSUS.toLowerCase()]: '',
  [FantasyRanker.NATHAN.toLowerCase()]: '🏆',
  [FantasyRanker.IAN.toLowerCase()]: '🧠',
  [FantasyRanker.KEVIN.toLowerCase()]: '📊',
  [FantasyRanker.DWAIN.toLowerCase()]: '💰',
}

// Label generation
const FORMATTED_TAGS = TAGS.map((tag) => {
  if (tag === FantasyTag.PFF_VALUE) {
    return { label: 'PFF Value', value: FantasyTag.PFF_VALUE }
  }
  if (tag === FantasyTag.TALENTED_AF) {
    return { label: 'Talented AF', value: FantasyTag.TALENTED_AF }
  }

  return {
    label: startCase(camelCase(tag)),
    value: tag,
  }
})

export type TransformResponse = {
  label: string
  value: string
  icon?: string
  iconGroup?: string
  customSize?: number
  isDisabled?: boolean
  notClickable?: boolean
}
const transformOption = (value: string): TransformResponse => ({ label: value, value })

export const FANTASY_LABELS = {
  POSITIONS: POSITIONS.map(transformOption).map((item) => {
    if (item.value === FantasyPosition.DEF) {
      return {
        label: FantasyPosition.DST,
        value: item.value,
      }
    }

    return item
  }),
  // Todo: Remove IN_SEASON_POSITIONS when super flex position filter is implemented for draft experience
  IN_SEASON_POSITIONS: IN_SEASON_POSITIONS.map(transformOption).map((item) => {
    if (item.value === FantasyPosition.DEF) {
      return {
        label: FantasyPosition.DST,
        value: item.value,
      }
    }
    return item
  }),
  IN_SEASON_RANKERS: RANKERS.filter((ranker) => ranker !== FantasyRanker.DWAIN && ranker !== FantasyRanker.KEVIN).map(
    (value: string): TransformResponse => {
      if (value === FantasyRanker.PFF_CONSENSUS) {
        return {
          label: `Consensus`,
          icon: 'pff-logo',
          customSize: 24,
          value: API_RANKERS_TYPE[value],
        }
      }
      return {
        label: `${RANKER_EMOJI_MAP[value.toLowerCase()]} ${value}`,
        value: API_RANKERS_TYPE[value],
      }
    }
  ),
  IN_SEASON_MATCHUP_POSITIONS: IN_SEASON_MATCHUP_POSITIONS.map(transformOption),
  RANKERS: RANKERS.map((value: string): TransformResponse => {
    if (value === FantasyRanker.PFF_CONSENSUS) {
      return {
        label: `Consensus`,
        icon: 'pff-logo',
        customSize: 24,
        value: API_RANKERS_TYPE[value],
      }
    }
    return {
      label: `${RANKER_EMOJI_MAP[value.toLowerCase()]} ${value}`,
      value: API_RANKERS_TYPE[value],
    }
  }),
  SCORING_TYPE: SCORING_TYPE.map((value: string): { label: string; value: string } => ({
    label: value,
    value: API_SCORING_TYPE[value],
  })),
  LEAGUE_TYPE: LEAGUE_TYPE.map((value: string): { label: string; value: string } => ({
    label: value,
    value: value === FantasyLeagueType.TWO_QB ? value : API_LEAGUE_TYPE[value],
  })),
  TAGS: FORMATTED_TAGS.map(({ label, value }): { label: string; value: string; icon: string; iconGroup: string } => ({
    label,
    icon: value.replaceAll('_', '-'),
    // TODO: FIX - We get circular dependency issues if we reference the SVGGroup directly here🙄
    iconGroup: 'fantasy',
    value,
  })),
}

const EXCLUDED_COMPARE_POSITIONS = [FantasyPosition.K, FantasyPosition.DEF]

// TODO For now removing the My Team tab. Will include it when it is ready
const IN_SEASON_HEADER_TABS = ['My Team', 'Rankings', 'Start/Sit', 'Matchups', 'Waiver Targets']
const MY_TEAM_HEADER_TABS = ['Matchups', 'Waiver Targets']
const SEASON: number = 2024
const NUM_OF_GAMES_PER_TEAM: number = 17

const THIRD_PARTY_ERRORS = {
  // internal error cases
  SYNCED_LEAGUE_NOT_FOUND: `Synced league not found.`,
  UNSUPPORTED_FANTASY_PROVIDER: `Unsupported fantasy provider.`,
  // special cases in UI
  UNSUPPORTED_POSITION: 'League contains unsupported position.',
  USERS_TEAM_UNKNOWN: `Couldn't determine team, please resync league.`,
  // not found errors
  LEAGUE_NOT_FOUND: `League not found.`,
  ROSTER_NOT_FOUND: `Roster not found.`,
  OPPONENT_ROSTER_NOT_FOUND: `Opponent roster not found.`,
  MATCHUP_NOT_FOUND: `Matchup not found for provided week.`,
  // out of season errors
  LEAGUE_NOT_IN_SEASON: `The requested league isn't in season.`,
  DRAFT_NOT_IN_SEASON: `League does not have a draft this season.`,
  // missing secrets
  MISSING_ESPN_SECRETS: `Request missing required ESPN access tokens.`,
  MISSING_YAHOO_SECRETS: `Request missing required Yahoo access tokens.`,
  // Yahoo specific
  YAHOO_FAILED_REFRESH: `Failed to refresh Yahoo access tokens.`,
  YAHOO_TOKEN_SAVE: `Failed to save Yahoo access tokens.`,
  YAHOO_URL_REQUIRED: 'A valid Yahoo league URL is required.',
  YAHOO_CANNOT_DETERMINE_LEAGUE_ID: `Could not determine the league ID from the provided Yahoo URL.`,
  // ESPN specific
  ESPN_KEEPERS_NON_SNAKE_DRAFT: 'Cannot calculate keeper pick positions for non-snake drafts.',
  ESPN_URL_REQUIRED: 'A valid ESPN league URL is required.',
  ESPN_CANNOT_DETERMINE_LEAGUE_ID: 'Could not determine the league ID from the provided ESPN URL.',
  // Sleeper specific
  SLEEPER_USER_NOT_FOUND: `Sleeper user not found.`,
  SLEEPER_DRAFT_NOT_FOUND: `Sleeper draft not found.`,
  SLEEPER_DRAFT_PICKS_NOT_FOUND: 'Sleeper draft picks not found.',
  SLEEPER_LEAGUE_ID_REQUIRED: 'A valid Sleeper league ID is required.',
  SLEEPER_LEAGUE_USERNAME_REQUIRED: 'A valid Sleeper username is required.',
  SLEEPER_LEAGUE_NOT_FOUND: 'Sleeper league not found.',
  // other
  // METHOD_NOT_IMPLEMENTED - used by methods not supported by all providers
  METHOD_NOT_IMPLEMENTED: `Method not implemented.`,
}

const IDP_RANKINGS_URL = 'https://www.pff.com/fantasy/week/rankings?scoring=preset_ppr_idp_bal'

export const FANTASY_CONSTANTS = {
  TEAMS: TEAM_ID_MAP,
  POSITIONS,
  TAGS,
  RANKERS,
  SCORING_TYPE,
  LEAGUE_TYPE,
  API_SCORING_TYPE,
  API_LEAGUE_TYPE,
  API_RANKERS_TYPE,
  SHARED_SCORING_TYPE_ENUMS,
  SHARED_ADP_SOURCE_ENUMS,
  RANKER_EMOJI_MAP,
  RANKERS_DESCRIPTION,
  EXCLUDED_COMPARE_POSITIONS,
  IN_SEASON_HEADER_TABS,
  IN_SEASON_POSITIONS,
  FANTASY_RANKERS: FantasyRanker,
  SEASON,
  NUM_OF_GAMES_PER_TEAM,
  MY_TEAM_HEADER_TABS,
  THIRD_PARTY_ERRORS,
  IDP_RANKINGS_URL,
}

export const FANTASY_PREP_OPTIONS = [
  {
    title: 'Rankings',
    path: Routes.DraftRankings,
    subText: 'Award winning rankings by PFF analyst Nathan Jahnke.',
  },
  {
    title: 'Live Draft Assistant',
    path: Routes.LiveDraftAssistant,
    subText: 'Enter draft room to begin to prep for your draft by favoriting players.',
  },
  {
    title: 'Fantasy Mock Draft Simulator',
    path: Routes.MockDraftSimulator,
    subText: 'Simulate your fantasy draft to prepare your pick strategy.',
  },
  {
    title: 'Cheat Sheet',
    path: Routes.Cheatsheet,
    subText: 'Prepare for your draft by prepping your cheat sheet.',
  },
  {
    title: 'Fantasy News & Articles',
    path: Routes.Articles,
    subText: 'Prepare to dominate your fantasy league with up-to-date fantasy news and content.',
  },
]

export const MODAL_LOCATION = {
  FMDS: 'FMDS',
  LDA: 'LDA',
  LEAGUE_SYNC: 'League Sync',
}

export const HAS_SEEN_SYNC_LEAGUE_MODAL = 'hasSeenSyncLeagueModal'
export const HAS_SEEN_HIGH_TRAFFIC_MODAL = 'hasSeenHighTrafficModal'

export const PAYWALL_REDIRECT_URL = 'https://www.pff.com/join?product=plus&referrer=fmds'
export const CHROME_EXTENSION_URL =
  'https://chromewebstore.google.com/detail/pff-for-your-fantasy-leag/enpmekoogcpafokplcfodchijhmnmbdm'
