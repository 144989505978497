import { SeasonStatsTable, SeasonStatPosition, SeasonStat } from '@pff-consumer/schema'

export const getSeasonStatsTableData = (
  seasonStats: SeasonStat[],
  position: SeasonStatPosition
): { title: string; stats: SeasonStat[] }[] => {
  const seasonStatKeyMap = seasonStats.reduce(
    (acc: Record<string, SeasonStat>, stat: SeasonStat) => ({ ...acc, [stat.statKey]: stat }),
    {}
  )

  const statsConfig = SeasonStatsTable[position]
  return statsConfig.map((item) => {
    const stats = item.statKeys.map((statKey) => seasonStatKeyMap[statKey])
    return {
      title: item.title,
      stats,
    }
  })
}
