import { AddListenerOverloads } from '@reduxjs/toolkit/dist/listenerMiddleware/types'
import { FantasyPosition, FantasyPositions } from '@pff-consumer/schema'
import { convertToStringId } from '@pff-consumer/utils/lib/fantasy/draft/convertToStringId'
import FuzzySearch from 'fuzzy-search'
import { orderBy as lodashOrderBy, round } from 'lodash'
import { sorters } from '@pff-consumer/utils'
import { Keeper } from '@pff-consumer/schema/fantasy-live-draft/keeper'
import { consumerApi } from '../../lib/consumer-api/consumer-api'
import { fantasySlice, mutateRankings, RankingsEntity } from '../../lib/fantasy/fantasy.slice'

export const addFantasyRankingsListeners = (startListening: AddListenerOverloads<unknown>) => {
  startListening({
    actionCreator: fantasySlice.actions.updateFilterPosition,
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()
      listenerApi.dispatch(fantasySlice.actions.updateMutatingRankings(true))
      await listenerApi.delay(300)

      listenerApi.dispatch(mutateRankings())
    },
  })

  startListening({
    actionCreator: fantasySlice.actions.updateFilterTags,
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()
      listenerApi.dispatch(fantasySlice.actions.updateMutatingRankings(true))
      await listenerApi.delay(300)

      listenerApi.dispatch(mutateRankings())
    },
  })

  startListening({
    actionCreator: fantasySlice.actions.updateSorting,
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()
      listenerApi.dispatch(fantasySlice.actions.updateMutatingRankings(true))
      await listenerApi.delay(300)

      listenerApi.dispatch(mutateRankings())
    },
  })

  startListening({
    actionCreator: fantasySlice.actions.setSearchTerm,
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()
      listenerApi.dispatch(fantasySlice.actions.updateMutatingRankings(true))
      listenerApi.dispatch(mutateRankings())
    },
  })

  startListening({
    actionCreator: mutateRankings,
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()

      // TODO Fix type issues that cause a cyclical dependency when fixed
      const state = listenerApi.getState()
      // @ts-expect-error
      const { sortBy, orderBy } = state.fantasy.sort
      // @ts-expect-error
      const { position: positionFilters, search, tags } = state.fantasy.filters
      // @ts-expect-error
      const unfilteredPlayers = state.fantasy.unfilteredRankings

      const {
        showDraftedPlayers,
        draftPicks,
        selectedSuggestedPlayerIndex,
        playerNextRoundAvailability,
        playerPickGrades,
        leagueKeepers,
        // @ts-expect-error
      } = state.fantasy.draftRoom

      // Now that we are doing the filtering on the FE, make sure we look for right DST type
      const clonedPositionFilters = [...positionFilters].map((position) => {
        if (position === FantasyPosition.DEF) {
          return FantasyPosition.DST
        }
        return position
      })

      // If we are in flex mode, we need to filter for WR, RB, and TE
      const positionFiltersNeededForRankings = clonedPositionFilters.reduce(
        (rankingsPositionFilters: Array<FantasyPositions>, position: FantasyPositions) => {
          if (position === FantasyPosition.FLEX) {
            rankingsPositionFilters.push(FantasyPosition.WR, FantasyPosition.RB, FantasyPosition.TE)
          }
          rankingsPositionFilters.push(position)
          return rankingsPositionFilters
        },
        []
      )

      const filteredPlayers = unfilteredPlayers
        .filter((player: RankingsEntity) => {
          const isValidPosition =
            positionFiltersNeededForRankings.length === 0
              ? true
              : positionFiltersNeededForRankings.includes(player.position)
          const isDrafted = draftPicks.includes(convertToStringId(player.id, player.position === FantasyPosition.DST))
          const isKeeper = leagueKeepers.find(
            (keeper: Keeper) =>
              keeper.playerId === convertToStringId(player.id, player.position === FantasyPosition.DST)
          )
          const canDraftPlayer = !isDrafted && !isKeeper
          const shouldShow = showDraftedPlayers ? true : canDraftPlayer

          // Filtering by tag is happening at the FE. So we need to filter the rankings object before rendering
          return (
            shouldShow &&
            isValidPosition &&
            (tags.length > 0 ? player.tags.some((tag: string) => tags.includes(tag)) : true)
          )
        })
        .map((player: RankingsEntity) => {
          const { id: playerId, position } = player
          const convertedId = convertToStringId(playerId, position === FantasyPosition.DST)
          const corePickGradeValue = playerPickGrades?.[convertedId] || 0
          const pickGrade = round(corePickGradeValue * 100)

          const suggestedPlayerSelectedIndex = selectedSuggestedPlayerIndex
          const nextRoundAvailability = playerNextRoundAvailability?.[playerId]?.[suggestedPlayerSelectedIndex] || 0

          // Pick grade and availability percentage are rendered using playerId in the component, but needed here for sorting
          return {
            ...player,
            pickGrade,
            nextRoundAvailability,
          }
        })

      // Create new searcher whenever the data changes
      const fuzzySearcher = new FuzzySearch(
        filteredPlayers || [],
        ['first_name', 'last_name', 'long_name', 'teamName', 'teamCity'],
        {
          caseSensitive: false,
        }
      )

      // Stop fuzzy searching breaking when users use apostrophes that are in players names, so strip them out and fuzzy will still work
      const prunedSearchTerm = search.replaceAll('’', '').replaceAll(`'`, '').replaceAll('`', '')
      const filteredResults = fuzzySearcher.search(prunedSearchTerm)

      // @ts-expect-error
      const sortedRankings = lodashOrderBy(filteredResults.slice(), [sorters[sortBy]], [orderBy])
      listenerApi.dispatch(fantasySlice.actions.updateMutatedRankings(sortedRankings))
    },
  })

  startListening({
    actionCreator: fantasySlice.actions.updateMutatedRankings,
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()

      listenerApi.dispatch(fantasySlice.actions.updateMutatingRankings(false))
    },
  })

  startListening({
    matcher: consumerApi.endpoints.getRankingsForMds.matchFulfilled,
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()

      listenerApi.dispatch(fantasySlice.actions.updateUnfilteredRankings(action.payload.players))
      listenerApi.dispatch(fantasySlice.actions.updateRankingsUpdatedTime(action.payload.lastUpdatedAt))
      listenerApi.dispatch(mutateRankings())
    },
  })
}
