import { AddListenerOverloads } from '@reduxjs/toolkit/dist/listenerMiddleware/types'
import type {
  GetNextCpuPicksRequest,
  GetNextPickInfoRequest,
  LeagueSettingsStarters,
  MDSLeagueSettings,
} from '@pff-consumer/schema'
import { FantasyLeagueType } from '@pff-consumer/schema'
import { fantasySlice, fetchRankings, mutateRankings } from '../../lib/fantasy/fantasy.slice'
import type { RootState } from '../store.fantasy'
import { consumerApi } from '../../lib/consumer-api/consumer-api'
import { fantasyLdaMdsApi } from '../../lib/fantasy-lda-mds/fantasy-lda-mds-api'
import { getPositionsThatCanBeDrafted } from '../utils/get-positions-that-can-be-drafted'
import { transformLeagueSettingsForMds } from '../utils/transform-league-settings-for-mds'

export const addFantasyDraftRoomSpecificListeners = (startListening: AddListenerOverloads<unknown>) => {
  // Step: Wait for user to start draft. This is assumed when we update the draft room settings
  startListening({
    actionCreator: fantasySlice.actions.updateDraftRoomLeagueSettings,
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()

      listenerApi.dispatch(fantasySlice.actions.updateDraftRoomSuggestionsLoading(true))
      // Fetch players
      listenerApi.dispatch(fantasyLdaMdsApi.endpoints.initialize.initiate({ body: { leagueSettings: action.payload } }))
    },
  })

  // Step: Wait for the initialize endpoint call to finish and invoke next pick info to determine what to do
  startListening({
    matcher: fantasyLdaMdsApi.endpoints.initialize.matchFulfilled,
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()

      listenerApi.dispatch(fantasySlice.actions.updateDraftRoomPlayers(action.payload))

      listenerApi.dispatch(fantasySlice.actions.updateDraftRoomSuggestionsLoading(true))

      listenerApi.dispatch(fantasySlice.actions.fetchNextPickInfo())
    },
  })
  startListening({
    matcher: fantasyLdaMdsApi.endpoints.initialize.matchRejected,
    effect: async (action, listenerApi) => {
      listenerApi.cancelActiveListeners()
    },
  })

  startListening({
    actionCreator: fantasySlice.actions.fetchNextPickInfo,
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()

      const state = listenerApi.getState() as RootState
      const { draftPicks, leagueSettings, players, leagueKeepers } = state.fantasy.draftRoom

      /**
       * We send null picks when get keepers sent to us and have a gap in between draft picks. The models can't handle
       * null so we need to stringify the null so they can deal with it accordingly
       */

      /** const transformedDraftPicks = draftPicks.map((pick) => {
        if (pick === null) {
          return 'null'
        }
        return pick
      })* */
      // Temporarily undo until model code is on prod
      const keeperPlayerIDs = new Set(leagueKeepers.map((keeper) => keeper.playerId))
      const transformedDraftPicks = draftPicks.filter((item) => item !== null && !keeperPlayerIDs.has(item))

      const data = {
        allPlayers: action.payload || players,
        draftedPlayers: transformedDraftPicks,
        leagueSettings: transformLeagueSettingsForMds(leagueSettings as MDSLeagueSettings, leagueKeepers),
      } as GetNextPickInfoRequest

      // This determines if user is on clock or not and handles accordingly
      listenerApi.dispatch(fantasyLdaMdsApi.endpoints.getNextPickInfo.initiate({ body: data }))
    },
  })

  // Step 3: Hydrate store with next pick info
  startListening({
    matcher: fantasyLdaMdsApi.endpoints.getNextPickInfo.matchFulfilled,
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()
      listenerApi.dispatch(fantasySlice.actions.updateDraftRoomSuggestionsLoading(false))

      // Update suggestions
      listenerApi.dispatch(fantasySlice.actions.updateDraftRoomSuggestions(action.payload.suggestions))
      // Update next pick info
      listenerApi.dispatch(fantasySlice.actions.updateDraftRoomPickInfo(action.payload.pickInfo))
      // Update player pick grades
      listenerApi.dispatch(fantasySlice.actions.updateDraftRoomPlayerPickGrades(action.payload.pickGrades))
      // Update selected player index to be 0
      listenerApi.dispatch(fantasySlice.actions.updateDraftRoomSelectedSuggestedPlayerIndex(0))

      const state = listenerApi.getState() as RootState

      if (state.fantasy.draftRoom.initialLoad) {
        listenerApi.dispatch(fantasySlice.actions.updateDraftRoomInitialLoad(false))
      }
      // If draft starts with user not being first pick, get the next cpu picks
      if (
        action.payload.pickInfo &&
        action.payload.pickInfo.next_user_pick !== action.payload.pickInfo.pick &&
        !state.fantasy.draftRoom.liveDraftMode
      ) {
        const { draftPicks, leagueSettings, players, leagueKeepers } = state.fantasy.draftRoom

        const data = {
          allPlayers: players,
          draftedPlayers: draftPicks,
          leagueSettings: transformLeagueSettingsForMds(leagueSettings as MDSLeagueSettings, leagueKeepers),
        } as GetNextCpuPicksRequest
        listenerApi.dispatch(fantasyLdaMdsApi.endpoints.getNextCpuPicks.initiate({ body: data }))
      } else if (!state.fantasy.draftRoom.liveDraftMode) {
        listenerApi.dispatch(fantasySlice.actions.updateIsUserOnTheClock(true))
      }
      listenerApi.dispatch(
        fantasySlice.actions.updateDraftRoomPlayerNextRoundAvailability(action.payload.nextRoundAvailability)
      )

      // Get the filled roster
      // For LDS Keepers, user picks will also contain the Keepers. Hence using Set here.
      // For MDS, the Keepers will not be a part of user picks
      const rosterWithKeepers = [
        ...new Set([...state.fantasy.draftRoom.userPicks, ...state.fantasy.draftRoom.teamKeepers]),
      ]
      const rosterPlayers = rosterWithKeepers.map((playerId) => state.fantasy.draftRoom.playerInfoMap[playerId])

      // Get the Starter League Settings & starting count
      const startingLeagueSettings: Partial<LeagueSettingsStarters> = {}
      let startersCount = 0

      Object.entries(state.fantasy.draftRoom.leagueSettings).forEach(([key, value]) => {
        if (key.startsWith('starting_')) {
          startingLeagueSettings[key as keyof LeagueSettingsStarters] = value
          startersCount += state.fantasy.draftRoom.leagueSettings[key as keyof LeagueSettingsStarters] as number
        }
      })

      const benchCount = state.fantasy.draftRoom.leagueSettings.rounds - startersCount

      const positionsThatCanBeDrafted = getPositionsThatCanBeDrafted(
        rosterPlayers,
        startingLeagueSettings as LeagueSettingsStarters,
        benchCount
      )

      listenerApi.dispatch(fantasySlice.actions.updatePositionsAllowedToBeDrafted(positionsThatCanBeDrafted))
    },
  })

  // Update Player Info Map, whenever Unfiltered Rankings gets updated
  startListening({
    actionCreator: fantasySlice.actions.updateUnfilteredRankings,
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()
      listenerApi.dispatch(fantasySlice.actions.updateDraftRoomPlayerInfoMap(action.payload))
    },
  })

  startListening({
    actionCreator: fetchRankings,
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()

      const state = listenerApi.getState() as RootState

      const clonedFilters = { ...state.fantasy.filters }
      const isInSuperflexMode = state.fantasy.draftRoom.leagueSettings.starting_superflex > 0

      Reflect.deleteProperty(clonedFilters, 'search')
      if (isInSuperflexMode) {
        clonedFilters.leagueType = FantasyLeagueType.TWO_QB
      }

      listenerApi.dispatch(consumerApi.endpoints.getRankingsForMds.initiate(clonedFilters))
    },
  })

  startListening({
    actionCreator: fantasySlice.actions.updateDraftRoomShowDraftedPlayers,
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()
      listenerApi.dispatch(fantasySlice.actions.updateMutatingRankings(true))
      await listenerApi.delay(300)
      listenerApi.dispatch(mutateRankings())
    },
  })
}
