import { PlayerHeroStatsInSeason, PlayerHeroStatsOffSeason, SeasonStatPosition } from '@pff-consumer/schema'

export const getPlayerStatKeys = (isInSeason: boolean, position: string | undefined) => {
  if (!position) {
    return []
  }
  const statList = isInSeason ? PlayerHeroStatsInSeason : PlayerHeroStatsOffSeason
  const selectedStatList = statList[position as SeasonStatPosition]
  return selectedStatList
}
