import { PerformanceMetricStat } from '@pff-consumer/schema'

export const PlayerPositionalStatsMap: Record<string, PerformanceMetricStat[]> = {
  QB: [
    PerformanceMetricStat.PASS_YD,
    PerformanceMetricStat.RUSH_YD,
    PerformanceMetricStat.ANY_TIME_TD,
    PerformanceMetricStat.PASS_TD,
    PerformanceMetricStat.PASS_ATT,
    PerformanceMetricStat.PASS_INT,
  ],
  WR: [
    PerformanceMetricStat.RECV_YD,
    PerformanceMetricStat.RECV_REC,
    PerformanceMetricStat.ANY_TIME_TD,
    PerformanceMetricStat.LONGEST_RECEPTION,
  ],
  TE: [
    PerformanceMetricStat.RECV_YD,
    PerformanceMetricStat.RECV_REC,
    PerformanceMetricStat.ANY_TIME_TD,
    PerformanceMetricStat.LONGEST_RECEPTION,
  ],
  RB: [
    PerformanceMetricStat.RUSH_YD,
    PerformanceMetricStat.RECV_YD,
    PerformanceMetricStat.RECV_REC,
    PerformanceMetricStat.LONGEST_RECEPTION,
    PerformanceMetricStat.ANY_TIME_TD,
  ],
  HB: [
    PerformanceMetricStat.RUSH_YD,
    PerformanceMetricStat.RECV_YD,
    PerformanceMetricStat.RECV_REC,
    PerformanceMetricStat.LONGEST_RECEPTION,
    PerformanceMetricStat.ANY_TIME_TD,
  ],
  FB: [
    PerformanceMetricStat.RUSH_YD,
    PerformanceMetricStat.RECV_YD,
    PerformanceMetricStat.RECV_REC,
    PerformanceMetricStat.LONGEST_RECEPTION,
    PerformanceMetricStat.ANY_TIME_TD,
  ],
}

export const PerformanceMetricToStatKey: Record<string, string> = {
  [PerformanceMetricStat.PASS_YD]: 'Passing Yds',
  [PerformanceMetricStat.RUSH_YD]: 'Rushing Yds',
  [PerformanceMetricStat.ANY_TIME_TD]: 'Touchdowns',
  [PerformanceMetricStat.PASS_TD]: 'Passing TDs',
  [PerformanceMetricStat.PASS_ATT]: 'Pass Attempts',
  [PerformanceMetricStat.PASS_INT]: 'Interceptions',
  [PerformanceMetricStat.RECV_YD]: 'Receiving Yds',
  [PerformanceMetricStat.RECV_REC]: 'Receptions',
  [PerformanceMetricStat.LONGEST_RECEPTION]: 'Long Reception',
}

export const PerformanceMetricStatKeyToUnit: Record<string, string> = {
  pass_yd: 'YDS',
  rush_yd: 'YDS',
  any_time_td: 'TDS',
  pass_td: 'TDS',
  pass_att: 'ATTEMPTS',
  pass_int: 'INT',
  recv_yd: 'YDS',
  recv_rec: 'YDS',
  longest_reception: 'YDS',
}
