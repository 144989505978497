/**
 * 16px is our base size making 1rem 16px. All spacing increments and line-heights within the UI will be a value divisible by 4px. This will help
 * to create visual harmony and consistency throughout the product.
 *
 * When adding spacing to elements, the following increments should be used:
 */

/**
 * 🚨🚨🚨
 * DO NOT ADD OR EDIT THESE WITHOUT THE EXPLICIT PERMISSION OF EITHER ANDY BREEN OR ETHAN HEFFLER
 * 🚨🚨🚨
 */

export type Spacing = Record<'s1' | 's2' | 's3' | 's4' | 's5' | 's6' | 's8' | 's10' | 's12' | 's16', number>
export const spacing: Spacing = {
  s1: 4,
  s2: 8,
  s3: 12,
  s4: 16,
  s5: 20,
  s6: 24,
  s8: 32,
  s10: 40,
  s12: 48,
  s16: 64,
}
