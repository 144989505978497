/**
 * All colors that get referenced across any UI libraries need to be defined here. Never reference variables outlined in
 * colorPalette.ts. If the figma design you have is referencing colorPalette variables then reach out to your designer to
 * get the correct variable name
 */
import { colorPalette } from './colorPalette'

export type Colors = Record<ColorNames, string>

/* eslint sort-keys: ["error", "asc"] */
export const colors: Colors = {
  abandonButtonBackground: 'transparent',
  abandonButtonBackgroundActive: 'transparent',
  abandonButtonBorder: colorPalette.red300,
  abandonButtonBorderActive: colorPalette.red400,
  abandonButtonColor: colorPalette.red300,
  abandonButtonColorActive: colorPalette.red400,
  activeStateBackground: colorPalette.blue500,
  activeTabBorderColor: colorPalette.blue300,
  adjustLineupPlayerCardActivePlayerPosition: colorPalette.blue500,
  adjustLineupPlayerCardInActivePlayerPosition: colorPalette.gray800,
  alertBackgroundColor: colorPalette.blue500,
  alternateButtonTextColor: colorPalette.blue300,
  appLockIconColor: colorPalette.blue300,
  appLockIconColorDark: colorPalette.gray1000,
  appLockIconColorFaded: colorPalette.gray600,
  atColor: colorPalette.gray800,
  backgroundColor: colorPalette.black,
  barChartAxis: colorPalette.gray900,
  barChartLegend: colorPalette.gray200,
  barChartPathBase: colorPalette.gray1000,
  barChartPathLoser: colorPalette.gray800,
  barChartPathWinner: colorPalette.green300,
  bestSuggestionForCurrentRound: colorPalette.white,
  betSlipBackground: colorPalette.white,
  betSlipIconColor: colorPalette.black,
  betSlipSecondaryText: colorPalette.gray600,
  binIconActive: colorPalette.red300,
  binIconInactive: colorPalette.gray600,
  borderColor: colorPalette.gray1000,
  buttonBorderColor: colorPalette.gray800,
  buttonInverseTextColor: colorPalette.black,
  cardCarouselControlsBg: colorPalette.gray800,
  cardCarouselControlsHoverBg: colorPalette.gray1000,
  cardCarouselControlsIcon: colorPalette.white,
  changelogUpdateBody: colorPalette.gray300,
  clearAllText: colorPalette.red300,
  clickableActiveBackground: colorPalette.blue500,
  clickableActiveBorder: colorPalette.blue300,
  clickableBackgroundColor: colorPalette.gray1000,
  closeIconBackground: '#ffffff12',
  columnTextColor: colorPalette.gray700,
  compareSelectPlayerText: colorPalette.blue300,
  connectBackgroundBlueColor: colorPalette.blue500,
  connectBorderBlueColor: colorPalette.blue300,
  connectBorderColor: colorPalette.gray900,
  connectGameClockColor: colorPalette.red300,
  connectGameStartTimeColor: colorPalette.gray500,
  contentColor: colorPalette.gray100,
  couponAppliedBackgroundColor: colorPalette.gray1000,
  createAccountDescription: colorPalette.gray100,
  darkClickableBackgroundColor: colorPalette.black,
  darkSeparator: colorPalette.gray800,
  defaultTabColor: colorPalette.gray500,
  deleteAccountButtonBackground: colorPalette.red300,
  descriptionText: colorPalette.blue400,
  disabledBetBackground: `${colorPalette.gray1000}80`,
  draftGradePositionRank: colorPalette.gray600,
  draftPlayerAnalysis: colorPalette.gray200,
  fantasyLeagueToggleOptionBackground: colorPalette.gray900,
  fantasyPositionPill: colorPalette.blue300,
  footerLinkColor: '#9BD9FA',
  formButtonBorder: colorPalette.gray800,
  gameSimulatorLoser: colorPalette.red300,
  gameSimulatorWinner: colorPalette.green300,
  gradeColorA: '#0C5EA0',
  gradeColorABackground: '#e4edf5',
  gradeColorAMinus: '#0287a5',
  gradeColorAMinusBackground: '#e3f2f5',
  gradeColorAPlus: '#163D9C',
  gradeColorAPlusBackground: '#e5eaf4',
  gradeColorB: '#18A33A',
  gradeColorBBackground: '#e6f5e9',
  gradeColorBMinus: '#5EB90F',
  gradeColorBMinusBackground: '#edf7e5',
  gradeColorBPlus: '#00936E',
  gradeColorBPlusBackground: '#e3f3ef',
  gradeColorC: '#FEB600',
  gradeColorCBackground: '#fff8e3',
  gradeColorCMinus: '#FD9700',
  gradeColorCMinusBackground: '#fff4e3',
  gradeColorCPlus: '#F1CF00',
  gradeColorCPlusBackground: '#fdfae3',
  gradeColorD: '#E64A11',
  gradeColorDBackground: '#fcebe5',
  gradeColorDMinus: '#D6321D',
  gradeColorDMinusBackground: '#fae8e6',
  gradeColorDPlus: '#F76104',
  gradeColorDPlusBackground: '#ffeee4',
  gradeColorF: '#C61B2A',
  gradeColorFBackground: '#f9e6e8',
  headshotBackground: colorPalette.white,
  historicalPerformanceOver: colorPalette.green300,
  historicalPerformanceUnder: colorPalette.red300,
  hyperlink: colorPalette.blue200,
  inactiveBorderColor: colorPalette.gray900,
  inactiveTabColor: colorPalette.gray700,
  infoMessageTextColor: colorPalette.blue200,
  injuryDoubtful: colorPalette.orange300,
  injuryIn: colorPalette.green300,
  injuryOut: colorPalette.red300,
  injuryProbable: colorPalette.yellow300,
  injuryQuestionable: colorPalette.orange300,
  inverseBackgroundColor: colorPalette.white,
  inverseTextColor: colorPalette.black,
  lightTextColor: colorPalette.white,
  lightTextColorOpaque: `${colorPalette.white}80`,
  lineUpSuggestToBench: colorPalette.red300,
  lineUpSuggestToStart: colorPalette.green300,
  lockedWaiverTargetsBorder: colorPalette.yellow500,
  matchupActivePillBackground: colorPalette.white,
  matchupActivePillBorder: colorPalette.white,
  matchupActivePillText: colorPalette.blue300,
  matchupAverage: colorPalette.yellow300,
  matchupAverageBackground: colorPalette.yellow500,
  matchupBadgeBackground: colorPalette.gray1000,
  matchupBarBaseColor: `${colorPalette.gray700}30`,
  matchupBarFifthGradientColor: colorPalette.green400,
  matchupBarFirstGradientColor: colorPalette.gray900,
  matchupBarFourthGradientColor: colorPalette.green300,
  matchupBarNeutralFifthGradientColor: colorPalette.white,
  matchupBarNeutralFirstGradientColor: `${colorPalette.white}80`,
  matchupBarNeutralFourthGradientColor: colorPalette.gray200,
  matchupBarNeutralSecondGradientColor: colorPalette.gray600,
  matchupBarNeutralThirdGradientColor: colorPalette.gray400,
  matchupBarSecondGradientColor: colorPalette.gray500,
  matchupBarThirdGradientColor: colorPalette.green200,
  matchupBorderColor: colorPalette.gray900,
  matchupErrorBorderTop: colorPalette.yellow300,
  matchupGreat: colorPalette.green300,
  matchupGreatBackground: colorPalette.green500,
  matchupHeader: colorPalette.gray700,
  matchupInactivePillBorder: colorPalette.white,
  matchupInactivePillText: colorPalette.white,
  matchupLoser: colorPalette.gray900,
  matchupLoserBackground: colorPalette.gray1000,
  matchupPillBorder: colorPalette.gray700,
  matchupPillGradientEnd: colorPalette.gray900,
  matchupPillGradientStart: colorPalette.green500,
  matchupPlayerGradeGood: colorPalette.green200,
  matchupPlayerGradeGoodBackground: colorPalette.green500,
  matchupPlayerGradeGreat: colorPalette.green300,
  matchupPlayerGradeGreatBackground: colorPalette.green500,
  matchupPlayerGradePoor: colorPalette.gray900,
  matchupPlayerGradePoorBackground: colorPalette.gray1000,
  matchupPoor: colorPalette.red300,
  matchupPoorBackground: colorPalette.red500,
  matchupPositionBackground: colorPalette.gray1000,
  matchupTextIcon: colorPalette.white,
  matchupWinner: colorPalette.green300,
  matchupWinnerBackground: colorPalette.green500,
  mdsRecentlyDraftedBackground: colorPalette.blue300,
  modalBackground: colorPalette.gray700,
  modalCloseIconFill: colorPalette.white,
  modalPrimaryBackground: colorPalette.gray1000,
  modalSecondaryBackground: colorPalette.gray900,
  networkErrorBackground: colorPalette.red300,
  notificationDotColor: colorPalette.white,
  notificationDotRedColor: colorPalette.red300,
  optionValue: colorPalette.gray500,
  pffConnectCopyAnalysisText: colorPalette.blue300,
  pffEdgeOperatorColor: colorPalette.gray800,
  pffLiveEventHeaderBackground: colorPalette.gray1000,
  pffLiveMetricAboveHalftimeAvg: colorPalette.green300,
  pffLiveMetricBelowHalftimeAvg: colorPalette.red300,
  pffLogoColor: colorPalette.white,
  pickGradeColor: colorPalette.green300,
  pickGradeSelectedColor: colorPalette.green400,
  pillBorderColor: colorPalette.gray1000,
  pillSelectedBackgroundColor: colorPalette.blue500,
  pillSelectedBorderColor: colorPalette.blue300,
  playerAvailabilityAverageBackgroundColor: colorPalette.orange100,
  playerAvailabilityAverageColor: colorPalette.orange300,
  playerAvailabilityDecentBackgroundColor: colorPalette.yellow100,
  playerAvailabilityDecentColor: colorPalette.yellow400,
  playerAvailabilityGreatBackgroundColor: colorPalette.green100,
  playerAvailabilityGreatColor: colorPalette.green400,
  playerAvailabilityPoorBackgroundColor: colorPalette.red100,
  playerAvailabilityPoorColor: colorPalette.red300,
  playerBackgroundColor: colorPalette.gray900,
  playerCardContentBg: colorPalette.white,
  playerCardHeadshotDefaultBg: colorPalette.gray600,
  playerDetailsSeparator: colorPalette.white,
  playerExtraInfoTextColor: colorPalette.gray200,
  playerGradeAverage: colorPalette.orange300,
  playerGradeAverageBackground: colorPalette.orange500,
  playerGradeGood: colorPalette.yellow300,
  playerGradeGoodBackground: colorPalette.yellow500,
  playerGradeGreat: colorPalette.green300,
  playerGradeGreatBackground: colorPalette.green500,
  playerGradePoor: colorPalette.red300,
  playerGradePoorBackground: colorPalette.red500,
  playerHeadshotBackground: colorPalette.gray900,
  playerIconBackground: colorPalette.gray300,
  playerNarrativeTextColor: colorPalette.gray900,
  playerNarrativeTextLightColor: colorPalette.gray800,
  playerPosition: colorPalette.gray400,
  playerPositionBgColor: colorPalette.gray1000,
  playerPositionBorderColor: colorPalette.gray200,
  playerPositionTextColor: colorPalette.gray200,
  playerProfileFooterBg: colorPalette.black,
  playerProfileFooterBorder: colorPalette.blue300,
  playerProfileHeroDefaultBg: colorPalette.black,
  playerPropFilterAtSign: colorPalette.gray300,
  playerRankDetail: colorPalette.gray600,
  playerSearchClearTextColor: colorPalette.white,
  playerStatAverageColor: colorPalette.gray800,
  playerStatBackgroundColor: colorPalette.gray100,
  playerStatBarColor: colorPalette.blue200,
  playerStatDescriptionColor: colorPalette.gray900,
  playerStatLabelColor: colorPalette.gray1000,
  playerTagBackgroundColor: colorPalette.gray800,
  playerTagBorderColor: colorPalette.black,
  playerTagFillColor: colorPalette.white,
  playerTeamDetailsColor: colorPalette.gray900,
  playerTypeButtonBackgroundColor: colorPalette.gray800,
  positionQuickFilter: colorPalette.white,
  positionQuickFilterSelected: colorPalette.white,
  primaryBorderSeparator: colorPalette.blue200,
  primaryButtonBackground: colorPalette.white,
  primaryButtonBackgroundActive: colorPalette.white,
  primaryButtonBorder: colorPalette.white,
  primaryButtonBorderActive: colorPalette.white,
  primaryButtonColor: colorPalette.black,
  primaryButtonColorActive: colorPalette.gray1000,
  primaryColor: colorPalette.white,
  progressBarActiveColor: colorPalette.blue300,
  progressBarBackgroundColor: colorPalette.blue100,
  progressBarBrightColor: colorPalette.white,
  progressBarFadedColor: colorPalette.gray600,
  quaternaryButtonBackground: colorPalette.gray200,
  quaternaryButtonBackgroundActive: colorPalette.gray100,
  quaternaryButtonBackgroundHover: colorPalette.gray200,
  quaternaryButtonBorder: 'transparent',
  quaternaryButtonColor: colorPalette.blue100,
  quaternaryButtonColorActive: colorPalette.blue300,
  rankCardBorderColor: colorPalette.gray100,
  rankCardPlayerGradeDescriptionColor: colorPalette.white,
  rankCardPlayerGradeValueColor: colorPalette.white,
  rankCardSortBackgroundColor: colorPalette.gray900,
  savedArticlesInfoSeparator: colorPalette.gray300,
  scoringTypeExplainer: colorPalette.gray800,
  // secondaryBorderColor is not a button style, if styling a button use the button styles below it
  secondaryBorderColor: colorPalette.gray300,
  secondaryButtonBackground: colorPalette.black,
  secondaryButtonBackgroundActive: colorPalette.gray1000,
  secondaryButtonBorder: colorPalette.gray700,
  secondaryButtonBorderActive: colorPalette.gray800,
  secondaryButtonColor: colorPalette.gray300,
  secondaryButtonColorActive: colorPalette.gray400,
  selectedBetColor: colorPalette.white,
  selectedEventBackground: colorPalette.gray900,
  shareIcon: colorPalette.white,
  similarityBar: colorPalette.blue300,
  simulateActiveButtonColor: colorPalette.blue300,
  simulateInactiveButtonColor: colorPalette.blue500,
  simulateTeamButtonBorderColor: colorPalette.blue300,
  simulateTeamButtonColor: colorPalette.blue500,
  simulatorBorderColor: colorPalette.gray900,
  simulatorGradeBackgroundColor: colorPalette.blue300,
  sitTightDescription: colorPalette.orange400,
  skeletonBackgroundColor: colorPalette.gray900,
  skeletonForegroundColor: colorPalette.gray800,
  skipText: colorPalette.gray600,
  sliderThumbBorder: colorPalette.blue400,
  sortIconColor: colorPalette.gray800,
  spinnerButtonBackground: colorPalette.blue100,
  startSitCoachBackground: colorPalette.blue300,
  startSitCompareHeadshotBorderColor: colorPalette.white,
  startSitWinnerBorder: colorPalette.blue300,
  statBoxRankText: colorPalette.gray300,
  statsButtonBorderColor: colorPalette.blue300,
  statsButtonTextColor: colorPalette.blue300,
  subscriptionDescriptionText: colorPalette.gray600,
  subscriptionPlanInfo: colorPalette.gray700,
  subscriptionPlanPriceColor: colorPalette.gray400,
  subscriptionPlanSelectionColor: colorPalette.green300,
  suggestionBackground: colorPalette.blue500,
  suggestionBorder: colorPalette.blue300,
  suggestionNegative: colorPalette.red300,
  suggestionPositive: colorPalette.green300,
  teamGradingLowestBadgeBorder: colorPalette.gray400,
  teamOnTheClockTextColor: colorPalette.blue500,
  teamOptionHeaderClearColor: colorPalette.white,
  tertiaryButtonBackground: 'transparent',
  tertiaryButtonBackgroundActive: 'transparent',
  tertiaryButtonBorder: 'transparent',
  tertiaryButtonBorderActive: 'transparent',
  tertiaryButtonColor: colorPalette.black,
  tertiaryButtonColorActive: colorPalette.gray1000,
  textColor: colorPalette.white,
  textFieldBorderColor: colorPalette.gray200,
  tintedTextColor: colorPalette.gray500,
  toggleColor: colorPalette.white,
  toggleOffBackgroundColor: colorPalette.gray500,
  toggleOnBackgroundColor: colorPalette.green300,
  topPlayerGradePlayerContext: colorPalette.gray200,
  unselectedRadioButton: colorPalette.gray300,
  validPasswordColor: colorPalette.green300,
  warningAccent: colorPalette.yellow300,
  warningBackground: colorPalette.orange100,
  webActiveBackground: colorPalette.blue100,
  webActiveBorder: colorPalette.blue300,
  webActiveTextColor: colorPalette.blue300,
  webBorder: colorPalette.gray600,
  webBorderDark: colorPalette.gray1000,
  webBorderLight: colorPalette.gray100,
  webBorderSeparator: colorPalette.gray200,
  webBrightBackground: colorPalette.white,
  webButtonBackground: colorPalette.blue300,
  webButtonBorder: colorPalette.gray500,
  webButtonHoverBackground: colorPalette.blue400,
  webErrorColor: colorPalette.red300,
  webFadedBackground: colorPalette.gray100,
  webFadedTextColor: colorPalette.gray600,
  webFilterTextColor: colorPalette.blue300,
  webHeadingText: colorPalette.gray1000,
  webHeroFeatureBackground: colorPalette.gray800,
  webHeroGradientDark: colorPalette.black,
  webHeroGradientLight: colorPalette.gray300,
  webInactiveTabColor: colorPalette.gray700,
  webInverseTextColor: colorPalette.white,
  webLeagueShortTitle: colorPalette.gray800,
  webLinkText: colorPalette.gray700,
  webLogoBorder: colorPalette.gray400,
  webModalDescriptionColor: colorPalette.gray800,
  webModalHeaderColor: colorPalette.gray1000,
  webNewsletterSuccessText: colorPalette.green300,
  webOptionBorder: colorPalette.gray600,
  webParagraphText: colorPalette.gray900,
  webPositionPillBorder: colorPalette.gray800,
  webProductInfoBackground: colorPalette.blue100,
  webRemoveHoverBackground: colorPalette.red100,
  webShortDescription: colorPalette.gray800,
  webSubnavBackground: colorPalette.gray100,
  webSubnavHighlight: colorPalette.blue300,
  webSubnavText: colorPalette.gray800,
  webSubnavTextSelected: colorPalette.black,
  webSuccessBackground: colorPalette.green400,
  webTeamBarDefaultColor: colorPalette.gray700,
  webTextColor: colorPalette.gray1000,
  webTextColorDefault: colorPalette.black,
  webTextColorLight: colorPalette.gray800,
  webTextField: colorPalette.gray800,
  webTextFieldBorder: colorPalette.gray400,
  webTextFieldDisabled: colorPalette.gray900,
  webTextFieldPlaceholder: colorPalette.gray400,
  webVerticalSeparator: '#393c40',
  webWarningColor: colorPalette.orange300,
  whatsNewButton: colorPalette.blue300,
}

/* eslint @typescript-eslint/sort-type-constituents: ["error"] */
export type ColorNames =
  | 'abandonButtonBackground'
  | 'abandonButtonBackgroundActive'
  | 'abandonButtonBorder'
  | 'abandonButtonBorderActive'
  | 'abandonButtonColor'
  | 'abandonButtonColorActive'
  | 'activeStateBackground'
  | 'activeTabBorderColor'
  | 'adjustLineupPlayerCardActivePlayerPosition'
  | 'adjustLineupPlayerCardInActivePlayerPosition'
  | 'alertBackgroundColor'
  | 'alternateButtonTextColor'
  | 'appLockIconColor'
  | 'appLockIconColorDark'
  | 'appLockIconColorFaded'
  | 'atColor'
  | 'backgroundColor'
  | 'barChartAxis'
  | 'barChartLegend'
  | 'barChartPathBase'
  | 'barChartPathLoser'
  | 'barChartPathWinner'
  | 'bestSuggestionForCurrentRound'
  | 'betSlipBackground'
  | 'betSlipIconColor'
  | 'betSlipSecondaryText'
  | 'binIconActive'
  | 'binIconInactive'
  | 'borderColor'
  | 'buttonBorderColor'
  | 'buttonInverseTextColor'
  | 'cardCarouselControlsBg'
  | 'cardCarouselControlsHoverBg'
  | 'cardCarouselControlsIcon'
  | 'changelogUpdateBody'
  | 'clearAllText'
  | 'clickableActiveBackground'
  | 'clickableActiveBorder'
  | 'clickableBackgroundColor'
  | 'closeIconBackground'
  | 'columnTextColor'
  | 'compareSelectPlayerText'
  | 'connectBackgroundBlueColor'
  | 'connectBorderBlueColor'
  | 'connectBorderColor'
  | 'connectGameClockColor'
  | 'connectGameStartTimeColor'
  | 'contentColor'
  | 'couponAppliedBackgroundColor'
  | 'createAccountDescription'
  | 'darkClickableBackgroundColor'
  | 'darkSeparator'
  | 'defaultTabColor'
  | 'deleteAccountButtonBackground'
  | 'descriptionText'
  | 'disabledBetBackground'
  | 'draftGradePositionRank'
  | 'draftPlayerAnalysis'
  | 'fantasyLeagueToggleOptionBackground'
  | 'fantasyPositionPill'
  | 'footerLinkColor'
  | 'formButtonBorder'
  | 'gameSimulatorLoser'
  | 'gameSimulatorWinner'
  | 'gradeColorA'
  | 'gradeColorABackground'
  | 'gradeColorAMinus'
  | 'gradeColorAMinusBackground'
  | 'gradeColorAPlus'
  | 'gradeColorAPlusBackground'
  | 'gradeColorB'
  | 'gradeColorBBackground'
  | 'gradeColorBMinus'
  | 'gradeColorBMinusBackground'
  | 'gradeColorBPlus'
  | 'gradeColorBPlusBackground'
  | 'gradeColorC'
  | 'gradeColorCBackground'
  | 'gradeColorCMinus'
  | 'gradeColorCMinusBackground'
  | 'gradeColorCPlus'
  | 'gradeColorCPlusBackground'
  | 'gradeColorD'
  | 'gradeColorDBackground'
  | 'gradeColorDMinus'
  | 'gradeColorDMinusBackground'
  | 'gradeColorDPlus'
  | 'gradeColorDPlusBackground'
  | 'gradeColorF'
  | 'gradeColorFBackground'
  | 'headshotBackground'
  | 'historicalPerformanceOver'
  | 'historicalPerformanceUnder'
  | 'hyperlink'
  | 'inactiveBorderColor'
  | 'inactiveTabColor'
  | 'infoMessageTextColor'
  | 'injuryDoubtful'
  | 'injuryIn'
  | 'injuryOut'
  | 'injuryProbable'
  | 'injuryQuestionable'
  | 'inverseBackgroundColor'
  | 'inverseTextColor'
  | 'lightTextColor'
  | 'lightTextColorOpaque'
  | 'lineUpSuggestToBench'
  | 'lineUpSuggestToStart'
  | 'lockedWaiverTargetsBorder'
  | 'matchupActivePillBackground'
  | 'matchupActivePillBorder'
  | 'matchupActivePillText'
  | 'matchupAverage'
  | 'matchupAverageBackground'
  | 'matchupBadgeBackground'
  | 'matchupBarBaseColor'
  | 'matchupBarFifthGradientColor'
  | 'matchupBarFirstGradientColor'
  | 'matchupBarFourthGradientColor'
  | 'matchupBarNeutralFifthGradientColor'
  | 'matchupBarNeutralFirstGradientColor'
  | 'matchupBarNeutralFourthGradientColor'
  | 'matchupBarNeutralSecondGradientColor'
  | 'matchupBarNeutralThirdGradientColor'
  | 'matchupBarSecondGradientColor'
  | 'matchupBarThirdGradientColor'
  | 'matchupBorderColor'
  | 'matchupErrorBorderTop'
  | 'matchupGreat'
  | 'matchupGreatBackground'
  | 'matchupHeader'
  | 'matchupInactivePillBorder'
  | 'matchupInactivePillText'
  | 'matchupLoser'
  | 'matchupLoserBackground'
  | 'matchupPillBorder'
  | 'matchupPillGradientEnd'
  | 'matchupPillGradientStart'
  | 'matchupPlayerGradeGood'
  | 'matchupPlayerGradeGoodBackground'
  | 'matchupPlayerGradeGreat'
  | 'matchupPlayerGradeGreatBackground'
  | 'matchupPlayerGradePoor'
  | 'matchupPlayerGradePoorBackground'
  | 'matchupPoor'
  | 'matchupPoorBackground'
  | 'matchupPositionBackground'
  | 'matchupTextIcon'
  | 'matchupWinner'
  | 'matchupWinnerBackground'
  | 'mdsRecentlyDraftedBackground'
  | 'modalBackground'
  | 'modalCloseIconFill'
  | 'modalPrimaryBackground'
  | 'modalSecondaryBackground'
  | 'networkErrorBackground'
  | 'notificationDotColor'
  | 'notificationDotRedColor'
  | 'optionValue'
  | 'pffConnectCopyAnalysisText'
  | 'pffEdgeOperatorColor'
  | 'pffLiveEventHeaderBackground'
  | 'pffLiveMetricAboveHalftimeAvg'
  | 'pffLiveMetricBelowHalftimeAvg'
  | 'pffLogoColor'
  | 'pickGradeColor'
  | 'pickGradeSelectedColor'
  | 'pillBorderColor'
  | 'pillSelectedBackgroundColor'
  | 'pillSelectedBorderColor'
  | 'playerAvailabilityAverageBackgroundColor'
  | 'playerAvailabilityAverageColor'
  | 'playerAvailabilityDecentBackgroundColor'
  | 'playerAvailabilityDecentColor'
  | 'playerAvailabilityGreatBackgroundColor'
  | 'playerAvailabilityGreatColor'
  | 'playerAvailabilityPoorBackgroundColor'
  | 'playerAvailabilityPoorColor'
  | 'playerBackgroundColor'
  | 'playerCardContentBg'
  | 'playerCardHeadshotDefaultBg'
  | 'playerDetailsSeparator'
  | 'playerExtraInfoTextColor'
  | 'playerGradeAverage'
  | 'playerGradeAverageBackground'
  | 'playerGradeGood'
  | 'playerGradeGoodBackground'
  | 'playerGradeGreat'
  | 'playerGradeGreatBackground'
  | 'playerGradePoor'
  | 'playerGradePoorBackground'
  | 'playerHeadshotBackground'
  | 'playerIconBackground'
  | 'playerNarrativeTextColor'
  | 'playerNarrativeTextLightColor'
  | 'playerPosition'
  | 'playerPositionBgColor'
  | 'playerPositionBorderColor'
  | 'playerPositionTextColor'
  | 'playerProfileFooterBg'
  | 'playerProfileFooterBorder'
  | 'playerProfileHeroDefaultBg'
  | 'playerPropFilterAtSign'
  | 'playerRankDetail'
  | 'playerSearchClearTextColor'
  | 'playerStatAverageColor'
  | 'playerStatBackgroundColor'
  | 'playerStatBarColor'
  | 'playerStatDescriptionColor'
  | 'playerStatLabelColor'
  | 'playerTagBackgroundColor'
  | 'playerTagBorderColor'
  | 'playerTagFillColor'
  | 'playerTeamDetailsColor'
  | 'playerTypeButtonBackgroundColor'
  | 'positionQuickFilter'
  | 'positionQuickFilterSelected'
  | 'primaryBorderSeparator'
  | 'primaryButtonBackground'
  | 'primaryButtonBackgroundActive'
  | 'primaryButtonBorder'
  | 'primaryButtonBorderActive'
  | 'primaryButtonColor'
  | 'primaryButtonColorActive'
  | 'primaryColor'
  | 'progressBarActiveColor'
  | 'progressBarBackgroundColor'
  | 'progressBarBrightColor'
  | 'progressBarFadedColor'
  | 'quaternaryButtonBackground'
  | 'quaternaryButtonBackgroundActive'
  | 'quaternaryButtonBackgroundHover'
  | 'quaternaryButtonBorder'
  | 'quaternaryButtonColor'
  | 'quaternaryButtonColorActive'
  | 'rankCardBorderColor'
  | 'rankCardPlayerGradeDescriptionColor'
  | 'rankCardPlayerGradeValueColor'
  | 'rankCardSortBackgroundColor'
  | 'savedArticlesInfoSeparator'
  | 'scoringTypeExplainer'
  | 'secondaryBorderColor'
  | 'secondaryButtonBackground'
  | 'secondaryButtonBackgroundActive'
  | 'secondaryButtonBorder'
  | 'secondaryButtonBorderActive'
  | 'secondaryButtonColor'
  | 'secondaryButtonColorActive'
  | 'selectedBetColor'
  | 'selectedEventBackground'
  | 'shareIcon'
  | 'similarityBar'
  | 'simulateActiveButtonColor'
  | 'simulateInactiveButtonColor'
  | 'simulateTeamButtonBorderColor'
  | 'simulateTeamButtonColor'
  | 'simulatorBorderColor'
  | 'simulatorGradeBackgroundColor'
  | 'sitTightDescription'
  | 'skeletonBackgroundColor'
  | 'skeletonForegroundColor'
  | 'skipText'
  | 'sliderThumbBorder'
  | 'sortIconColor'
  | 'spinnerButtonBackground'
  | 'startSitCoachBackground'
  | 'startSitCompareHeadshotBorderColor'
  | 'startSitWinnerBorder'
  | 'statBoxRankText'
  | 'statsButtonBorderColor'
  | 'statsButtonTextColor'
  | 'subscriptionDescriptionText'
  | 'subscriptionPlanInfo'
  | 'subscriptionPlanPriceColor'
  | 'subscriptionPlanSelectionColor'
  | 'suggestionBackground'
  | 'suggestionBorder'
  | 'suggestionNegative'
  | 'suggestionPositive'
  | 'teamGradingLowestBadgeBorder'
  | 'teamOnTheClockTextColor'
  | 'teamOptionHeaderClearColor'
  | 'tertiaryButtonBackground'
  | 'tertiaryButtonBackgroundActive'
  | 'tertiaryButtonBorder'
  | 'tertiaryButtonBorderActive'
  | 'tertiaryButtonColor'
  | 'tertiaryButtonColorActive'
  | 'textColor'
  | 'textFieldBorderColor'
  | 'tintedTextColor'
  | 'toggleColor'
  | 'toggleOffBackgroundColor'
  | 'toggleOnBackgroundColor'
  | 'topPlayerGradePlayerContext'
  | 'unselectedRadioButton'
  | 'validPasswordColor'
  | 'warningAccent'
  | 'warningBackground'
  | 'webActiveBackground'
  | 'webActiveBorder'
  | 'webActiveTextColor'
  | 'webBorder'
  | 'webBorderDark'
  | 'webBorderLight'
  | 'webBorderSeparator'
  | 'webBrightBackground'
  | 'webButtonBackground'
  | 'webButtonBorder'
  | 'webButtonHoverBackground'
  | 'webErrorColor'
  | 'webFadedBackground'
  | 'webFadedTextColor'
  | 'webFilterTextColor'
  | 'webHeadingText'
  | 'webHeroFeatureBackground'
  | 'webHeroGradientDark'
  | 'webHeroGradientLight'
  | 'webInactiveTabColor'
  | 'webInverseTextColor'
  | 'webLeagueShortTitle'
  | 'webLinkText'
  | 'webLogoBorder'
  | 'webModalDescriptionColor'
  | 'webModalHeaderColor'
  | 'webNewsletterSuccessText'
  | 'webOptionBorder'
  | 'webParagraphText'
  | 'webPositionPillBorder'
  | 'webProductInfoBackground'
  | 'webRemoveHoverBackground'
  | 'webShortDescription'
  | 'webSubnavBackground'
  | 'webSubnavHighlight'
  | 'webSubnavText'
  | 'webSubnavTextSelected'
  | 'webSuccessBackground'
  | 'webTeamBarDefaultColor'
  | 'webTextColor'
  | 'webTextColorDefault'
  | 'webTextColorLight'
  | 'webTextField'
  | 'webTextFieldBorder'
  | 'webTextFieldDisabled'
  | 'webTextFieldPlaceholder'
  | 'webVerticalSeparator'
  | 'webWarningColor'
  | 'whatsNewButton'
