import {
  type SeasonStat,
  SeasonStatLabels,
  SeasonStatKeys,
  PlayerSeasonStats,
  SeasonStatPosition,
} from '@pff-consumer/schema'
import { getOrdinalNumber } from '../../../stats/getOrdinalNumber'
import { type PlayerStatDataProps } from '../player-stats/get-player-stat-data.props'

const getStat = (
  seasonStatKeyMap: Record<string, SeasonStat>,
  statKey: SeasonStatKeys,
  position: string,
  season: number,
  isInSeason: boolean
) => {
  const stat = seasonStatKeyMap[statKey]
  const title = isInSeason ? SeasonStatLabels[statKey] : `${SeasonStatLabels[statKey]} ${season}`

  const primaryStat = `${stat?.statValue || 0}`
  const secondaryStat =
    stat?.rankTotal && stat?.rank ? `${getOrdinalNumber(stat?.rank)}/${stat?.rankTotal} ${position}s` : 'Not Ranked'

  return { title, primaryStat, secondaryStat }
}

export const getSeasonStatCardData = ({
  isInSeason,
  seasonStats,
  position,
  season,
}: Omit<PlayerStatDataProps, 'grades' | 'matchups' | 'weekAbv'>) => {
  const statKeys = PlayerSeasonStats[position as SeasonStatPosition]

  const seasonStatKeyMap = seasonStats.reduce(
    (acc: Record<string, SeasonStat>, stat: SeasonStat) => ({ ...acc, [stat.statKey]: stat }),
    {}
  )
  const statsCardData = statKeys.map((statKey) => {
    const { title, primaryStat, secondaryStat } = getStat(seasonStatKeyMap, statKey, position, season, isInSeason)

    return {
      key: statKey,
      title,
      primaryStat,
      secondaryStat,
    }
  })

  return statsCardData
}
