export const sleeperToPffMap: { [key: string]: number } = {
  ARI: 1,
  ATL: 2,
  BAL: 3,
  BUF: 4,
  CAR: 5,
  CHI: 6,
  CIN: 7,
  CLE: 8,
  DAL: 9,
  DEN: 10,
  DET: 11,
  GB: 12,
  HOU: 13,
  IND: 14,
  JAX: 15,
  KC: 16,
  MIA: 17,
  MIN: 18,
  NE: 19,
  NO: 20,
  NYG: 21,
  NYJ: 22,
  LV: 23,
  PHI: 24,
  PIT: 25,
  LAR: 26,
  LAC: 27,
  SF: 28,
  SEA: 29,
  TB: 30,
  TEN: 31,
  WAS: 32,
  '17': 3471,
  '96': 2241,
  '391': 5180,
  '421': 4924,
  '503': 5443,
  '533': 5609,
  '650': 3794,
  '829': 6187,
  '1049': 7014,
  '1067': 7158,
  '1166': 7102,
  '1233': 7050,
  '1234': 7077,
  '1264': 7604,
  '1266': 7163,
  '1339': 7816,
  '1348': 7958,
  '1352': 7822,
  '1373': 7820,
  '1426': 7808,
  '1433': 8437,
  '1466': 7844,
  '1476': 7962,
  '1479': 7857,
  '1535': 7810,
  '1689': 8288,
  '1837': 8697,
  '1992': 8696,
  '2020': 8932,
  '2028': 8671,
  '2078': 8647,
  '2133': 8688,
  '2161': 8731,
  '2197': 8655,
  '2216': 8642,
  '2251': 8755,
  '2306': 9434,
  '2307': 9435,
  '2309': 9437,
  '2319': 9447,
  '2374': 9502,
  '2449': 9579,
  '2505': 9637,
  '2711': 9896,
  '2747': 10207,
  '2749': 9783,
  '3163': 10635,
  '3164': 10638,
  '3198': 10679,
  '3199': 10681,
  '3202': 10715,
  '3214': 10669,
  '3225': 10689,
  '3257': 10725,
  '3258': 10728,
  '3271': 10744,
  '3294': 10769,
  '3321': 10799,
  '3634': 11028,
  '3832': 11347,
  '4017': 11767,
  '4018': 11803,
  '4029': 11796,
  '4033': 11784,
  '4034': 11763,
  '4035': 11822,
  '4036': 11760,
  '4037': 11839,
  '4039': 11824,
  '4040': 11817,
  '4046': 11765,
  '4066': 11778,
  '4068': 11762,
  '4080': 11792,
  '4082': 11795,
  '4089': 11799,
  '4098': 11841,
  '4111': 11844,
  '4137': 11860,
  '4147': 11869,
  '4149': 11889,
  '4171': 11872,
  '4177': 11873,
  '4179': 11890,
  '4195': 11908,
  '4199': 11937,
  '4217': 11901,
  '4227': 11988,
  '4319': 12303,
  '4351': 12087,
  '4381': 12112,
  '4454': 12270,
  '4455': 12271,
  '4663': 12164,
  '4666': 12042,
  '4866': 45791,
  '4881': 46416,
  '4892': 46518,
  '4943': 29048,
  '4950': 47546,
  '4951': 47702,
  '4958': 8585,
  '4968': 50905,
  '4973': 47294,
  '4981': 48262,
  '4983': 48267,
  '4984': 46601,
  '4985': 27414,
  '4988': 45783,
  '4993': 47153,
  '5000': 45851,
  '5001': 47304,
  '5008': 47257,
  '5012': 47436,
  '5022': 47272,
  '5038': 26075,
  '5045': 48164,
  '5052': 34001,
  '5086': 47809,
  '5089': 46453,
  '5095': 28121,
  '5110': 44771,
  '5119': 29578,
  '5133': 47124,
  '5137': 28991,
  '5185': 48135,
  '5248': 46027,
  '5284': 29413,
  '5347': 48103,
  '5840': 50235,
  '5844': 40912,
  '5846': 47864,
  '5848': 61568,
  '5849': 38334,
  '5850': 45953,
  '5857': 42388,
  '5859': 48327,
  '5870': 39395,
  '5872': 48274,
  '5880': 47940,
  '5890': 35134,
  '5892': 45719,
  '5906': 35883,
  '5916': 41193,
  '5917': 42683,
  '5927': 48229,
  '5937': 33441,
  '5947': 47468,
  '5955': 47509,
  '5967': 27126,
  '5970': 42237,
  '5985': 47043,
  '5987': 45817,
  '5995': 45865,
  '6011': 41401,
  '6083': 57643,
  '6126': 42060,
  '6130': 40485,
  '6144': 45775,
  '6149': 25578,
  '6151': 40555,
  '6591': 47186,
  '6768': 60326,
  '6770': 28022,
  '6783': 61103,
  '6786': 61570,
  '6790': 57212,
  '6794': 61398,
  '6797': 28237,
  '6801': 61211,
  '6803': 84109,
  '6804': 40306,
  '6806': 57366,
  '6813': 57488,
  '6814': 61220,
  '6819': 29049,
  '6820': 57279,
  '6824': 61439,
  '6826': 60995,
  '6828': 57165,
  '6834': 47214,
  '6845': 40341,
  '6847': 40006,
  '6850': 26394,
  '6853': 47447,
  '6886': 42312,
  '6904': 40291,
  '6918': 57472,
  '6920': 52130,
  '6926': 47338,
  '6938': 57206,
  '6943': 55410,
  '6945': 78050,
  '6955': 22425,
  '7002': 28305,
  '7042': 33010,
  '7045': 13750,
  '7066': 23503,
  '7083': 46448,
  '7090': 48236,
  '7446': 45181,
  '7523': 77632,
  '7525': 61100,
  '7526': 84088,
  '7527': 60323,
  '7528': 57121,
  '7538': 82096,
  '7543': 57185,
  '7547': 84470,
  '7553': 83964,
  '7561': 57272,
  '7562': 77965,
  '7564': 84270,
  '7565': 84271,
  '7567': 77737,
  '7569': 61441,
  '7571': 84296,
  '7588': 77803,
  '7591': 82118,
  '7593': 57372,
  '7594': 57373,
  '7596': 84302,
  '7600': 84085,
  '7601': 62890,
  '7605': 39987,
  '7606': 61245,
  '7607': 57337,
  '7608': 41668,
  '7610': 91399,
  '7611': 98689,
  '7670': 62979,
  '7828': 60878,
  '7835': 56902,
  '7839': 82583,
  '7922': 57556,
  '7841': 83175,
  '8110': 61081,
  '8111': 61071,
  '8112': 86519,
  '8116': 66619,
  '8119': 84375,
  '8121': 84329,
  '8122': 97432,
  '8123': 83701,
  '8125': 61418,
  '8126': 101735,
  '8129': 83659,
  '8130': 75916,
  '8131': 83955,
  '8132': 83639,
  '8134': 84140,
  '8135': 99218,
  '8136': 131633,
  '8137': 99229,
  '8138': 83666,
  '8139': 83665,
  '8140': 78021,
  '8142': 78038,
  '8143': 83601,
  '8144': 84355,
  '8146': 97405,
  '8147': 97400,
  '8148': 101701,
  '8150': 97118,
  '8151': 97199,
  '8153': 99146,
  '8154': 57120,
  '8155': 97636,
  '8157': 60396,
  '8159': 55086,
  '8160': 60515,
  '8161': 60341,
  '8162': 97599,
  '8167': 66581,
  '8168': 97116,
  '8171': 84463,
  '8172': 84068,
  '8183': 77672,
  '8188': 78092,
  '8205': 83747,
  '8210': 84000,
  '8214': 84330,
  '8219': 60509,
  '8221': 77761,
  '8225': 84041,
  '8228': 98692,
  '8230': 62942,
  '8259': 77069,
  '8289': 82330,
  '8408': 62946,
  '8676': 63707,
  '8800': 57196,
  '8917': 47658,
  '9220': 97302,
  '9221': 122474,
  '9222': 123072,
  '9224': 77787,
  '9225': 123974,
  '9226': 124305,
  '9227': 122999,
  '9228': 123943,
  '9229': 124393,
  '9479': 124044,
  '9480': 101694,
  '9481': 98499,
  '9482': 122906,
  '9483': 84084,
  '9484': 104034,
  '9486': 99711,
  '9487': 129799,
  '9488': 129758,
  '9489': 84376,
  '9490': 123578,
  '9492': 99248,
  '9493': 100632,
  '9494': 122937,
  '9497': 124282,
  '9500': 122832,
  '9502': 78874,
  '9504': 124087,
  '9505': 122624,
  '9506': 123056,
  '9508': 97195,
  '9509': 123126,
  '9511': 123826,
  '9512': 121495,
  '9753': 97630,
  '9754': 123085,
  '9756': 123000,
  '9757': 123091,
  '9758': 129759,
  '9997': 97268,
  '9998': 60593,
  '9999': 83542,
  '10210': 82647,
  '10213': 101106,
  '10215': 60596,
  '10216': 99158,
  '10217': 77652,
  '10218': 122518,
  '10219': 83689,
  '10222': 78171,
  '10223': 99143,
  '10225': 97774,
  '10228': 55038,
  '10229': 97605,
  '10231': 98027,
  '10232': 84423,
  '10234': 78196,
  '10235': 99467,
  '10236': 91791,
  '10444': 84434,
  '10651': 55226,
  '10857': 60387,
  '10859': 101690,
  '10862': 83574,
  '10863': 77975,
  '10866': 60516,
  '10870': 83774,
  '10871': 84005,
  '10880': 101515,
  '10905': 123907,
  '10937': 82603,
  '11256': 169628,
  '11439': 138346,
  '11533': 156112,

  // New ones for 2024
  '19': 4332,
  '59': 3809,
  '260': 4474,
  '827': 6332,
  '862': 6162,
  '1338': 7880,
  '1379': 7912,
  '1604': 158144,
  '1642': 7915,
  '1737': 7323,
  '1800': 8677,
  '1945': 8990,
  '1984': 8931,
  '2325': 9453,
  '2334': 9462,
  '2359': 9487,
  '2381': 9509,
  '2410': 9538,
  '2446': 9576,
  '2460': 9590,
  '2471': 9601,
  '2673': 9964,
  '2755': 9786,
  '3048': 10211,
  '3161': 10636,
  '3269': 10741,
  '3286': 10760,
  '3312': 10790,
  '3343': 10821,
  '3357': 10835,
  '3362': 10841,
  '3391': 10870,
  '3451': 11161,
  '3496': 11160,
  '3668': 11051,
  '3678': 11096,
  '3852': 11371,
  '4038': 11764,
  '4054': 12078,
  '4127': 11859,
  '4144': 11855,
  '4152': 11898,
  '4157': 11874,
  '4183': 11926,
  '4189': 11929,
  '4197': 11927,
  '4218': 11932,
  '4219': 11917,
  '4234': 11993,
  '4274': 11981,
  '4314': 12206,
  '4353': 48862,
  '4435': 51837,
  '4443': 12247,
  '4464': 12282,
  '4574': 12324,
  '4602': 12193,
  '4854': 47417,
  '4972': 46569,
  '4992': 48113,
  '4995': 42130,
  '5010': 30762,
  '5026': 48228,
  '5032': 47050,
  '5046': 45747,
  '5092': 47333,
  '5102': 48181,
  '5116': 27413,
  '5121': 47961,
  '5122': 39462,
  '5124': 46420,
  '5127': 26152,
  '5128': 46517,
  '5154': 48021,
  '5171': 30887,
  '5189': 44140,
  '5209': 31615,
  '5220': 49166,
  '5230': 39417,
  '5235': 34985,
  '5257': 21956,
  '5272': 22842,
  '5285': 20994,
  '5323': 47967,
  '5374': 66915,
  '5409': 66924,
  '5432': 47908,
  '5695': 48036,
  '5773': 26104,
  '5806': 46454,
  '5854': 39517,
  '5878': 48297,
  '5903': 38265,
  '5915': 47448,
  '5938': 42247,
  '5957': 47119,
  '5965': 48209,
  '5973': 47083,
  '5980': 45914,
  '6001': 47259,
  '6012': 45859,
  '6018': 36049,
  '6032': 48143,
  '6037': 46487,
  '6039': 45769,
  '6040': 46481,
  '6069': 48110,
  '6075': 91465,
  '6109': 34934,
  '6111': 46401,
  '6136': 13725,
  '6137': 47216,
  '6147': 46501,
  '6167': 39258,
  '6171': 48152,
  '6185': 29466,
  '6219': 13701,
  '6223': 38275,
  '6233': 47123,
  '6234': 91470,
  '6239': 94375,
  '6268': 39675,
  '6271': 44920,
  '6323': 47121,
  '6379': 44889,
  '6380': 33158,
  '6386': 47996,
  '6387': 48107,
  '6402': 48341,
  '6421': 34164,
  '6427': 91479,
  '6451': 94365,
  '6453': 55003,
  '6465': 47906,
  '6528': 39235,
  '6540': 48211,
  '6553': 45038,
  '6557': 48055,
  '6588': 94379,
  '6598': 48080,
  '6650': 33285,
  '6659': 94383,
  '6662': 48369,
  '6665': 94384,
  '6694': 45734,
  '6699': 95015,
  '6744': 12258,
  '6753': 32342,
  '6798': 61697,
  '6805': 61590,
  '6823': 46444,
  '6843': 41933,
  '6849': 47800,
  '6857': 47808,
  '6865': 52266,
  '6869': 109795,
  '6873': 28070,
  '6894': 47234,
  '6908': 26408,
  '6909': 61361,
  '6927': 61664,
  '6931': 55632,
  '6956': 40294,
  '6960': 48054,
  '6963': 40448,
  '6964': 42266,
  '6970': 40411,
  '6973': 25512,
  '6989': 42295,
  '6996': 25582,
  '7009': 41247,
  '7021': 40693,
  '7032': 42134,
  '7039': 61448,
  '7048': 57184,
  '7049': 48027,
  '7050': 47034,
  '7064': 40692,
  '7075': 42257,
  '7082': 61068,
  '7087': 25859,
  '7103': 40616,
  '7106': 34914,
  '7131': 47743,
  '7135': 26448,
  '7149': 28301,
  '7191': 48012,
  '7204': 40951,
  '7216': 13756,
  '7233': 47901,
  '7237': 47964,
  '7262': 28373,
  '7308': 36271,
  '7357': 34061,
  '7427': 25705,
  '7436': 115664,
  '7438': 28729,
  '7439': 47412,
  '7496': 47861,
  '7521': 84096,
  '7529': 57133,
  '7530': 40988,
  '7531': 40217,
  '7533': 84135,
  '7534': 84137,
  '7535': 60859,
  '7536': 61182,
  '7537': 77745,
  '7541': 40689,
  '7546': 40445,
  '7554': 60896,
  '7559': 61324,
  '7568': 77887,
  '7579': 84457,
  '7583': 60555,
  '7585': 52269,
  '7587': 77986,
  '7589': 40216,
  '7595': 61577,
  '7597': 42196,
  '7599': 83740,
  '7602': 40630,
  '7619': 26287,
  '7622': 143769,
  '7694': 84025,
  '7703': 34255,
  '7713': 61522,
  '7716': 42275,
  '7720': 40547,
  '7729': 42349,
  '7733': 39861,
  '7746': 52195,
  '7751': 84147,
  '7752': 61639,
  '7757': 27948,
  '7771': 45835,
  '7789': 40986,
  '7793': 61401,
  '7794': 27303,
  '7808': 57070,
  '7809': 42873,
  '7812': 84424,
  '7842': 42353,
  '7854': 47378,
  '7891': 60994,
  '7933': 44905,
  '7943': 143780,
  '7944': 143358,
  '7946': 42058,
  '7956': 42109,
  '7989': 47663,
  '7992': 52008,
  '8002': 12659,
  '8013': 61669,
  '8025': 35639,
  '8033': 143797,
  '8041': 143786,
  '8076': 91824,
  '8094': 143812,
  '8107': 156095,
  '8114': 78148,
  '8117': 61637,
  '8118': 97989,
  '8127': 60917,
  '8145': 84029,
  '8164': 83517,
  '8170': 77885,
  '8174': 99141,
  '8176': 121926,
  '8177': 60997,
  '8178': 61457,
  '8179': 99163,
  '8180': 84292,
  '8181': 57301,
  '8194': 83773,
  '8195': 57208,
  '8197': 60915,
  '8204': 61610,
  '8206': 60417,
  '8207': 101659,
  '8208': 83714,
  '8211': 99169,
  '8220': 97119,
  '8227': 84036,
  '8235': 25276,
  '8241': 40316,
  '8247': 84065,
  '8249': 83962,
  '8250': 61811,
  '8254': 109239,
  '8255': 57385,
  '8258': 98975,
  '8411': 47972,
  '8413': 40364,
  '8414': 32933,
  '8416': 41991,
  '8423': 40399,
  '8428': 57384,
  '8475': 64310,
  '8484': 41372,
  '8500': 83974,
  '8523': 61079,
  '8527': 111900,
  '8570': 101347,
  '8583': 60492,
  '8665': 40034,
  '8674': 57639,
  '8698': 60864,
  '8732': 22373,
  '8745': 61478,
  '8755': 156151,
  '8756': 40666,
  '8757': 63718,
  '8783': 40408,
  '8799': 145197,
  '8801': 61295,
  '8809': 148900,
  '8820': 65088,
  '8831': 61765,
  '8840': 84070,
  '8849': 22777,
  '8861': 28304,
  '8915': 39879,
  '8921': 33465,
  '8924': 77872,
  '8928': 64309,
  '8931': 61359,
  '8932': 57498,
  '8938': 60504,
  '9501': 104155,
  '9230': 131770,
  '9231': 82094,
  '9495': 129519,
  '9499': 78127,
  '9510': 97187,
  '10212': 54037,
  '10214': 99697,
  '10220': 77888,
  '10221': 84288,
  '10224': 77917,
  '10226': 109811,
  '10227': 84037,
  '10233': 99470,
  '10860': 60436,
  '10861': 57490,
  '10867': 77997,
  '10869': 61271,
  '10955': 77083,
  '10983': 51708,
  '11008': 57509,
  '11024': 111276,
  '11034': 111284,
  '11046': 63721,
  '11053': 77985,
  '11056': 99691,
  '11058': 57502,
  '11060': 57293,
  '11068': 123470,
  '11070': 83782,
  '11073': 61515,
  '11077': 82587,
  '11080': 78141,
  '11082': 77918,
  '11099': 84188,
  '11104': 77777,
  '11105': 105710,
  '11113': 77943,
  '11114': 57394,
  '11139': 61501,
  '11145': 128347,
  '11157': 84425,
  '11168': 102585,
  '11169': 63583,
  '11174': 83540,
  '11184': 84165,
  '11199': 77763,
  '11201': 77881,
  '11204': 123237,
  '11209': 83930,
  '11210': 124173,
  '11245': 169776,
  '11257': 84136,
  '11266': 63659,
  '11270': 77705,
  '11276': 98214,
  '11280': 60876,
  '11292': 60545,
  '11298': 60728,
  '11299': 55123,
  '11304': 109834,
  '11306': 104136,
  '11311': 121451,
  '11312': 88745,
  '11320': 84150,
  '11347': 84465,
  '11370': 83644,
  '11371': 88685,
  '11377': 61107,
  '11378': 57209,
  '11380': 83994,
  '11381': 61004,
  '11384': 57486,
  '11387': 78030,
  '11415': 66715,
  '11421': 78734,
  '11433': 87625,
  '11435': 154173,
  '11456': 77958,
  '11474': 78187,
  '11483': 128096,
  '11493': 78741,
  '11495': 61777,
  '11505': 78972,
  '11506': 97273,
  '11508': 62982,
  '11510': 91319,
  '11520': 29557,
  '11524': 89497,
  '11540': 34983,
  '11556': 77628,
  '11558': 77618,
  '11559': 82134,
  '11560': 144622,
  '11561': 123229,
  '11562': 97408,
  '11563': 97790,
  '11564': 143969,
  '11565': 144918,
  '11566': 97993,
  '11567': 77615,
  '11568': 84363,
  '11570': 121717,
  '11571': 138299,
  '11574': 88664,
  '11575': 101072,
  '11576': 145159,
  '11577': 144138,
  '11579': 147476,
  '11580': 124176,
  '11581': 124247,
  '11582': 145632,
  '11583': 144721,
  '11584': 144970,
  '11586': 129574,
  '11588': 99595,
  '11589': 131705,
  '11590': 123926,
  '11592': 52595,
  '11593': 84006,
  '11595': 98508,
  '11596': 122616,
  '11597': 129787,
  '11598': 87645,
  '11599': 101486,
  '11600': 144717,
  '11601': 98359,
  '11603': 129433,
  '11604': 146715,
  '11605': 124225,
  '11606': 83946,
  '11607': 102500,
  '11608': 97156,
  '11610': 97108,
  '11615': 99207,
  '11616': 100894,
  '11617': 66272,
  '11618': 131905,
  '11619': 123153,
  '11620': 73774,
  '11621': 131692,
  '11623': 97623,
  '11624': 144718,
  '11625': 146712,
  '11626': 99197,
  '11627': 146567,
  '11628': 145056,
  '11629': 145773,
  '11630': 129604,
  '11631': 146760,
  '11632': 146759,
  '11633': 97399,
  '11635': 124030,
  '11636': 76366,
  '11637': 144949,
  '11638': 100660,
  '11640': 124017,
  '11643': 146934,
  '11644': 157177,
  '11645': 123904,
  '11646': 143068,
  '11647': 123727,
  '11648': 100580,
  '11649': 124031,
  '11650': 101634,
  '11651': 83806,
  '11652': 183079,
  '11653': 183080,
  '11654': 109158,
  '11655': 84226,
  '11716': 129423,
  '11748': 98847,
  '11762': 97078,
  '11773': 97097,
  '11783': 159617,
  '11786': 146646,
  '11789': 131765,
  '11792': 98965,
  '11802': 145443,
  '11806': 101710,
  '11808': 101135,
  '11815': 97996,
  '11820': 84080,
  '11821': 97288,
  '11834': 100637,
  '11848': 91401,
  '11850': 97868,
  '11851': 77685,
  '11857': 124021,
  '11862': 99708,
  '11864': 78750,
  '11867': 82535,
  '11874': 84191,
  '11885': 99204,
  '11886': 124169,
  '11895': 109617,
  '11897': 141559,
  '11900': 57427,
  '11901': 183111,
  '11906': 99232,
  '11911': 84283,
  '11913': 102587,
  '11918': 97164,
  '11921': 101130,
  '11924': 77656,
  '11925': 100867,
  '11931': 111760,
  '11940': 175505,
  '11941': 100230,
  '11942': 83589,
  '11943': 98617,
  '11946': 124037,
  '11947': 97183,
  '11949': 77073,
  '11957': 88668,
  '11959': 84248,
  '11960': 89478,
  '11961': 82111,
  '11962': 99693,
  '11963': 142677,
  '11964': 97181,
  '11969': 101641,
  '11982': 98102,
  '11989': 99154,
  '11994': 165866,
  '11995': 83516,
  '11998': 122630,
  '12005': 146137,
  '12007': 100586,
  '12009': 83543,
  '12013': 142937,
  '12015': 124212,
  '12018': 84197,
  '12026': 77800,
  '12028': 77741,
  '12029': 77969,
  '12036': 102419,
  '12045': 84079,
  '12048': 72154,
  '12050': 157609,
  '12061': 97185,
  '12062': 134552,
  '12063': 60452,
  '12066': 83705,
  '12068': 172661,
  '12070': 83949,
  '12079': 98097,
  '12080': 98030,
  '12085': 77899,
  '12086': 84497,
  '12090': 97276,
  '12097': 102592,
  '12107': 99685,
  '12113': 84072,
  '12114': 98095,
  '12118': 84057,
  '12129': 97272,
  '12133': 78087,
  '12134': 88315,
  '12135': 98298,
  '12144': 87729,
  '12149': 100827,
  '12150': 77710,
  '12151': 55059,
  '12154': 84012,
  '12156': 99857,
  '12170': 109584,
  '12171': 77714,
  '12173': 78939,
  '12185': 100974,
  '12189': 99566,
  '12190': 83529,
  '12191': 77726,
  '12197': 77689,
  '12198': 183061,
  '12210': 183105,
  '12212': 98464,
  '12215': 183123,
  '12217': 87634,
  '12218': 78579,
  '12220': 160316,
  '12222': 78128,
  '12226': 77779,
  '12231': 183082,
  '12235': 77967,
  '12237': 147209,
  '12246': 183114,
  '12250': 90868,
  '12254': 78138,
  '12256': 98500,
  '12267': 78521,
  '12270': 83581,
  '12281': 83810,
  '12356': 124298,
  '12357': 77892,
  '12360': 156849,
  '12373': 97814,
  '12374': 53746,
  '12383': 180159,
  '12385': 162910,
  '12389': 101680,
  '12392': 101722,
  '12395': 52961,
  '12398': 78938,
  '12402': 57280,
  '12412': 102408,
  '12413': 61259,
  '167': 698,
  '184': 1177,
  '289': 802,
  '455': 4933,
  '515': 5567,
  '536': 1571,
  '843': 6183,
  '957': 61695,
  '1052': 7079,
  '1192': 7060,
  '1213': 7641,
  '1343': 8000,
  '1576': 7850,
  '1875': 2408,
  '1947': 8636,
  '1999': 9000,
  '2027': 8982,
  '2036': 8640,
  '2064': 8669,
  '2130': 8675,
  '2311': 9439,
  '2330': 9458,
  '2343': 9471,
  '2350': 9478,
  '2353': 9481,
  '2389': 9517,
  '2391': 5165,
  '2393': 9521,
  '2427': 9555,
  '2617': 9994,
  '3156': 10637,
  '3160': 10639,
  '3172': 10641,
  '3174': 10643,
  '3186': 10661,
  '3220': 10683,
  '3230': 10695,
  '3233': 10698,
  '3252': 10718,
  '3256': 10723,
  '3276': 10749,
  '3303': 10780,
  '3369': 10848,
  '3546': 10898,
  '3558': 10671,
  '3969': 11759,
  '3973': 11756,
  '4032': 11772,
  '4056': 11780,
  '4059': 11766,
  '4063': 11768,
  '4070': 11785,
  '4071': 11771,
  '4081': 11791,
  '4091': 11797,
  '4118': 11831,
  '4135': 11858,
  '4136': 11854,
  '4170': 11880,
  '4180': 11868,
  '4191': 11918,
  '4327': 12126,
  '4449': 51674,
  '4960': 50892,
  '4963': 51206,
  '4967': 48526,
  '4969': 48956,
  '4976': 48698,
  '4979': 49350,
  '4990': 49684,
  '4999': 50120,
  '5015': 48457,
  '5020': 49894,
  '5021': 50126,
  '5036': 49048,
  '5041': 51222,
  '5056': 28091,
  '5061': 50084,
  '5071': 28854,
  '5210': 47774,
  '5297': 66917,
  '5318': 50966,
  '5330': 48557,
  '5332': 24904,
  '5334': 50720,
  '5341': 49511,
  '5346': 49834,
  '5348': 44485,
  '5372': 26940,
  '5375': 49196,
  '5493': 43034,
  '5580': 51028,
  '5726': 50918,
  '5816': 42741,
  '5823': 13076,
  '5839': 42905,
  '5841': 42200,
  '5847': 25991,
  '5862': 44522,
  '5871': 49605,
  '5876': 51081,
  '5882': 51172,
  '5893': 49371,
  '5894': 29407,
  '5908': 51378,
  '5912': 48500,
  '5928': 140792,
  '5944': 50954,
  '5960': 30737,
  '5991': 26316,
  '6056': 51503,
  '6065': 50727,
  '6118': 44216,
  '6119': 43089,
  '6120': 50293,
  '6124': 27271,
  '6125': 43742,
  '6141': 51276,
  '6145': 50426,
  '6179': 51055,
  '6183': 30267,
  '6214': 49779,
  '6217': 35413,
  '6218': 94351,
  '6220': 49753,
  '6291': 49408,
  '6302': 51485,
  '6315': 51045,
  '6333': 56167,
  '6419': 51189,
  '6485': 49792,
  '6711': 49116,
  '6781': 42451,
  '6782': 56436,
  '6784': 19554,
  '6788': 55775,
  '6799': 60685,
  '6802': 56369,
  '6807': 57941,
  '6809': 41817,
  '6818': 44304,
  '6836': 55586,
  '6839': 56259,
  '6868': 44550,
  '6872': 90621,
  '6880': 29623,
  '6888': 456,
  '6900': 48407,
  '6911': 2313,
  '6923': 55967,
  '6949': 50851,
  '6968': 45326,
  '7016': 43179,
  '7023': 57987,
  '7044': 49434,
  '7069': 41662,
  '7113': 43507,
  '7117': 40588,
  '7136': 55800,
  '7342': 108840,
  '7391': 27128,
  '7626': 82161,
  '7627': 76889,
  '7633': 56713,
  '7634': 57905,
  '7635': 56902,
  '7637': 77190,
  '7640': 81360,
  '7647': 58093,
  '7648': 81322,
  '7651': 58089,
  '7655': 1432,
  '7659': 77847,
  '7662': 43196,
  '7664': 82198,
  '7666': 83873,
  '7667': 83217,
  '7669': 52280,
  '7672': 81399,
  '7676': 55015,
  '7677': 42208,
  '7679': 76996,
  '7680': 56203,
  '7681': 58095,
  '7698': 76641,
  '7715': 38408,
  '7765': 43021,
  '7782': 57754,
  '7811': 83903,
  '7815': 57727,
  '7816': 56144,
  '7819': 55686,
  '7823': 55948,
  '7827': 55087,
  '8265': 98940,
  '8266': 82547,
  '8267': 99018,
  '8268': 98879,
  '8269': 94854,
  '8278': 32667,
  '8280': 77196,
  '8286': 97519,
  '8290': 100903,
  '8303': 83176,
  '8309': 81282,
  '8311': 57739,
  '8314': 57737,
  '8323': 101665,
  '8325': 56862,
  '8329': 58238,
  '8339': 102684,
  '8341': 83326,
  '8343': 55220,
  '8348': 83239,
  '8355': 98444,
  '8359': 12442,
  '8362': 101463,
  '8363': 82566,
  '8364': 72191,
  '8365': 83451,
  '8385': 98319,
  '8388': 57317,
  '8396': 81246,
  '8398': 17520,
  '8399': 56033,
  '8406': 56065,
  '8476': 56158,
  '8487': 79181,
  '8680': 57662,
  '8857': 156070,
  '8860': 55953,
  '10873': 99014,
  '10878': 101384,
  '10879': 99822,
  '10881': 131679,
  '10883': 92750,
  '10885': 129482,
  '10888': 124019,
  '10889': 82432,
  '10891': 101388,
  '10892': 120047,
  '10894': 101376,
  '10897': 97562,
  '10898': 131840,
  '10906': 83256,
  '10908': 124105,
  '10913': 484,
  '10914': 61500,
  '10916': 66894,
  '10917': 98923,
  '10919': 83241,
  '10921': 122705,
  '10923': 97694,
  '10927': 98857,
  '10933': 76729,
  '10935': 122157,
  '10936': 129767,
  '10970': 98982,
  '10974': 124326,
  '11539': 121959,
  '11573': 2282,
  '11656': 144143,
  '11658': 69996,
  '11661': 146603,
  '11662': 130628,
  '11663': 144901,
  '11665': 101912,
  '11667': 146604,
  '11669': 100551,
  '11674': 97895,
  '11675': 146602,
  '11676': 97180,
  '11677': 76958,
  '11678': 144867,
  '11679': 123908,
  '11682': 146454,
  '11686': 146726,
  '11687': 124311,
  '11688': 129584,
  '11692': 88016,
  '11695': 99768,
  '11699': 129420,
  '11701': 124827,
  '11720': 102789,
  '11724': 146747,
  '11726': 143946,
  '11727': 77127,
  '11729': 158505,
  '11742': 122835,
  '11752': 372,
  '11761': 101488,
  '12259': 97271,
  '12340': 111781,
  '12341': 83786,
  '4971': 51368,
  '5017': 49162,
  '11086': 53587,
  '6901': 43395,
  '7641': 83119,
  '5030': 48600,
  '11557': 82151,
  '11668': 131252,
  '6074': 91014,
  '8308': 98253,
  '3976': 11757,
  '6811': 58099,
  '4427': 51596,
  '8374': 61790,
  '4174': 11886,
  '4216': 11906,
  '5864': 44027,
  '6131': 43190,
  '6789': 61102,
  '6921': 27379,
  '7630': 83341,
  '8573': 40997,
}

export const yahooToPffMap: { [key: string]: number } = {
  '449.p.100022': 1,
  '449.p.100001': 2,
  '449.p.100033': 3,
  '449.p.100002': 4,
  '449.p.100029': 5,
  '449.p.100003': 6,
  '449.p.100004': 7,
  '449.p.100005': 8,
  '449.p.100006': 9,
  '449.p.100007': 10,
  '449.p.100008': 11,
  '449.p.100009': 12,
  '449.p.100034': 13,
  '449.p.100011': 14,
  '449.p.100030': 15,
  '449.p.100012': 16,
  '449.p.100015': 17,
  '449.p.100016': 18,
  '449.p.100017': 19,
  '449.p.100018': 20,
  '449.p.100019': 21,
  '449.p.100020': 22,
  '449.p.100013': 23,
  '449.p.100021': 24,
  '449.p.100023': 25,
  '449.p.100014': 26,
  '449.p.100024': 27,
  '449.p.100025': 28,
  '449.p.100026': 29,
  '449.p.100027': 30,
  '449.p.100010': 31,
  '449.p.100028': 32,
  '449.p.7200': 2241,
  '449.p.27545': 2408,
  '449.p.7520': 2607,
  '449.p.8565': 3471,
  '449.p.8432': 3794,
  '449.p.8447': 3809,
  '449.p.8930': 4467,
  '449.p.8937': 4474,
  '449.p.9265': 4924,
  '449.p.9520': 5180,
  '449.p.9547': 5277,
  '449.p.9526': 5443,
  '449.p.24060': 5609,
  '449.p.24793': 6158,
  '449.p.24797': 6162,
  '449.p.24815': 6180,
  '449.p.24822': 6187,
  '449.p.24851': 6216,
  '449.p.24967': 6332,
  '449.p.25718': 7014,
  '449.p.25785': 7077,
  '449.p.25798': 7089,
  '449.p.25812': 7102,
  '449.p.25876': 7158,
  '449.p.25881': 7163,
  '449.p.29281': 10681,
  '449.p.26483': 7323,
  '449.p.26060': 7330,
  '449.p.26389': 7469,
  '449.p.25757': 7050,
  '449.p.26534': 7604,
  '449.p.26650': 7808,
  '449.p.26652': 7810,
  '449.p.26658': 7816,
  '449.p.26662': 7820,
  '449.p.26664': 7822,
  '449.p.26686': 7844,
  '449.p.26699': 7857,
  '449.p.26701': 7859,
  '449.p.26721': 7880,
  '449.p.26753': 7912,
  '449.p.26800': 7958,
  '449.p.26804': 7962,
  '449.p.27277': 8288,
  '449.p.27120': 8437,
  '449.p.27369': 8585,
  '449.p.27535': 8642,
  '449.p.27540': 8647,
  '449.p.27548': 8655,
  '449.p.27564': 8671,
  '449.p.27581': 8688,
  '449.p.27589': 8696,
  '449.p.27590': 8697,
  '449.p.27624': 8731,
  '449.p.27648': 8755,
  '449.p.40600': 106524,
  '449.p.27742': 8848,
  '449.p.28227': 8932,
  '449.p.28188': 8990,
  '449.p.28026': 8999,
  '449.p.28141': 9302,
  '449.p.28389': 9434,
  '449.p.28390': 9435,
  '449.p.28392': 9437,
  '449.p.28402': 9447,
  '449.p.28403': 9448,
  '449.p.28408': 9453,
  '449.p.28414': 9459,
  '449.p.28417': 9462,
  '449.p.28442': 9487,
  '449.p.28457': 9502,
  '449.p.28464': 9509,
  '449.p.28473': 9518,
  '449.p.28476': 9521,
  '449.p.28482': 9527,
  '449.p.28493': 9538,
  '449.p.28505': 9550,
  '449.p.28534': 9579,
  '449.p.28545': 9590,
  '449.p.28548': 9593,
  '449.p.28556': 9601,
  '449.p.28592': 9637,
  '449.p.28638': 9683,
  '449.p.28654': 9783,
  '449.p.28658': 9786,
  '449.p.28947': 9859,
  '449.p.28839': 9896,
  '449.p.28730': 9964,
  '449.p.28716': 9994,
  '449.p.28378': 10207,
  '449.p.28365': 10211,
  '449.p.29235': 10635,
  '449.p.29238': 10638,
  '449.p.29257': 10657,
  '449.p.29269': 10669,
  '449.p.29274': 10674,
  '449.p.29279': 10679,
  '449.p.29288': 10689,
  '449.p.29307': 10707,
  '449.p.29315': 10715,
  '449.p.29325': 10725,
  '449.p.29328': 10728,
  '449.p.29341': 10741,
  '449.p.29344': 10744,
  '449.p.29351': 10751,
  '449.p.29360': 10760,
  '449.p.29369': 10769,
  '449.p.29390': 10790,
  '449.p.29399': 10799,
  '449.p.29420': 10820,
  '449.p.29421': 10821,
  '449.p.34389': 40316,
  '449.p.29435': 10835,
  '449.p.29440': 10840,
  '449.p.29441': 10841,
  '449.p.29470': 10870,
  '449.p.29631': 11028,
  '449.p.29650': 11047,
  '449.p.29754': 11096,
  '449.p.29703': 11107,
  '449.p.29792': 11161,
  '449.p.29785': 11254,
  '449.p.29951': 11347,
  '449.p.29978': 11371,
  '449.p.30115': 11757,
  '449.p.30117': 11759,
  '449.p.30118': 11760,
  '449.p.30120': 11762,
  '449.p.30121': 11763,
  '449.p.30122': 11764,
  '449.p.30123': 11765,
  '449.p.30125': 11767,
  '449.p.30132': 11774,
  '449.p.30136': 11778,
  '449.p.30142': 11784,
  '449.p.30150': 11792,
  '449.p.30153': 11795,
  '449.p.30154': 11796,
  '449.p.30157': 11799,
  '449.p.30161': 11803,
  '449.p.30175': 11817,
  '449.p.30180': 11822,
  '449.p.30182': 11824,
  '449.p.30197': 11839,
  '449.p.30199': 11841,
  '449.p.30202': 11844,
  '449.p.30213': 11855,
  '449.p.30217': 11859,
  '449.p.30218': 11860,
  '449.p.30227': 11869,
  '449.p.30230': 11872,
  '449.p.30231': 11873,
  '449.p.30247': 11889,
  '449.p.30248': 11890,
  '449.p.30259': 11901,
  '449.p.30266': 11908,
  '449.p.30275': 11917,
  '449.p.30278': 11920,
  '449.p.30284': 11926,
  '449.p.30285': 11927,
  '449.p.30287': 11929,
  '449.p.30290': 11932,
  '449.p.30295': 11937,
  '449.p.30305': 11948,
  '449.p.30337': 11979,
  '449.p.30339': 11981,
  '449.p.30346': 11988,
  '449.p.30352': 11993,
  '449.p.30364': 12007,
  '449.p.30426': 12042,
  '449.p.30660': 12052,
  '449.p.30112': 12078,
  '449.p.30511': 12087,
  '449.p.30513': 12088,
  '449.p.30614': 12112,
  '449.p.30538': 12134,
  '449.p.30682': 12137,
  '449.p.30423': 12164,
  '449.p.30777': 12193,
  '449.p.33571': 42873,
  '449.p.30373': 12206,
  '449.p.30627': 12247,
  '449.p.30707': 12253,
  '449.p.30715': 12258,
  '449.p.30551': 12270,
  '449.p.30552': 12271,
  '449.p.30563': 12282,
  '449.p.30502': 12303,
  '449.p.30788': 12324,
  '449.p.30860': 12329,
  '449.p.33879': 12659,
  '449.p.31808': 13076,
  '449.p.32293': 13534,
  '449.p.31965': 13725,
  '449.p.32782': 13750,
  '449.p.33151': 13756,
  '449.p.32859': 17287,
  '449.p.31329': 20994,
  '449.p.31831': 21473,
  '449.p.31431': 21956,
  '449.p.34407': 22373,
  '449.p.33138': 22425,
  '449.p.34513': 22777,
  '449.p.31538': 22842,
  '449.p.34473': 23226,
  '449.p.32846': 23503,
  '449.p.31189': 23519,
  '449.p.31198': 23670,
  '449.p.31170': 24904,
  '449.p.34214': 25276,
  '449.p.33028': 25512,
  '449.p.32003': 25578,
  '449.p.33199': 25582,
  '449.p.33286': 25705,
  '449.p.33088': 25859,
  '449.p.31051': 26075,
  '449.p.31769': 26104,
  '449.p.31301': 26152,
  '449.p.31938': 26316,
  '449.p.32785': 26394,
  '449.p.32790': 26408,
  '449.p.32884': 26448,
  '449.p.33020': 26884,
  '449.p.33302': 27003,
  '449.p.31960': 27126,
  '449.p.33590': 27303,
  '449.p.34484': 27369,
  '449.p.31207': 27413,
  '449.p.30997': 27414,
  '449.p.32502': 27436,
  '449.p.31001': 27589,
  '449.p.33124': 27766,
  '449.p.33637': 27948,
  '449.p.32671': 28022,
  '449.p.31137': 28121,
  '449.p.32439': 28219,
  '449.p.32676': 28237,
  '449.p.34356': 28304,
  '449.p.33113': 28305,
  '449.p.32751': 28486,
  '449.p.33349': 28729,
  '449.p.31210': 28991,
  '449.p.30973': 29048,
  '449.p.32704': 29049,
  '449.p.32378': 29347,
  '449.p.31394': 29413,
  '449.p.31998': 29466,
  '449.p.31221': 29539,
  '449.p.40791': 29557,
  '449.p.33444': 29573,
  '449.p.31199': 29578,
  '449.p.32836': 29614,
  '449.p.31651': 29726,
  '449.p.40541': 30437,
  '449.p.31090': 30762,
  '449.p.31441': 30887,
  '449.p.31228': 31615,
  '449.p.32630': 32342,
  '449.p.31826': 32565,
  '449.p.32029': 32819,
  '449.p.34378': 32933,
  '449.p.32858': 33010,
  '449.p.32417': 33285,
  '449.p.34657': 33384,
  '449.p.31898': 33441,
  '449.p.34712': 33465,
  '449.p.31008': 34001,
  '449.p.33211': 34038,
  '449.p.32929': 34045,
  '449.p.33023': 34061,
  '449.p.40003': 34083,
  '449.p.32201': 34164,
  '449.p.33529': 34255,
  '449.p.32901': 34311,
  '449.p.32511': 34427,
  '449.p.32418': 34579,
  '449.p.31145': 34761,
  '449.p.33329': 34914,
  '449.p.32344': 34934,
  '449.p.31283': 34985,
  '449.p.31889': 35014,
  '449.p.33242': 35096,
  '449.p.31919': 35134,
  '449.p.33258': 35299,
  '449.p.33901': 35639,
  '449.p.31928': 35883,
  '449.p.31973': 36049,
  '449.p.33315': 36271,
  '449.p.31932': 38265,
  '449.p.32019': 38275,
  '449.p.33006': 38287,
  '449.p.31833': 38334,
  '449.p.32447': 38855,
  '449.p.32384': 39235,
  '449.p.31838': 39395,
  '449.p.31366': 39417,
  '449.p.31171': 39462,
  '449.p.31874': 39517,
  '449.p.32006': 39587,
  '449.p.32063': 39770,
  '449.p.33741': 39957,
  '449.p.33452': 39987,
  '449.p.34360': 39988,
  '449.p.32762': 40006,
  '449.p.34278': 40034,
  '449.p.32148': 40065,
  '449.p.33521': 40216,
  '449.p.33729': 40217,
  '449.p.32821': 40221,
  '449.p.32723': 40291,
  '449.p.32761': 40294,
  '449.p.32696': 40306,
  '449.p.32756': 40341,
  '449.p.34197': 40364,
  '449.p.34206': 40399,
  '449.p.34476': 40408,
  '449.p.32921': 40411,
  '449.p.33630': 40445,
  '449.p.33235': 40448,
  '449.p.31906': 40485,
  '449.p.33114': 40498,
  '449.p.33507': 40547,
  '449.p.31885': 40555,
  '449.p.33599': 40568,
  '449.p.34027': 40573,
  '449.p.32808': 40588,
  '449.p.33515': 40630,
  '449.p.34571': 40666,
  '449.p.34596': 40668,
  '449.p.33569': 40689,
  '449.p.32763': 40692,
  '449.p.33100': 40693,
  '449.p.34689': 40771,
  '449.p.33632': 40903,
  '449.p.31840': 40912,
  '449.p.33357': 40951,
  '449.p.32008': 40973,
  '449.p.33597': 40986,
  '449.p.33575': 40988,
  '449.p.31902': 41193,
  '449.p.33253': 41247,
  '449.p.34174': 41372,
  '449.p.32010': 41401,
  '449.p.31194': 41406,
  '449.p.33605': 41668,
  '449.p.33591': 41838,
  '449.p.32788': 41933,
  '449.p.34579': 41991,
  '449.p.31953': 42018,
  '449.p.34494': 42032,
  '449.p.33891': 42058,
  '449.p.31882': 42060,
  '449.p.34495': 42096,
  '449.p.33858': 42109,
  '449.p.31071': 42130,
  '449.p.32922': 42134,
  '449.p.33705': 42196,
  '449.p.32523': 42237,
  '449.p.32494': 42247,
  '449.p.32860': 42257,
  '449.p.33101': 42266,
  '449.p.33512': 42275,
  '449.p.33109': 42295,
  '449.p.33724': 57441,
  '449.p.32719': 42312,
  '449.p.33497': 42349,
  '449.p.33533': 42353,
  '449.p.31852': 42388,
  '449.p.33921': 42541,
  '449.p.31888': 42683,
  '449.p.33676': 42701,
  '449.p.33001': 48012,
  '449.p.31482': 44140,
  '449.p.34633': 44539,
  '449.p.33918': 44709,
  '449.p.31164': 44771,
  '449.p.34469': 44789,
  '449.p.32140': 44889,
  '449.p.32123': 44920,
  '449.p.32383': 45038,
  '449.p.33343': 45181,
  '449.p.31457': 45691,
  '449.p.32842': 45693,
  '449.p.32669': 45704,
  '449.p.31905': 45719,
  '449.p.32608': 45734,
  '449.p.31041': 45747,
  '449.p.32018': 45769,
  '449.p.32014': 45775,
  '449.p.31005': 45783,
  '449.p.30972': 45791,
  '449.p.31934': 45817,
  '449.p.33621': 45835,
  '449.p.31104': 45851,
  '449.p.32036': 45859,
  '449.p.31945': 45865,
  '449.p.32746': 45866,
  '449.p.32066': 45914,
  '449.p.31984': 45934,
  '449.p.31856': 45953,
  '449.p.31424': 46027,
  '449.p.40760': 78741,
  '449.p.32138': 46401,
  '449.p.31002': 46416,
  '449.p.32662': 46440,
  '449.p.32792': 46444,
  '449.p.32993': 46448,
  '449.p.31141': 46453,
  '449.p.31803': 46454,
  '449.p.32415': 46481,
  '449.p.32221': 46487,
  '449.p.32272': 46508,
  '449.p.31219': 46517,
  '449.p.30971': 46518,
  '449.p.31046': 46569,
  '449.p.30977': 46601,
  '449.p.32764': 47034,
  '449.p.31969': 47043,
  '449.p.33021': 47044,
  '449.p.31068': 47050,
  '449.p.31901': 47083,
  '449.p.32225': 47121,
  '449.p.32551': 47123,
  '449.p.31127': 47124,
  '449.p.32250': 47147,
  '449.p.31012': 47153,
  '449.p.32060': 47216,
  '449.p.31093': 47257,
  '449.p.31884': 47259,
  '449.p.31019': 47272,
  '449.p.32970': 47282,
  '449.p.30995': 47294,
  '449.p.31107': 47304,
  '449.p.32125': 47311,
  '449.p.31126': 47333,
  '449.p.32806': 47338,
  '449.p.32442': 47358,
  '449.p.33836': 47378,
  '449.p.33340': 47412,
  '449.p.30955': 47417,
  '449.p.31056': 47436,
  '449.p.32727': 47447,
  '449.p.31894': 47448,
  '449.p.32231': 47468,
  '449.p.32040': 47481,
  '449.p.31981': 47509,
  '449.p.31017': 47546,
  '449.p.34709': 47658,
  '449.p.33813': 47663,
  '449.p.31073': 47698,
  '449.p.31031': 47702,
  '449.p.32876': 47743,
  '449.p.31030': 47792,
  '449.p.32729': 47800,
  '449.p.32835': 47808,
  '449.p.31144': 47809,
  '449.p.33348': 47861,
  '449.p.31896': 47864,
  '449.p.31157': 47895,
  '449.p.32943': 47901,
  '449.p.31405': 47908,
  '449.p.31935': 47928,
  '449.p.31891': 47940,
  '449.p.31180': 47961,
  '449.p.33168': 47964,
  '449.p.31177': 47967,
  '449.p.33953': 47972,
  '449.p.32124': 47996,
  '449.p.31517': 48017,
  '449.p.31320': 48021,
  '449.p.32887': 48027,
  '449.p.31641': 48036,
  '449.p.32871': 48037,
  '449.p.32831': 48054,
  '449.p.32533': 48055,
  '449.p.31777': 48078,
  '449.p.32438': 48080,
  '449.p.31075': 48101,
  '449.p.31074': 48103,
  '449.p.32137': 48107,
  '449.p.32469': 48110,
  '449.p.31014': 48113,
  '449.p.31268': 48135,
  '449.p.32342': 48143,
  '449.p.32367': 48152,
  '449.p.31021': 48160,
  '449.p.31010': 48164,
  '449.p.31178': 48170,
  '449.p.31129': 48181,
  '449.p.31223': 48192,
  '449.p.31925': 48209,
  '449.p.31061': 48228,
  '449.p.31908': 48229,
  '449.p.32843': 48236,
  '449.p.30996': 48262,
  '449.p.30994': 48267,
  '449.p.31868': 48274,
  '449.p.31883': 48327,
  '449.p.32882': 48329,
  '449.p.31952': 48331,
  '449.p.32187': 48341,
  '449.p.32588': 48369,
  '449.p.30592': 48862,
  '449.p.31024': 49162,
  '449.p.31258': 49166,
  '449.p.31205': 49834,
  '449.p.31845': 50293,
  '449.p.31480': 50714,
  '449.p.32697': 50851,
  '449.p.31503': 51028,
  '449.p.31040': 51222,
  '449.p.30396': 51593,
  '449.p.40173': 51708,
  '449.p.30571': 51837,
  '449.p.30891': 51842,
  '449.p.33804': 52008,
  '449.p.34256': 52122,
  '449.p.32877': 52130,
  '449.p.40462': 52133,
  '449.p.33745': 52195,
  '449.p.32803': 52266,
  '449.p.33864': 52267,
  '449.p.33455': 52269,
  '449.p.34625': 52286,
  '449.p.40182': 54037,
  '449.p.40158': 55038,
  '449.p.34030': 55086,
  '449.p.40539': 55123,
  '449.p.40586': 55226,
  '449.p.40289': 55239,
  '449.p.40805': 55306,
  '449.p.32841': 55307,
  '449.p.32798': 55410,
  '449.p.32814': 55632,
  '449.p.40354': 55894,
  '449.p.40620': 55906,
  '449.p.33572': 57070,
  '449.p.34054': 57120,
  '449.p.33412': 57121,
  '449.p.33584': 57133,
  '449.p.32892': 57136,
  '449.p.33732': 57156,
  '449.p.32364': 94371,
  '449.p.34159': 57159,
  '449.p.32732': 57165,
  '449.p.33413': 57185,
  '449.p.33798': 57189,
  '449.p.34610': 57196,
  '449.p.40410': 57204,
  '449.p.32722': 57206,
  '449.p.34310': 57208,
  '449.p.40631': 57209,
  '449.p.32705': 57212,
  '449.p.34234': 57266,
  '449.p.33582': 57272,
  '449.p.32702': 57279,
  '449.p.32794': 57289,
  '449.p.40298': 57293,
  '449.p.34163': 57301,
  '449.p.33586': 57308,
  '449.p.33495': 57337,
  '449.p.40361': 57355,
  '449.p.32725': 57366,
  '449.p.34377': 57371,
  '449.p.33476': 57372,
  '449.p.33514': 57373,
  '449.p.34216': 57384,
  '449.p.34617': 57385,
  '449.p.40358': 57394,
  '449.p.40402': 57461,
  '449.p.33265': 57472,
  '449.p.40639': 57486,
  '449.p.32711': 57488,
  '449.p.40721': 57490,
  '449.p.34720': 57498,
  '449.p.40296': 57502,
  '449.p.40214': 57509,
  '449.p.33781': 57556,
  '449.p.40015': 57611,
  '449.p.40496': 57624,
  '449.p.31977': 57643,
  '449.p.40254': 57854,
  '449.p.40428': 59851,
  '449.p.33403': 60323,
  '449.p.32675': 60326,
  '449.p.34042': 60341,
  '449.p.34346': 60349,
  '449.p.34545': 60356,
  '449.p.40622': 60382,
  '449.p.40153': 60387,
  '449.p.32837': 60389,
  '449.p.34093': 60396,
  '449.p.34203': 60417,
  '449.p.40290': 60436,
  '449.p.34637': 60492,
  '449.p.40016': 60504,
  '449.p.34029': 60509,
  '449.p.40666': 60513,
  '449.p.33975': 60515,
  '449.p.40138': 60516,
  '449.p.40300': 60530,
  '449.p.40533': 60545,
  '449.p.33606': 60555,
  '449.p.33454': 60556,
  '449.p.40096': 60593,
  '449.p.40144': 60596,
  '449.p.40535': 60728,
  '449.p.40733': 60854,
  '449.p.33469': 60859,
  '449.p.34374': 60864,
  '449.p.40519': 60876,
  '449.p.33550': 60878,
  '449.p.33485': 60896,
  '449.p.34607': 60915,
  '449.p.34084': 60917,
  '449.p.34664': 60970,
  '449.p.33774': 60994,
  '449.p.32713': 60995,
  '449.p.34154': 60997,
  '449.p.40643': 61004,
  '449.p.32771': 61068,
  '449.p.34062': 61071,
  '449.p.40705': 61076,
  '449.p.34183': 61079,
  '449.p.34085': 61081,
  '449.p.33398': 61100,
  '449.p.32685': 61103,
  '449.p.40630': 61107,
  '449.p.34274': 61137,
  '449.p.40740': 61167,
  '449.p.33819': 61182,
  '449.p.32703': 61211,
  '449.p.33473': 61212,
  '449.p.32712': 61220,
  '449.p.33783': 61224,
  '449.p.33408': 61245,
  '449.p.40458': 61271,
  '449.p.34592': 61295,
  '449.p.34609': 61321,
  '449.p.33545': 61324,
  '449.p.33710': 101497,
  '449.p.34719': 61359,
  '449.p.32750': 61361,
  '449.p.40460': 61382,
  '449.p.40418': 61394,
  '449.p.32692': 61398,
  '449.p.33593': 61401,
  '449.p.34094': 61418,
  '449.p.34478': 61423,
  '449.p.40372': 61438,
  '449.p.32857': 61439,
  '449.p.33477': 61441,
  '449.p.33797': 61442,
  '449.p.32948': 61448,
  '449.p.34534': 61457,
  '449.p.34330': 61478,
  '449.p.40464': 61489,
  '449.p.40673': 61494,
  '449.p.40374': 61501,
  '449.p.33609': 61510,
  '449.p.40322': 61515,
  '449.p.33517': 61522,
  '449.p.34276': 61544,
  '449.p.31857': 61568,
  '449.p.34229': 61569,
  '449.p.32687': 61570,
  '449.p.40764': 61576,
  '449.p.33519': 61577,
  '449.p.40619': 61584,
  '449.p.34601': 61588,
  '449.p.32716': 61590,
  '449.p.40487': 61591,
  '449.p.34185': 61610,
  '449.p.34044': 61637,
  '449.p.33642': 61639,
  '449.p.40634': 61640,
  '449.p.33592': 61643,
  '449.p.32870': 61664,
  '449.p.33649': 61669,
  '449.p.32691': 61697,
  '449.p.34493': 61765,
  '449.p.40762': 61777,
  '449.p.34309': 61794,
  '449.p.34563': 61799,
  '449.p.40348': 61804,
  '449.p.34319': 61811,
  '449.p.33299': 61812,
  '449.p.34466': 61815,
  '449.p.40375': 62290,
  '449.p.33367': 62825,
  '449.p.33437': 62890,
  '449.p.34125': 62942,
  '449.p.34320': 62946,
  '449.p.33465': 62979,
  '449.p.33064': 47214,
  '449.p.40778': 62982,
  '449.p.40420': 63268,
  '449.p.40645': 63572,
  '449.p.40400': 63583,
  '449.p.40503': 63659,
  '449.p.40425': 63671,
  '449.p.40607': 63706,
  '449.p.34659': 63707,
  '449.p.34581': 63718,
  '449.p.40263': 63721,
  '449.p.34713': 64309,
  '449.p.34118': 64310,
  '449.p.34597': 65088,
  '449.p.40689': 66123,
  '449.p.33989': 66581,
  '449.p.34083': 66619,
  '449.p.40463': 66639,
  '449.p.40680': 66715,
  '449.p.31114': 66915,
  '449.p.31257': 66924,
  '449.p.31588': 66957,
  '449.p.34010': 75916,
  '449.p.40380': 77049,
  '449.p.40321': 77054,
  '449.p.34344': 77069,
  '449.p.40713': 77076,
  '449.p.40817': 77078,
  '449.p.40159': 77083,
  '449.p.33389': 77632,
  '449.p.40450': 77651,
  '449.p.40188': 77652,
  '449.p.34218': 77672,
  '449.p.40507': 77705,
  '449.p.40334': 77733,
  '449.p.33538': 77737,
  '449.p.33706': 77745,
  '449.p.34157': 77761,
  '449.p.40430': 77763,
  '449.p.40196': 77787,
  '449.p.33423': 77803,
  '449.p.40437': 77871,
  '449.p.34300': 77872,
  '449.p.40433': 77881,
  '449.p.34133': 77885,
  '449.p.33535': 77887,
  '449.p.40180': 77888,
  '449.p.40472': 77912,
  '449.p.40261': 77917,
  '449.p.40335': 77918,
  '449.p.40346': 77943,
  '449.p.40712': 77958,
  '449.p.33445': 77965,
  '449.p.40242': 77975,
  '449.p.40281': 77985,
  '449.p.33470': 77986,
  '449.p.40800': 77993,
  '449.p.40452': 77997,
  '449.p.34440': 78021,
  '449.p.40640': 78030,
  '449.p.40772': 78032,
  '449.p.34008': 78038,
  '449.p.32736': 78050,
  '449.p.34005': 78092,
  '449.p.40672': 78107,
  '449.p.40782': 78108,
  '449.p.40388': 78123,
  '449.p.40529': 78127,
  '449.p.40308': 78141,
  '449.p.34081': 78148,
  '449.p.40063': 78171,
  '449.p.40737': 78187,
  '449.p.40133': 78196,
  '449.p.40417': 78498,
  '449.p.40676': 78734,
  '449.p.40595': 78771,
  '449.p.40097': 78874,
  '449.p.40773': 78972,
  '449.p.40324': 81252,
  '449.p.33400': 81360,
  '449.p.33491': 81399,
  '449.p.40185': 82094,
  '449.p.33390': 82096,
  '449.p.33399': 82118,
  '449.p.33537': 82583,
  '449.p.40307': 82587,
  '449.p.40129': 82603,
  '449.p.40101': 82647,
  '449.p.34050': 83517,
  '449.p.40299': 83522,
  '449.p.40757': 83528,
  '449.p.40406': 83540,
  '449.p.40068': 83542,
  '449.p.40195': 83574,
  '449.p.34112': 83601,
  '449.p.40587': 83607,
  '449.p.34107': 83639,
  '449.p.40625': 83644,
  '449.p.34063': 83659,
  '449.p.34078': 83665,
  '449.p.34019': 83666,
  '449.p.40231': 83689,
  '449.p.34087': 83701,
  '449.p.34152': 83714,
  '449.p.34544': 83716,
  '449.p.40546': 83738,
  '449.p.33644': 83740,
  '449.p.34207': 83747,
  '449.p.40331': 83748,
  '449.p.34665': 83773,
  '449.p.40570': 83774,
  '449.p.40311': 83782,
  '449.p.32046': 83791,
  '449.p.33424': 83873,
  '449.p.40444': 83930,
  '449.p.34095': 83955,
  '449.p.34654': 83962,
  '449.p.33392': 83964,
  '449.p.34169': 83974,
  '449.p.40637': 83994,
  '449.p.34099': 84000,
  '449.p.40061': 84005,
  '449.p.33471': 84025,
  '449.p.34057': 84029,
  '449.p.34126': 84036,
  '449.p.40193': 84037,
  '449.p.34068': 84041,
  '449.p.34548': 84043,
  '449.p.34252': 84065,
  '449.p.34036': 84068,
  '449.p.34543': 84070,
  '449.p.40255': 84084,
  '449.p.33443': 84085,
  '449.p.33394': 84088,
  '449.p.34442': 84089,
  '449.p.33376': 84096,
  '449.p.32695': 84109,
  '449.p.34158': 84117,
  '449.p.33479': 84135,
  '449.p.40491': 84136,
  '449.p.33607': 84137,
  '449.p.34104': 84140,
  '449.p.32838': 84142,
  '449.p.33645': 84147,
  '449.p.40571': 84150,
  '449.p.40432': 84165,
  '449.p.40710': 84176,
  '449.p.40326': 84188,
  '449.p.32978': 84213,
  '449.p.33393': 84270,
  '449.p.33447': 84271,
  '449.p.40367': 84274,
  '449.p.40642': 84284,
  '449.p.40770': 84285,
  '449.p.40279': 84288,
  '449.p.41048': 84226,
  '449.p.34147': 84292,
  '449.p.33415': 84296,
  '449.p.33422': 84302,
  '449.p.34088': 84329,
  '449.p.34105': 84330,
  '449.p.34242': 84345,
  '449.p.33966': 84355,
  '449.p.33971': 84375,
  '449.p.40167': 84376,
  '449.p.40728': 84395,
  '449.p.40582': 84414,
  '449.p.40121': 84423,
  '449.p.33567': 84424,
  '449.p.40407': 84425,
  '449.p.40093': 84434,
  '449.p.34119': 84463,
  '449.p.40352': 84464,
  '449.p.40590': 84465,
  '449.p.33500': 84470,
  '449.p.34520': 84480,
  '449.p.40505': 84516,
  '449.p.33963': 86519,
  '449.p.40474': 87559,
  '449.p.40702': 87625,
  '449.p.34673': 87703,
  '449.p.40804': 87714,
  '449.p.40629': 88685,
  '449.p.40553': 88745,
  '449.p.40801': 89497,
  '449.p.40703': 89507,
  '449.p.32456': 91014,
  '449.p.40466': 91115,
  '449.p.28279': 91248,
  '449.p.40780': 91319,
  '449.p.33391': 91399,
  '449.p.32576': 91465,
  '449.p.32142': 91468,
  '449.p.32398': 91470,
  '449.p.32292': 91479,
  '449.p.40048': 91791,
  '449.p.32189': 94356,
  '449.p.32273': 94365,
  '449.p.32488': 94375,
  '449.p.32561': 94379,
  '449.p.32596': 94383,
  '449.p.32600': 94384,
  '449.p.40556': 94929,
  '449.p.32612': 95015,
  '449.p.34009': 97116,
  '449.p.34120': 97118,
  '449.p.34551': 97119,
  '449.p.34434': 97163,
  '449.p.40260': 97187,
  '449.p.40119': 97195,
  '449.p.33996': 97199,
  '449.p.40039': 97268,
  '449.p.40774': 97273,
  '449.p.40169': 97302,
  '449.p.40542': 97314,
  '449.p.33999': 97400,
  '449.p.33965': 97405,
  '449.p.34357': 97432,
  '449.p.34100': 97599,
  '449.p.40084': 97605,
  '449.p.40075': 97630,
  '449.p.33991': 97636,
  '449.p.40073': 97774,
  '449.p.40515': 97791,
  '449.p.34055': 97989,
  '449.p.40226': 98027,
  '449.p.40514': 98214,
  '449.p.40074': 98499,
  '449.p.33508': 98689,
  '449.p.34447': 98692,
  '449.p.34608': 98738,
  '449.p.34080': 98975,
  '449.p.34139': 99141,
  '449.p.40198': 99143,
  '449.p.34079': 99146,
  '449.p.40267': 99158,
  '449.p.34110': 99163,
  '449.p.34049': 99169,
  '449.p.34623': 99185,
  '449.p.33973': 99218,
  '449.p.34007': 99229,
  '449.p.40223': 99248,
  '449.p.40162': 99467,
  '449.p.40272': 99470,
  '449.p.40288': 99691,
  '449.p.40164': 99697,
  '449.p.40201': 99711,
  '449.p.40393': 100276,
  '449.p.40168': 100632,
  '449.p.34403': 100647,
  '449.p.40114': 101106,
  '449.p.34231': 101347,
  '449.p.34261': 101659,
  '449.p.40064': 101690,
  '449.p.40078': 101694,
  '449.p.33967': 101701,
  '449.p.33998': 101735,
  '449.p.40384': 102567,
  '449.p.40399': 102585,
  '449.p.34506': 102929,
  '449.p.40102': 104034,
  '449.p.40548': 104136,
  '449.p.40222': 104155,
  '449.p.40461': 104187,
  '449.p.40338': 105710,
  '449.p.40793': 105743,
  '449.p.34451': 109239,
  '449.p.40497': 109429,
  '449.p.34591': 109686,
  '449.p.40523': 109726,
  '449.p.40797': 109726,
  '449.p.32775': 109795,
  '449.p.40211': 109811,
  '449.p.34223': 109832,
  '449.p.40550': 109834,
  '449.p.40251': 111276,
  '449.p.40257': 111284,
  '449.p.34189': 111900,
  '449.p.33351': 115664,
  '449.p.40545': 121451,
  '449.p.40285': 121495,
  '449.p.34061': 121926,
  '449.p.40059': 122474,
  '449.p.40232': 122518,
  '449.p.40240': 122624,
  '449.p.40126': 122832,
  '449.p.40065': 122906,
  '449.p.40090': 122937,
  '449.p.40165': 122999,
  '449.p.40042': 123000,
  '449.p.40742': 123056,
  '449.p.40243': 123072,
  '449.p.40051': 123085,
  '449.p.40108': 123091,
  '449.p.40055': 123126,
  '449.p.40438': 123237,
  '449.p.40313': 123470,
  '449.p.40141': 123578,
  '449.p.40641': 123826,
  '449.p.40029': 123943,
  '449.p.40095': 123974,
  '449.p.40094': 124044,
  '449.p.40224': 124087,
  '449.p.40445': 124173,
  '449.p.40128': 124282,
  '449.p.40118': 124305,
  '449.p.40040': 124393,
  '449.p.40748': 128096,
  '449.p.40394': 128192,
  '449.p.40376': 128347,
  '449.p.40671': 129519,
  '449.p.40041': 129758,
  '449.p.40030': 129759,
  '449.p.40234': 129799,
  '449.p.34047': 131633,
  '449.p.40220': 131770,
  '449.p.40520': 134733,
  '449.p.40696': 138346,
  '449.p.34148': 138351,
  '449.p.40695': 141919,
  '449.p.33620': 143358,
  '449.p.33386': 143769,
  '449.p.33556': 143780,
  '449.p.33801': 143785,
  '449.p.33803': 143786,
  '449.p.33793': 143789,
  '449.p.33759': 143797,
  '449.p.40405': 144440,
  '449.p.34489': 145197,
  '449.p.40390': 146928,
  '449.p.34594': 148900,
  '449.p.40609': 148905,
  '449.p.40734': 150501,
  '449.p.34387': 154081,
  '449.p.40707': 154173,
  '449.p.33950': 156095,
  '449.p.40819': 156112,
  '449.p.34284': 156149,
  '449.p.34573': 156153,
  '449.p.40344': 160864,
  '449.p.40504': 169628,
  '449.p.40419': 169635,
  '449.p.40521': 169636,
  '449.p.40679': 169639,
  '449.p.40017': 169668,
  '449.p.40409': 169771,
  '449.p.40424': 169773,
  '449.p.40477': 169776,
  '449.p.40644': 169784,
  '449.p.40459': 169858,
  '449.p.40751': 169879,
  '449.p.40796': 169890,

  // New ones for 2024
  '449.p.24070': 5619,
  '449.p.25871': 7154,
  '449.p.27570': 8677,
  '449.p.27646': 8753,
  '449.p.28531': 9576,
  '449.p.28634': 9679,
  '449.p.29236': 10636,
  '449.p.29789': 11160,
  '449.p.30232': 11874,
  '449.p.30256': 11898,
  '449.p.31190': 46420,
  '449.p.31864': 48297,
  '449.p.31496': 42345,
  '449.p.32002': 13701,
  '449.p.32141': 33158,
  '449.p.32174': 39675,
  '449.p.32283': 55003,
  '449.p.33039': 28070,
  '449.p.33171': 47234,
  '449.p.33384': 26287,
  '449.p.33828': 39861,
  '449.p.33928': 91824,
  '449.p.33936': 143812,
  '449.p.34707': 39879,
  '449.p.40836': 34983,
  '449.p.40872': 183079,
  '449.p.40873': 183080,
  '449.p.40874': 109158,
  '449.p.40875': 97790,
  '449.p.40877': 144718,
  '449.p.40878': 146715,
  '449.p.40881': 143969,
  '449.p.40883': 146760,
  '449.p.40889': 82134,
  '449.p.40890': 99197,
  '449.p.40892': 100660,
  '449.p.40893': 145056,
  '449.p.40896': 97993,
  '449.p.40899': 146759,
  '449.p.40900': 144622,
  '449.p.40901': 73774,
  '449.p.40905': 144918,
  '449.p.40908': 124030,
  '449.p.40911': 144949,
  '449.p.40914': 123153,
  '449.p.40915': 146712,
  '449.p.40926': 144721,
  '449.p.40931': 122616,
  '449.p.40944': 66272,
  '449.p.40952': 129604,
  '449.p.40954': 131905,
  '449.p.40958': 131705,
  '449.p.40959': 129423,
  '449.p.40962': 129574,
  '449.p.40964': 101634,
  '449.p.40971': 124247,
  '449.p.40974': 124017,
  '449.p.40978': 98508,
  '449.p.40980': 101072,
  '449.p.40981': 145159,
  '449.p.40982': 123904,
  '449.p.40987': 101486,
  '449.p.40991': 145773,
  '449.p.40993': 144970,
  '449.p.40995': 144717,
  '449.p.40996': 100894,
  '449.p.40998': 83806,
  '449.p.41001': 129433,
  '449.p.41002': 144138,
  '449.p.41003': 129787,
  '449.p.41006': 158505,
  '449.p.41009': 146567,
  '449.p.41010': 146934,
  '449.p.41016': 97399,
  '449.p.41019': 147476,
  '449.p.41030': 99207,
  '449.p.41031': 138299,
  '449.p.41033': 121717,
  '449.p.41037': 77615,
  '449.p.41041': 97097,
  '449.p.41043': 97408,
  '449.p.41045': 98847,
  '449.p.41046': 97078,
  '449.p.41052': 123727,
  '449.p.41054': 88664,
  '449.p.41058': 97108,
  '449.p.41060': 82151,
  '449.p.41062': 146646,
  '449.p.41064': 99595,
  '449.p.41065': 101135,
  '449.p.41066': 87645,
  '449.p.41069': 77628,
  '449.p.41074': 101710,
  '449.p.41075': 123926,
  '449.p.41079': 145443,
  '449.p.41081': 131765,
  '449.p.41083': 97623,
  '449.p.41087': 159617,
  '449.p.41089': 76366,
  '449.p.41093': 98965,
  '449.p.41097': 100637,
  '449.p.41098': 131692,
  '449.p.41099': 97996,
  '449.p.41104': 97288,
  '449.p.41106': 124225,
  '449.p.41120': 84080,
  '449.p.41130': 123229,
  '449.p.41133': 77685,
  '449.p.41134': 124021,
  '449.p.41135': 97602,
  '449.p.41139': 99708,
  '449.p.41141': 78750,
  '449.p.41142': 91401,
  '449.p.41143': 82535,
  '449.p.41147': 84191,
  '449.p.41150': 97868,
  '449.p.41162': 99204,
  '449.p.41163': 124169,
  '449.p.41179': 141559,
  '449.p.41180': 83946,
  '449.p.41183': 57427,
  '449.p.41185': 183111,
  '449.p.41188': 109617,
  '449.p.41201': 98359,
  '449.p.41202': 101130,
  '449.p.41203': 99232,
  '449.p.41205': 84283,
  '449.p.41207': 102587,
  '449.p.41220': 111760,
  '449.p.41226': 100230,
  '449.p.41231': 175505,
  '449.p.41233': 83589,
  '449.p.41234': 98617,
  '449.p.41237': 97183,
  '449.p.41238': 77618,
  '449.p.41239': 124037,
  '449.p.41250': 82111,
  '449.p.41259': 99693,
  '449.p.41261': 142677,
  '449.p.41262': 97181,
  '449.p.41271': 122630,
  '449.p.41322': 102419,
  '449.p.41333': 77969,
  '449.p.41342': 102500,
  '449.p.41344': 72154,
  '449.p.41346': 77800,
  '449.p.41347': 157609,
  '449.p.41348': 77741,
  '449.p.41352': 84079,
  '449.p.41373': 83949,
  '449.p.41411': 98095,
  '449.p.41446': 77710,
  '449.p.41451': 55059,
  '449.p.41453': 78087,
  '449.p.41454': 88315,
  '449.p.41455': 98298,
  '449.p.41463': 84012,
  '449.p.41464': 145632,
  '449.p.41475': 109584,
  '449.p.41503': 98464,
  '449.p.41506': 77689,
  '449.p.41511': 160316,
  '449.p.41512': 87634,
  '449.p.41518': 78128,
  '449.p.41519': 78579,
  '449.p.41522': 159937,
  '449.p.41524': 77779,
  '449.p.41528': 183082,
  '449.p.41539': 77967,
  '449.p.41540': 147209,
  '449.p.41544': 183114,
  '449.p.41549': 90868,
  '449.p.41555': 78138,
  '449.p.41556': 98500,
  '449.p.41574': 82143,
  '449.p.41580': 83581,
  '449.p.41581': 83810,
  '449.p.41674': 97814,
  '449.p.41675': 53746,
  '449.p.7777': 2973,
  '449.p.8795': 4332,
}

export const espnToPffMap: { [key: string]: number } = {
  'espn-buf': 4,
  'espn--16002': 4,
  'espn-mia': 17,
  'espn--16015': 17,
  'espn-ne': 19,
  'espn--16017': 19,
  'espn-nyj': 22,
  'espn--16020': 22,
  'espn-bal': 3,
  'espn--16033': 3,
  'espn-cin': 7,
  'espn--16004': 7,
  'espn-cle': 8,
  'espn--16005': 8,
  'espn-pit': 25,
  'espn--16023': 25,
  'espn-hou': 13,
  'espn--16034': 13,
  'espn-ind': 14,
  'espn--16011': 14,
  'espn-jax': 15,
  'espn--16030': 15,
  'espn-ten': 31,
  'espn--16010': 31,
  'espn-den': 10,
  'espn--16007': 10,
  'espn-kc': 16,
  'espn--16012': 16,
  'espn-lv': 23,
  'espn--16013': 23,
  'espn-lac': 27,
  'espn--16024': 27,
  'espn-dal': 9,
  'espn--16006': 9,
  'espn-nyg': 21,
  'espn--16019': 21,
  'espn-phi': 24,
  'espn--16021': 24,
  'espn-wsh': 32,
  'espn--16028': 32,
  'espn-chi': 6,
  'espn--16003': 6,
  'espn-det': 11,
  'espn--16008': 11,
  'espn-gb': 12,
  'espn--16009': 12,
  'espn-min': 18,
  'espn--16016': 18,
  'espn-atl': 2,
  'espn--16001': 2,
  'espn-car': 5,
  'espn--16029': 5,
  'espn-no': 20,
  'espn--16018': 20,
  'espn-tb': 30,
  'espn--16027': 30,
  'espn-ari': 1,
  'espn--16022': 1,
  'espn-lar': 26,
  'espn--16014': 26,
  'espn-sf': 28,
  'espn--16025': 28,
  'espn-sea': 29,
  'espn--16026': 29,
  'espn-4050373': 61664,
  'espn-4036032': 40399,
  'espn-3923392': 47234,
  'espn-4240858': 60593,
  'espn-4036026': 42349,
  'espn-4240861': 61068,
  'espn-3124679': 29578,
  'espn-3048898': 21473,
  'espn-3042749': 46517,
  'espn-3917232': 33010,
  'espn-4259305': 61610,
  'espn-4259308': 57385,
  'espn-3128724': 11927,
  'espn-15795': 7808,
  'espn-3915165': 34038,
  'espn-3128720': 45783,
  'espn-3675550': 47412,
  'espn-15807': 7810,
  'espn-4249087': 57643,
  'espn-2977187': 11824,
  'espn-4572680': 104034,
  'espn-4371974': 84137,
  'espn-4427267': 102929,
  'espn-4240904': 60556,
  'espn-15818': 7857,
  'espn-3886598': 48027,
  'espn-4685201': 131770,
  'espn-3917315': 38334,
  'espn-4429202': 122999,
  'espn-4429205': 123000,
  'espn-3122672': 45747,
  'espn-15835': 7816,
  'espn-4062711': 61765,
  'espn-15839': 7859,
  'espn-4257188': 55038,
  'espn-15847': 7844,
  'espn-3042778': 11760,
  'espn-3139033': 31615,
  'espn-4027873': 25276,
  'espn-3120590': 29466,
  'espn-15864': 7820,
  'espn-4242873': 57136,
  'espn-2508256': 9576,
  'espn-4570561': 99143,
  'espn-15880': 7822,
  'espn-4369863': 84434,
  'espn-2991662': 11873,
  'espn-4431453': 122624,
  'espn-4372066': 82603,
  'espn-4372063': 84288,
  'espn-4427366': 97636,
  'espn-3929645': 28305,
  'espn-4036146': 44789,
  'espn-4372071': 83701,
  'espn-3929637': 34061,
  'espn-4572786': 61295,
  'espn-15920': 7962,
  'espn-4036131': 42388,
  'espn-3120659': 48181,
  'espn-3892775': 13725,
  'espn-4036133': 40912,
  'espn-3929630': 45791,
  'espn-4036129': 62825,
  'espn-4820592': 143789,
  'espn-3929633': 36271,
  'espn-4372096': 84005,
  'espn-4427391': 99158,
  'espn-4689546': 121926,
  'espn-4368003': 109811,
  'espn-4361741': 77672,
  'espn-15948': 7880,
  'espn-12731': 5180,
  'espn-4374033': 83740,
  'espn-4371986': 84135,
  'espn-4036224': 45835,
  'espn-4408854': 94384,
  'espn-3894912': 34427,
  'espn-4374045': 84036,
  'espn-3915381': 34761,
  'espn-15965': 7958,
  'espn-4036211': 47663,
  'espn-3122818': 47216,
  'espn-4570674': 99141,
  'espn-4361777': 83665,
  'espn-2983509': 12329,
  'espn-3071572': 51593,
  'espn-4372016': 84088,
  'espn-4372019': 83601,
  'espn-4372026': 82647,
  'espn-4243003': 57208,
  'espn-16002': 7912,
  'espn-3919557': 47964,
  'espn-2975417': 48862,
  'espn-4366031': 78874,
  'espn-4046530': 27303,
  'espn-4697815': 131633,
  'espn-4243160': 61220,
  'espn-2979501': 10821,
  'espn-4259545': 57212,
  'espn-4046522': 40294,
  'espn-3929785': 47861,
  'espn-4036275': 42266,
  'espn-4259553': 60387,
  'espn-13987': 6162,
  'espn-2979520': 11859,
  'espn-2985659': 11096,
  'espn-3122840': 11767,
  'espn-4239086': 55086,
  'espn-2971281': 12247,
  'espn-3919510': 47996,
  'espn-14012': 6187,
  'espn-4382466': 84292,
  'espn-3124900': 17287,
  'espn-3915416': 48267,
  'espn-4361988': 77885,
  'espn-4259592': 51708,
  'espn-4245131': 61669,
  'espn-2580216': 9859,
  'espn-4036348': 26075,
  'espn-3917569': 27003,
  'espn-4245144': 61811,
  'espn-4032247': 22373,
  'espn-4408988': 94375,
  'espn-3043078': 10679,
  'espn-2979590': 9593,
  'espn-3915511': 28022,
  'espn-4036335': 48170,
  'espn-14053': 6216,
  'espn-4259499': 61643,
  'espn-4374187': 84480,
  'espn-3917546': 13534,
  'espn-3126997': 94379,
  'espn-3122899': 28991,
  'espn-3915486': 47124,
  'espn-4245174': 61812,
  'espn-3886818': 45914,
  'espn-4589245': 143358,
  'espn-3886809': 47901,
  'espn-2973405': 11028,
  'espn-3118906': 57157,
  'espn-3929924': 36049,
  'espn-4036419': 60417,
  'espn-3045169': 21956,
  'espn-4036416': 42345,
  'espn-3118892': 66915,
  'espn-3043116': 10760,
  'espn-3051308': 12134,
  'espn-3049290': 30887,
  'espn-3929914': 35639,
  'espn-4362081': 77069,
  'espn-3917612': 45866,
  'espn-4362087': 77761,
  'espn-3045144': 10689,
  'espn-4038441': 45865,
  'espn-4241263': 60917,
  'espn-4243315': 57472,
  'espn-3045138': 11762,
  'espn-4274040': 65088,
  'espn-4243322': 60596,
  'espn-4036378': 40306,
  'espn-3921690': 47083,
  'espn-4243331': 61071,
  'espn-3122976': 29413,
  'espn-3045147': 11860,
  'espn-3051381': 46453,
  'espn-3045238': 49166,
  'espn-3125107': 47121,
  'espn-14163': 6332,
  'espn-4038524': 41401,
  'espn-4046715': 41247,
  'espn-3123052': 39417,
  'espn-3061612': 11920,
  'espn-4374302': 84470,
  'espn-3123076': 11784,
  'espn-3123075': 47961,
  'espn-2582410': 9518,
  'espn-4362019': 77975,
  'espn-3121023': 47272,
  'espn-3125116': 11844,
  'espn-2565969': 9896,
  'espn-3135321': 47509,
  'espn-3917668': 34934,
  'espn-3843945': 47043,
  'espn-4046692': 42312,
  'espn-3120980': 48143,
  'espn-4429615': 97268,
  'espn-4036448': 41991,
  'espn-4241205': 57308,
  'espn-4411193': 91470,
  'espn-4411192': 91468,
  'espn-4390717': 4474,
  'espn-4046676': 40498,
  'espn-4046678': 40216,
  'espn-4421446': 94383,
  'espn-4036431': 40692,
  'espn-4249417': 61637,
  'espn-2975674': 12193,
  'espn-4427728': 97432,
  'espn-2971573': 11161,
  'espn-3051439': 39462,
  'espn-3127211': 28219,
  'espn-4241372': 61568,
  'espn-4241374': 60997,
  'espn-4040627': 42541,
  'espn-4429795': 122474,
  'espn-4360174': 84117,
  'espn-4241389': 61570,
  'espn-4040612': 42353,
  'espn-3139477': 11765,
  'espn-4360175': 77652,
  'espn-4241401': 57372,
  'espn-3045260': 12253,
  'espn-4362238': 77787,
  'espn-4241410': 60509,
  'espn-4372485': 62890,
  'espn-4567048': 97199,
  'espn-3043234': 11979,
  'espn-4362249': 78171,
  'espn-4241416': 57373,
  'espn-4360078': 78038,
  'espn-16339': 8437,
  'espn-4360086': 54037,
  'espn-3125232': 27413,
  'espn-3915772': 47214,
  'espn-4685720': 123943,
  'espn-2977800': 11107,
  'espn-3127306': 48113,
  'espn-3059722': 11792,
  'espn-4057082': 115664,
  'espn-3932144': 34255,
  'espn-3127292': 30762,
  'espn-3930086': 35883,
  'espn-4243371': 57556,
  'espn-2576336': 9487,
  'espn-3043275': 10715,
  'espn-3917792': 39395,
  'espn-4372414': 84302,
  'espn-4243389': 61418,
  'espn-4032473': 63707,
  'espn-4599739': 123091,
  'espn-4040655': 48236,
  'espn-2971618': 9453,
  'espn-3930066': 47447,
  'espn-4362186': 78148,
  'espn-2576434': 9448,
  'espn-4040774': 26394,
  'espn-4243537': 55410,
  'espn-4569173': 98689,
  'espn-4040761': 40485,
  'espn-4360290': 77917,
  'espn-4360294': 78050,
  'espn-3915823': 47698,
  'espn-2576399': 10728,
  'espn-4360306': 78021,
  'espn-3139602': 45734,
  'espn-3127313': 13076,
  'espn-4360310': 77632,
  'espn-3127310': 47259,
  'espn-4567156': 99218,
  'espn-2576414': 9783,
  'espn-2531358': 10211,
  'espn-3917849': 34914,
  'espn-4259976': 61359,
  'espn-4040715': 40291,
  'espn-16460': 8288,
  'espn-2975863': 11929,
  'espn-4241424': 61577,
  'espn-3917960': 48341,
  'espn-3047536': 34985,
  'espn-4372505': 84037,
  'espn-4249624': 61079,
  'espn-3917940': 47928,
  'espn-2578570': 10725,
  'espn-16486': 8585,
  'espn-4360234': 82583,
  'espn-4360231': 83962,
  'espn-4360238': 83659,
  'espn-4372526': 83774,
  'espn-4241457': 57121,
  'espn-4567096': 97400,
  'espn-4241463': 61103,
  'espn-4241464': 60323,
  'espn-4360248': 83964,
  'espn-3917914': 44920,
  'espn-4241474': 57120,
  'espn-4259908': 61457,
  'espn-4241478': 61100,
  'espn-14471': 6515,
  'espn-3700815': 34045,
  'espn-4241479': 60326,
  'espn-2578533': 9509,
  'espn-4689988': 121451,
  'espn-3119195': 45851,
  'espn-2574511': 10835,
  'espn-4430027': 101690,
  'espn-4567246': 99163,
  'espn-4722893': 138346,
  'espn-4362452': 77745,
  'espn-3117256': 47304,
  'espn-2310331': 48054,
  'espn-3918003': 47338,
  'espn-3930298': 47282,
  'espn-3117251': 11763,
  'espn-3911853': 109795,
  'espn-4722908': 138351,
  'espn-2576581': 10741,
  'espn-4362477': 78196,
  'espn-12460': 5443,
  'espn-4249836': 66619,
  'espn-3045523': 12270,
  'espn-4038815': 40693,
  'espn-4360438': 84109,
  'espn-4038818': 28486,
  'espn-12477': 5277,
  'espn-4212989': 51842,
  'espn-12483': 4924,
  'espn-4034704': 91824,
  'espn-4247812': 61478,
  'espn-3125404': 12051,
  'espn-4362504': 77887,
  'espn-3932423': 48209,
  'espn-4043016': 29573,
  'espn-4259979': 61361,
  'espn-2574576': 9590,
  'espn-4245645': 57133,
  'espn-2576623': 9447,
  'espn-3115255': 48036,
  'espn-3115252': 38265,
  'espn-4241555': 57272,
  'espn-4212884': 11948,
  'espn-2980077': 10790,
  'espn-3121410': 47940,
  'espn-3121409': 11993,
  'espn-2511109': 9683,
  'espn-3940587': 91465,
  'espn-3051738': 47809,
  'espn-4212909': 11981,
  'espn-3119317': 48107,
  'espn-8439': 2241,
  'espn-4034779': 41372,
  'espn-3926231': 32933,
  'espn-3121378': 47123,
  'espn-3049698': 51837,
  'espn-3115314': 47908,
  'espn-4428119': 97187,
  'espn-4608362': 143786,
  'espn-2515270': 9601,
  'espn-4034862': 42058,
  'espn-2978109': 12303,
  'espn-2980120': 12239,
  'espn-4038952': 42701,
  'espn-3059989': 12282,
  'espn-3121427': 11795,
  'espn-4430191': 97116,
  'espn-4243830': 61522,
  'espn-3121422': 48229,
  'espn-4360569': 62946,
  'espn-4038941': 28237,
  'espn-4258170': 61442,
  'espn-3115306': 11872,
  'espn-4258173': 61441,
  'espn-4362619': 83689,
  'espn-3932442': 47967,
  'espn-4040980': 42060,
  'espn-2998565': 12078,
  'espn-4362630': 84271,
  'espn-4362628': 84270,
  'espn-2574630': 10841,
  'espn-3115293': 26152,
  'espn-3055899': 11988,
  'espn-3932430': 34164,
  'espn-4241802': 61697,
  'espn-4034952': 26408,
  'espn-4039050': 40006,
  'espn-4034948': 40217,
  'espn-4039043': 47808,
  'espn-4034950': 26448,
  'espn-4034949': 44140,
  'espn-3051889': 10657,
  'espn-3115378': 44771,
  'espn-3871102': 34579,
  'espn-4034946': 39987,
  'espn-4282647': 84213,
  'espn-3068267': 12164,
  'espn-16731': 8655,
  'espn-16733': 8647,
  'espn-3916148': 27126,
  'espn-4039034': 12659,
  'espn-4362523': 77888,
  'espn-3051909': 28121,
  'espn-16737': 8642,
  'espn-3115394': 47702,
  'espn-4045163': 40555,
  'espn-4372780': 84025,
  'espn-2969939': 9434,
  'espn-3115349': 32565,
  'espn-3139925': 27414,
  'espn-2576716': 9538,
  'espn-3924327': 39517,
  'espn-4428085': 98499,
  'espn-16757': 8671,
  'espn-16760': 8697,
  'espn-10621': 3794,
  'espn-3051876': 11778,
  'espn-3115359': 47307,
  'espn-16782': 8731,
  'espn-4035004': 42683,
  'espn-3049899': 12042,
  'espn-16799': 8696,
  'espn-16800': 8688,
  'espn-3047876': 26104,
  'espn-3916204': 48037,
  'espn-3049916': 12271,
  'espn-16813': 8755,
  'espn-3051926': 46027,
  'espn-3914151': 47034,
  'espn-3912092': 91014,
  'espn-4569587': 101735,
  'espn-4362748': 83714,
  'espn-4034964': 42134,
  'espn-3924365': 47294,
  'espn-4039057': 42247,
  'espn-4569609': 97302,
  'espn-4258190': 57070,
  'espn-4047365': 45953,
  'espn-4258195': 61439,
  'espn-4239768': 57441,
  'espn-3914240': 47743,
  'espn-4039160': 40630,
  'espn-4241820': 60555,
  'espn-4045305': 42130,
  'espn-2978308': 11371,
  'espn-4424106': 95015,
  'espn-2976212': 9579,
  'espn-3895785': 34311,
  'espn-2574808': 11254,
  'espn-4360635': 84000,
  'espn-3918298': 46601,
  'espn-4035020': 42257,
  'espn-4035019': 41406,
  'espn-4258248': 60915,
  'espn-3039707': 11757,
  'espn-3914307': 33465,
  'espn-3052083': 47972,
  'espn-4244049': 61182,
  'espn-4686422': 122518,
  'espn-4047422': 22777,
  'espn-4360797': 77965,
  'espn-14876': 7014,
  'espn-4031033': 42109,
  'espn-14880': 7102,
  'espn-14881': 7077,
  'espn-3052096': 12206,
  'espn-2976316': 10681,
  'espn-4035115': 41933,
  'espn-3895856': 47546,
  'espn-3052056': 47417,
  'espn-4426354': 99229,
  'espn-4692590': 124282,
  'espn-4596334': 123826,
  'espn-3895835': 47044,
  'espn-4362874': 83517,
  'espn-3910176': 28729,
  'espn-4035102': 27436,
  'espn-4239993': 61211,
  'espn-4383351': 91399,
  'espn-4239996': 57185,
  'espn-3895827': 38287,
  'espn-2576925': 9637,
  'espn-4686472': 122937,
  'espn-4012556': 11347,
  'espn-4362887': 82118,
  'espn-4569618': 97405,
  'espn-4241941': 57289,
  'espn-4035198': 40666,
  'espn-3052166': 12122,
  'espn-4037235': 42237,
  'espn-3054212': 11855,
  'espn-4360739': 84424,
  'espn-4241961': 57301,
  'espn-4260394': 60516,
  'espn-4039274': 25859,
  'espn-3916387': 46416,
  'espn-4428331': 97605,
  'espn-4035170': 42295,
  'espn-4260406': 57384,
  'espn-4430388': 121495,
  'espn-2972236': 11926,
  'espn-4360761': 84423,
  'espn-2576980': 9435,
  'espn-4239934': 57165,
  'espn-3975763': 22842,
  'espn-4241983': 61448,
  'espn-3914328': 47448,
  'espn-4241985': 57366,
  'espn-2980453': 11889,
  'espn-4039253': 42018,
  'espn-4422214': 94356,
  'espn-4239944': 60859,
  'espn-4430539': 101694,
  'espn-14993': 7163,
  'espn-4037311': 40986,
  'espn-2972331': 12007,
  'espn-4039358': 40448,
  'espn-4360939': 84296,
  'espn-4432620': 129799,
  'espn-4426475': 99169,
  'espn-3123857': 45038,
  'espn-3914397': 47481,
  'espn-4426485': 97774,
  'espn-4250360': 60396,
  'espn-3676833': 47658,
  'espn-4035222': 45775,
  'espn-3916430': 48103,
  'espn-4426502': 86519,
  'espn-4379401': 83974,
  'espn-3916433': 47468,
  'espn-4039307': 41838,
  'espn-4379399': 83666,
  'espn-4401805': 111900,
  'espn-2577134': 9527,
  'espn-4061956': 91479,
  'espn-4426386': 99467,
  'espn-2976499': 9437,
  'espn-4426385': 97630,
  'espn-4240021': 57206,
  'espn-4426388': 101701,
  'espn-4240023': 60896,
  'espn-4696736': 128347,
  'espn-2468609': 12112,
  'espn-15072': 7158,
  'espn-4035312': 40316,
  'espn-4362921': 78092,
  'espn-4426407': 99248,
  'espn-3932905': 33441,
  'espn-4426426': 98214,
  'espn-3123944': 45934,
  'espn-4432577': 129759,
  'espn-2972460': 9459,
  'espn-4361050': 83955,
  'espn-4068152': 61639,
  'espn-4242270': 61544,
  'espn-3916587': 25705,
  'espn-4367209': 84068,
  'espn-4746079': 143780,
  'espn-3918639': 11799,
  'espn-4692835': 111284,
  'espn-4047648': 40445,
  'espn-4047650': 47864,
  'espn-4047646': 48327,
  'espn-3916566': 23503,
  'espn-3916564': 46481,
  'espn-15168': 7323,
  'espn-3040035': 12258,
  'espn-4569987': 98692,
  'espn-4428557': 97195,
  'espn-3728262': 47895,
  'espn-4035465': 40689,
  'espn-4373266': 77985,
  'espn-3124084': 39235,
  'espn-4426515': 100632,
  'espn-4258595': 60995,
  'espn-4242214': 57279,
  'espn-3124092': 46454,
  'espn-17258': 8999,
  'espn-3912550': 34001,
  'espn-3912547': 29048,
  'espn-4037478': 40903,
  'espn-11122': 3471,
  'espn-2976592': 10674,
  'espn-3928925': 25582,
  'espn-4035426': 40411,
  'espn-4242231': 61401,
  'espn-4426553': 99697,
  'espn-2972515': 12324,
  'espn-3124069': 11991,
  'espn-3126115': 48152,
  'espn-4037457': 45859,
  'espn-4039505': 40951,
  'espn-4035403': 47800,
  'espn-4367178': 83574,
  'espn-4367175': 84463,
  'espn-2577327': 9502,
  'espn-3122103': 47311,
  'espn-13199': 5609,
  'espn-4430802': 124044,
  'espn-4248528': 66581,
  'espn-4035526': 39988,
  'espn-4242392': 60994,
  'espn-4430807': 123126,
  'espn-4039607': 25512,
  'espn-17315': 9302,
  'espn-3040151': 11901,
  'espn-3115928': 48078,
  'espn-13232': 5619,
  'espn-4262900': 64309,
  'espn-3134353': 12087,
  'espn-4242431': 62942,
  'espn-3926936': 37397,
  'espn-4242433': 62979,
  'espn-3052450': 23519,
  'espn-3150744': 33285,
  'espn-3910544': 13750,
  'espn-4262921': 61398,
  'espn-3122168': 48021,
  'espn-4430737': 97118,
  'espn-3050481': 66924,
  'espn-4565908': 123578,
  'espn-3050478': 11908,
  'espn-4385690': 91791,
  'espn-17372': 8990,
  'espn-4056052': 40034,
  'espn-4242335': 57488,
  'espn-3046401': 48017,
  'espn-2577417': 10769,
  'espn-3046399': 12137,
  'espn-3128317': 38275,
  'espn-4428718': 101106,
  'espn-4688813': 122832,
  'espn-4242355': 61081,
  'espn-15349': 7330,
  'espn-4047836': 40988,
  'espn-3040206': 46440,
  'espn-4248504': 55307,
  'espn-3122154': 44889,
  'espn-4037591': 40221,
  'espn-4242369': 52008,
  'espn-3916749': 13756,
  'espn-4035538': 45719,
  'espn-4035537': 40547,
  'espn-4035656': 27948,
  'espn-4052042': 22425,
  'espn-4242512': 60341,
  'espn-4054085': 48329,
  'espn-4035098': 45181,
  'espn-17427': 8932,
  'espn-4242519': 57509,
  'espn-4240472': 60878,
  'espn-3052587': 46518,
  'espn-4361307': 75916,
  'espn-4426844': 98027,
  'espn-3128390': 48135,
  'espn-4242540': 52130,
  'espn-4242546': 52269,
  'espn-3929118': 48055,
  'espn-4373626': 83639,
  'espn-3953687': 156112,
  'espn-3046439': 10669,
  'espn-4242557': 52266,
  'espn-4426875': 97599,
  'espn-4373632': 82094,
  'espn-4916349': 156095,
  'espn-4373642': 84147,
  'espn-3148929': 143769,
  'espn-3128429': 48164,
  'espn-4430871': 123056,
  'espn-4430878': 129758,
  'espn-3116165': 11839,
  'espn-3116164': 47153,
  'espn-3128452': 47050,
  'espn-3128451': 48228,
  'espn-3722362': 46487,
  'espn-3116158': 50714,
  'espn-4035693': 40573,
  'espn-4035687': 29049,
  'espn-4361259': 82096,
  'espn-4428850': 99711,
  'espn-2473037': 10207,
  'espn-2573401': 10744,
  'espn-15478': 7469,
  'espn-4035676': 40341,
  'espn-11387': 4467,
  'espn-4035671': 46448,
  'espn-3116136': 29539,
  'espn-11394': 4474,
  'espn-2511973': 9786,
  'espn-4240455': 57189,
  'espn-4242504': 61588,
  'espn-4429006': 129519,
  'espn-3138744': 47358,
  'espn-4361426': 84376,
  'espn-4367567': 109239,
  'espn-4035776': 40408,
  'espn-4429013': 123974,
  'espn-3120303': 20994,
  'espn-3699902': 48110,
  'espn-4240600': 61245,
  'espn-4361432': 84329,
  'espn-4240603': 57196,
  'espn-4429022': 124087,
  'espn-4361438': 84330,
  'espn-4429025': 123085,
  'espn-4685035': 123072,
  'espn-4248822': 64310,
  'espn-4240631': 55632,
  'espn-2579604': 9462,
  'espn-3116172': 32819,
  'espn-3916945': 25578,
  'espn-3116188': 46508,
  'espn-3931397': 35014,
  'espn-4250764': 60492,
  'espn-4426891': 99146,
  'espn-4361370': 84355,
  'espn-3931391': 48080,
  'espn-4361372': 84029,
  'espn-3054850': 11822,
  'espn-3155188': 66957,
  'espn-4035826': 40568,
  'espn-4428963': 98975,
  'espn-4373673': 84142,
  'espn-2577667': 9964,
  'espn-4373678': 84140,
  'espn-3042519': 11937,
  'espn-3126486': 48274,
  'espn-4240573': 61324,
  'espn-4361409': 84375,
  'espn-4240577': 61327,
  'espn-2577641': 10820,
  'espn-4361411': 84085,
  'espn-4361417': 84084,
  'espn-4361418': 83542,
  'espn-3120348': 11817,
  'espn-3040569': 11932,
  'espn-4035912': 23226,
  'espn-4259147': 60864,
  'espn-3124537': 39587,
  'espn-4248909': 57394,
  'espn-3046704': 9679,
  'espn-4427095': 104155,
  'espn-4427105': 99470,
  'espn-4259169': 57159,
  'espn-4361577': 77986,
  'espn-4035886': 41668,
  'espn-4429160': 124305,
  'espn-4361579': 77803,
  'espn-4240750': 55226,
  'espn-3122449': 47792,
  'espn-4244856': 52195,
  'espn-2514206': 9550,
  'espn-15683': 7604,
  'espn-3144991': 48369,
  'espn-4033806': 44539,
  'espn-3116407': 46569,
  'espn-3116406': 10799,
  'espn-3915145': 35096,
  'espn-4048259': 42873,
  'espn-4363538': 77083,
  'espn-4240657': 57337,
  'espn-4371733': 77737,
  'espn-3933568': 33158,
  'espn-4240661': 61510,
  'espn-3044720': 11890,
  'espn-4429086': 122906,
  'espn-4429084': 124393,
  'espn-4048244': 45817,
  'espn-4044144': 42196,
  'espn-3925357': 48262,
  'espn-4570409': 97989,
  'espn-4879650': 111276,
  'espn-3046779': 10635,
  'espn-3925347': 35134,
  'espn-4361516': 84041,
  'espn-4048228': 42275,
  'espn-4240689': 60389,
  'espn-3925346': 32342,
  'espn-4361529': 83747,
  'espn-3116365': 47436,
  'espn-4240703': 60515,
  'espn-3116389': 11869,
  'espn-4250945': 63721,
  'espn-3052897': 47257,
  'espn-3116385': 11803,
  'espn-4044111': 40364,
  'espn-3051392': 10638,
  'espn-3059915': 11841,
  'espn-4037361': 43034,
  'espn-3138826': 51222,
  'espn-3116593': 11796,
  'espn-3050073': 24904,
  'espn-4034790': 456,
  'espn-16716': 8648,
  'espn-16140': 7982,
  'espn-4362478': 77763,
  'espn-4259166': 57739,
  'espn-4039359': 41193,
  'espn-14979': 7050,
  'espn-4434153': 169628,
  'espn-3915411': 45769,
  'espn-4034849': 46416,
  'espn-4259619': 57502,
  'espn-4360423': 82134,
  'espn-4366963': 88664,
  'espn-4372561': 83806,
  'espn-4426338': 97790,
  'espn-4426348': 97993,
  'espn-4426948': 101634,
  'espn-4427985': 98847,
  'espn-4428209': 100660,
  'espn-4428532': 99207,
  'espn-4428678': 97399,
  'espn-4429023': 124247,
  'espn-4429033': 124017,
  'espn-4429096': 129574,
  'espn-4429275': 131705,
  'espn-4429501': 101072,
  'espn-4429684': 97078,
  'espn-4430034': 99197,
  'espn-4430637': 123153,
  'espn-4430834': 131905,
  'espn-4430968': 123727,
  'espn-4431280': 146567,
  'espn-4431299': 73774,
  'espn-4431452': 143969,
  'espn-4431492': 129604,
  'espn-4431545': 144138,
  'espn-4431588': 144717,
  'espn-4431611': 144622,
  'espn-4432665': 146715,
  'espn-4432708': 145056,
  'espn-4432773': 146760,
  'espn-4433970': 144918,
  'espn-4566192': 131765,
  'espn-4569682': 147476,
  'espn-4575665': 100894,
  'espn-4595348': 146759,
  'espn-4596448': 144970,
  'espn-4597500': 146712,
  'espn-4612826': 124030,
  'espn-4613104': 66272,
  'espn-4635008': 144949,
  'espn-4678008': 144721,
  'espn-4682745': 146934,
  'espn-4683062': 144718,
  'espn-4685247': 145159,
  'espn-4686361': 146646,
  'espn-4690013': 121717,
  'espn-4690923': 122616,
  'espn-4692022': 123904,
  'espn-4695404': 138299,
  'espn-4696700': 129423,
  'espn-4696882': 145773,
  'espn-3886812': 46401,
  'espn-4360516': 84226,
  'espn-4427834': 52595,
  'espn-4360799': 77615,
  'espn-4426339': 97408,
  'espn-4426496': 101486,
  'espn-4427278': 104136,
  'espn-4427575': 101135,
  'espn-4429001': 123926,
  'espn-4429148': 129787,
  'espn-4430723': 98508,
  'espn-4567104': 98965,
  'espn-4569382': 97623,
  'espn-4569603': 97108,
  'espn-4686104': 76366,
  'espn-4689936': 121959,
  'espn-2330': 698,
  'espn-4259324': 61004,
  'espn-4362018': 77881,
}

export const getPffPlayerId = (id: string) => {
  return sleeperToPffMap[id] || yahooToPffMap[id] || espnToPffMap[id]
}
