import { type SeasonStat, type PositionGrade, SeasonStatLabels, SeasonStatKeys } from '@pff-consumer/schema'
import { getOrdinalNumber } from '../../../stats/getOrdinalNumber'
import { getTeamIconSlugFromAbbreviation } from '../../../team/getTeamIconSlugFromAbbreviation'
import { type PlayerStatDataProps } from './get-player-stat-data.props'
import { getPlayerStatKeys } from './get-player-stat-keys'

const getStat = (
  gradeFacetMap: Record<string, SeasonStat>,
  seasonStatKeyMap: Record<string, SeasonStat>,
  statKey: SeasonStatKeys,
  position: PlayerStatDataProps['position'],
  season: PlayerStatDataProps['season'],
  isInSeason: PlayerStatDataProps['isInSeason']
) => {
  let stat
  if (['offense', 'defence'].includes(statKey)) {
    stat = gradeFacetMap[statKey]
  } else {
    stat = seasonStatKeyMap[statKey]
  }
  const title = isInSeason ? SeasonStatLabels[statKey] : `${SeasonStatLabels[statKey]} ${season}`

  const primaryStat = `${stat?.statValue || 0}`
  const secondaryStat = stat?.rankTotal
    ? `${getOrdinalNumber(stat?.rank)}/${stat?.rankTotal} - ${position}s`
    : 'Not Ranked'

  return { title, primaryStat, secondaryStat }
}

const getMatchupStats = (
  matchups: PlayerStatDataProps['matchups'],
  weekAbv: PlayerStatDataProps['weekAbv'],
  statKey: SeasonStatKeys
) => {
  // Display next opponent card if matchups are unavailable for the player position
  const hasNoMatchups = matchups.overallScore === 0
  const title = hasNoMatchups
    ? SeasonStatLabels[SeasonStatKeys.NEXT_OPPONENT]
    : `WK ${weekAbv} ${SeasonStatLabels[statKey]}`
  const primaryStat = getTeamIconSlugFromAbbreviation(matchups.opponent)
  const secondaryStat = hasNoMatchups ? '' : matchups.overallScore
  return {
    title,
    primaryStat,
    secondaryStat,
  }
}

export const getPlayerStatData = ({
  isInSeason,
  seasonStats,
  grades = [],
  position,
  matchups,
  season,
  weekAbv,
}: PlayerStatDataProps) => {
  const statKeys = getPlayerStatKeys(isInSeason, position)

  const seasonStatKeyMap = seasonStats.reduce(
    (acc: Record<string, SeasonStat>, stat: SeasonStat) => ({ ...acc, [stat.statKey]: stat }),
    {}
  )

  const gradeFacetMap = grades.reduce((acc: Record<string, SeasonStat>, grade: PositionGrade) => {
    return {
      ...acc,
      [grade.facet]: {
        statKey: grade.facet,
        statValue: grade.gradeValue,
        rank: grade.gradeRank,
        rankTotal: grade.gradeRankTotal,
      },
    }
  }, {})

  const statsCardData = statKeys.map((statKey) => {
    const { title, primaryStat, secondaryStat } =
      statKey === 'matchups'
        ? getMatchupStats(matchups, weekAbv, statKey)
        : getStat(gradeFacetMap, seasonStatKeyMap, statKey, position, season, isInSeason)

    return {
      key: statKey,
      title,
      primaryStat,
      secondaryStat,
    }
  })

  return statsCardData
}
