import type { PlayerPropKey, SDKReceivingMetricResponse } from '@pff-consumer/schema'
import { SDK_SEASON, NFLWeekAbbreviation } from '@pff-consumer/schema'

export const DEFAULT_SPORTSBOOK = 'FD'

export const footballEndpoints = {
  getTeamsForSeason: () => {
    return { url: `football/v1/sdk/nfl/seasons/${SDK_SEASON}/teams` }
  },
  getSDKEvents: ({
    sportsbookId = DEFAULT_SPORTSBOOK,
    season = SDK_SEASON,
    weekAbv,
    scoreData = false,
  }: {
    sportsbookId?: string
    season?: number
    weekAbv?: string
    scoreData?: boolean
  }) => {
    return {
      url: weekAbv
        ? `football/v1/sdk/nfl/seasons/${season}/weeks/${weekAbv}/events?sportsbook=${sportsbookId}&scoreData=${scoreData}`
        : `football/v1/sdk/nfl/seasons/${season}/events?sportsbook=${sportsbookId}&scoreData=${scoreData}`,
    }
  },
  getSDKHotPlayersByEvent: ({
    sportsbookId,
    eventId,
    markets,
  }: {
    sportsbookId: string
    eventId: number
    markets: PlayerPropKey[]
  }) => {
    return {
      url: `football/v1/sdk/nfl/events/${eventId}/hot-players?sportsbook=${sportsbookId}&markets=${markets.toString()}`,
    }
  },
  getSDKHotPlayersByWeek: ({
    sportsbookId = DEFAULT_SPORTSBOOK,
    season = SDK_SEASON,
    weekAbv,
    markets,
  }: {
    sportsbookId: string
    season?: number
    weekAbv: string
    markets: PlayerPropKey[]
  }) => {
    return {
      url: `/football/v1/sdk/nfl/seasons/${season}/weeks/${weekAbv}/hot-players?sportsbook=${sportsbookId}&markets=${markets.toString()}`,
    }
  },
  getSDKMismatchesByEvent: ({
    sportsbookId,
    eventId,
    markets,
  }: {
    sportsbookId: string
    eventId: number
    markets: PlayerPropKey[]
  }) => {
    return {
      url: `football/v1/sdk/nfl/events/${eventId}/biggest-mismatches?sportsbook=${sportsbookId}&markets=${markets.toString()}`,
    }
  },
  getSDKMismatchesByWeek: ({
    sportsbookId = DEFAULT_SPORTSBOOK,
    season = SDK_SEASON,
    weekAbv,
    markets,
  }: {
    sportsbookId: string
    season?: number
    weekAbv: string
    markets: PlayerPropKey[]
  }) => {
    return {
      url: `/football/v1/sdk/nfl/seasons/${season}/weeks/${weekAbv}/biggest-mismatches?sportsbook=${sportsbookId}&markets=${markets.toString()}`,
    }
  },
  getSDKHistoricalPerformance: ({
    playerId,
    propKey,
    eventIds,
  }: {
    playerId: number
    propKey: PlayerPropKey
    eventIds: number[]
  }) => {
    return {
      url: `football/v1/sdk/nfl/players/${playerId}/markets/${propKey}/historical-performances?eventIds=${eventIds.toString()}`,
    }
  },
  getSDKPlayerPropsBySeasonWeek: ({
    detailed = false,
    sportsbookId = DEFAULT_SPORTSBOOK,
    season = SDK_SEASON,
    weekAbv,
  }: {
    detailed?: boolean
    sportsbookId: string
    season?: number
    weekAbv: string
  }) => {
    return {
      url: `football/v1/sdk/nfl/seasons/${season}/weeks/${weekAbv}/player-props?sportsbook=${sportsbookId}&detailed=${detailed}`,
    }
  },
  getSDKRecentDefencesForEvent: ({ eventId }: { eventId: number }) => {
    return { url: `football/v1/sdk/nfl/events/${eventId}/recent-defenses` }
  },
  getSDKSimilarDefencesForEvent: ({ eventId }: { eventId: number }) => {
    return { url: `football/v1/sdk/nfl/events/${eventId}/similar-defenses` }
  },
  getMismatchesForGame: ({ id, sportsbookId = DEFAULT_SPORTSBOOK }: { id: number; sportsbookId: string }) => {
    return { url: `football/v1/games/${id}/mismatches?sportsbookId=${sportsbookId}` }
  },
  getAllMismatchesForGame: ({ id }: { id: number }) => {
    return { url: `football/v1/games/${id}/mismatches/all` }
  },
  getPlayerById: ({ id }: { id: number }) => {
    return { url: `football/v1/sdk/players/${id}` }
  },
  getPlayersById: ({ ids }: { ids: number[] }) => {
    return { url: `football/v1/players?playerIds=${ids.toString()}` }
  },
  getPlayerGradesByIds: ({ ids }: { ids: number[] }) => {
    return { url: `football/v1/nfl/latest-player-position-grades?playerIds=${ids.join(',')}` }
  },
  getPlayerMatchupsForEvent: ({ playerId, eventId }: { playerId: number; eventId: number }) => {
    return { url: `football/v1/nfl/events/${eventId}/players/${playerId}/matchups` }
  },
  getPropBetsForGame: ({ id, sportsbookId = DEFAULT_SPORTSBOOK }: { id: number; sportsbookId: string }) => {
    return { url: `football/v1/games/${id}/prop-bets?sportsbookId=${sportsbookId}` }
  },
  getPlayerPropBets: ({
    sportsbookId = DEFAULT_SPORTSBOOK,
    weekAbv,
    season = SDK_SEASON,
  }: {
    sportsbookId: string
    weekAbv: string
    season?: number
  }) => {
    return {
      url: `/football/v1/nfl/${season}/prop-bets?week=${weekAbv}&sportsbookId=${sportsbookId}&allProps=true`,
    }
  },
  getTeamGradingForGame: ({ id }: { id: number }) => {
    return { url: `football/v1/games/${id}/teams` }
  },
  getFunFacts: () => ({ url: `https://beta.pff.com/assets/fantasy-fun-facts.json` }),
  getEventsWithInsights: ({ eventIds }: { eventIds: string }) => {
    return { url: `/football/v1/insights/nfl/events?eventIds=${eventIds}` }
  },
  getDraftBigBoardData: () => ({ url: 'football/v1/draft/big-board' }),
  getDraftBigBoardMetadata: () => ({ url: 'football/v1/draft/big-board/meta' }),
  getCurrentEventWeek: () => ({ url: '/football/v1/nfl/current-week' }),
  getEventsMarketDataByWeek: ({
    sportsbookId = DEFAULT_SPORTSBOOK,
    season = SDK_SEASON,
    weekAbv,
  }: {
    sportsbookId: string
    weekAbv: string
    season?: number
  }) => {
    return {
      url: `/football/v1/nfl/betting-markets/seasons/${season}/weeks/${weekAbv}?sportsbook=${sportsbookId}`,
    }
  },
  getEventsMarketDataById: ({
    eventId,
    sportsbookId = DEFAULT_SPORTSBOOK,
  }: {
    eventId: number
    sportsbookId: string
  }) => {
    return {
      url: `/football/v1/nfl/betting-markets/events/${eventId}?sportsbook=${sportsbookId}`,
    }
  },
  getSDKEventsById: ({
    eventId,
    sportsbookId = DEFAULT_SPORTSBOOK,
    scoreData = false,
  }: {
    eventId: number
    sportsbookId?: string
    scoreData?: boolean
  }) => {
    return {
      url: `/football/v1/sdk/nfl/events/${eventId}?sportsbook=${sportsbookId}&scoreData=${scoreData}`,
    }
  },
  getSDKEventsByWeek: ({
    sportsbookId = DEFAULT_SPORTSBOOK,
    season = SDK_SEASON,
    weekAbv,
    scoreData = false,
  }: {
    sportsbookId?: string
    season?: number
    weekAbv?: string
    scoreData?: boolean
  }) => ({
    url: `/football/v1/sdk/nfl/seasons/${season}/weeks/${weekAbv}/events?sportsbook=${sportsbookId}&scoreData=${scoreData}`,
  }),
  getSDKReceivingMetrics: ({ season = SDK_SEASON, weekAbv }: SDKReceivingMetricResponse) => ({
    url: `/football/v1/sdk/nfl/seasons/${season}/weeks/${weekAbv}/receiving-metrics`,
  }),
  getSDKHalftimeReceivingMetrics: ({ season = SDK_SEASON, weekAbv }: SDKReceivingMetricResponse) => ({
    url: `/football/v1/sdk/nfl/seasons/${season}/weeks/${weekAbv}/halftime-receiving-metrics`,
  }),
  getSDKRushingMetrics: ({ season = SDK_SEASON, weekAbv }: { season: number; weekAbv: string }) => ({
    url: `/football/v1/sdk/nfl/seasons/${season}/weeks/${weekAbv}/rushing-metrics`,
  }),
  getSDKHalftimeRushingMetrics: ({ season = SDK_SEASON, weekAbv }: { season: number; weekAbv: string }) => ({
    url: `/football/v1/sdk/nfl/seasons/${season}/weeks/${weekAbv}/halftime-rushing-metrics`,
  }),
  getSDKPFFLiveSeasonPassRateNarratives: ({ season = SDK_SEASON, weekAbv }: { season: number; weekAbv: string }) => ({
    url: `/football/v1/sdk/nfl/seasons/${season}/weeks/${weekAbv}/season-pass-rate-narratives`,
  }),
  getSDKPFFPassRateNarratives: ({ season = SDK_SEASON, weekAbv }: { season: number; weekAbv: string }) => ({
    url: `/football/v1/sdk/nfl/seasons/${season}/weeks/${weekAbv}/pass-rate-narratives`,
  }),
  getSDKPFFPressureRateNarratives: ({ season = SDK_SEASON, weekAbv }: { season: number; weekAbv: string }) => ({
    url: `/football/v1/sdk/nfl/seasons/${season}/weeks/${weekAbv}/pressure-rate-narratives`,
  }),
  getSDKPastPerformanceEvents: ({
    season = SDK_SEASON,
    weekAbv,
    playerId,
  }: {
    season: number
    weekAbv: string
    playerId: string
  }) => ({
    url: `/football/v1/sdk/nfl/seasons/${season}/weeks/${weekAbv}/past-performances/latest?playerIds=${playerId}`,
  }),
  getSDKPastPerformanceSimiliarDefensesEvents: ({
    playerId,
    season = SDK_SEASON,
    weekAbv,
  }: {
    playerId: string
    season: number
    weekAbv: string
  }) => ({
    url: `/football/v1/sdk/nfl/seasons/${season}/weeks/${weekAbv}/past-performances/similar-defenses?playerIds=${playerId}`,
  }),
  getTopPlayersForEvent: ({
    eventId,
    sportsbookId = DEFAULT_SPORTSBOOK,
  }: {
    sportsbookId: string
    eventId: number
  }) => ({
    url: `/football/v1/nfl/events/${eventId}/top-player-grades?marketData=true&sportsbook=${sportsbookId}`,
  }),
  getAvailableMarketsByEvent: ({
    eventId,
    sportsbookId = DEFAULT_SPORTSBOOK,
    eventStatus = '',
  }: {
    sportsbookId: string
    eventId: number
    eventStatus?: string
  }) => ({
    url: `/football/v1/nfl/player-props/events/${eventId}/markets?sportsbook=${sportsbookId}&eventStatus=${eventStatus}`,
  }),
  getAvailableMarketsByWeek: ({
    weekAbv,
    sportsbookId,
    eventStatus = '',
    season = SDK_SEASON,
  }: {
    sportsbookId: string
    weekAbv: string
    eventStatus?: string
    season?: number
  }) => ({
    url: `/football/v1/nfl/player-props/seasons/${season}/weeks/${weekAbv}/markets?sportsbook=${sportsbookId}&eventStatus=${eventStatus}`,
  }),
  getAnalysisByBetId: ({
    sportsbookIds,
    weekAbv,
    season = SDK_SEASON,
    detailed = false,
  }: {
    sportsbookIds: string
    weekAbv: string
    season?: number
    detailed?: boolean
  }) => ({
    url: `/football/v1/sdk/nfl/seasons/${season}/weeks/${weekAbv}/player-props/analysis?sportsbookIds=${sportsbookIds}&detailed=${detailed}`,
  }),
  getPaginatedPropBetsByEvents: ({
    eventIds,
    sportsbookId = DEFAULT_SPORTSBOOK,
    market = '',
    page,
    pageSize,
  }: {
    sportsbookId: string
    eventIds: number[]
    page?: number
    pageSize?: number
    market?: string
  }) => {
    const marketQuery = market ? `&market=${market}` : ''
    const pageQuery = page ? `&page=${page}` : ''
    const pageSizeQuery = pageSize ? `&pageSize=${pageSize}` : ''
    return {
      url: `/football/v1/nfl/player-props/events/details?eventIds=${eventIds.toString()}&sportsbook=${sportsbookId}${pageSizeQuery}${pageQuery}${marketQuery}`,
    }
  },
  getSeasonSimulationResults: ({
    season = SDK_SEASON,
    teamId,
    version,
  }: {
    season?: number
    teamId: number
    version: number
  }) => ({
    url: `/football/v1/nfl/seasons/${season}/game-simulation/season-results?teamId=${teamId}&v=${version}`,
  }),
  getSelectedGameResults: ({ gameSimulationId, teamId }: { gameSimulationId: number; teamId: number }) => ({
    url: `/football/v1/game-simulation/games/${gameSimulationId}/results?teamId=${teamId}`,
  }),
  getTeamDepthChart: ({
    league = 'nfl',
    season = SDK_SEASON,
    weekAbv,
    teamId,
  }: {
    league: string
    season: number
    weekAbv: string
    teamId: number
  }) => ({
    url: `/football/v1/${league}/seasons/${season}/weeks/${weekAbv}/teams/${teamId}/depth-chart`,
  }),
  getPlayerSeasonStats: ({ season = SDK_SEASON, ids }: { season: number; ids: number[] }) => ({
    url: `/football/v1/profile/players/season-stats?season=${season}&playerIds=${ids.toString()}`,
  }),

  getPlayerInjuryStatus: ({
    league = 'nfl',
    season = SDK_SEASON,
    weekAbv,
  }: {
    league: string
    season: number
    weekAbv: string
  }) => ({
    url: `football/v1/${league}/seasons/${season}/weeks/${weekAbv}/injuries`,
  }),

  getPlayersByPositions: ({ positions, limit = 10 }: { positions: string[]; limit?: number }) => {
    return { url: `football/v1/players?positions=${positions.toString()}&limit=${limit}` }
  },

  getPlayersByTeams: ({ teamIds, limit = 10 }: { teamIds: number[]; limit?: number }) => {
    return { url: `football/v1/players?teamIds=${teamIds.toString()}&limit=${limit}` }
  },

  getGameScripts: ({
    season,
    week,
    playerIds = [],
  }: {
    season: number
    week: NFLWeekAbbreviation
    playerIds?: number[]
  }) => {
    return {
      url: `/football/v1/sdk/nfl/seasons/${season}/weeks/${week}/game-scripts?playerIds=${playerIds.toString()}`,
    }
  },

  getGamesByWeek: ({ season, team, week }: { season: number; team?: string; week: NFLWeekAbbreviation }) => {
    return { url: `football/v1/nfl/seasons/${season}/games?team=${team}&week=${week}` }
  },
}
