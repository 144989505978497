import { AddListenerOverloads } from '@reduxjs/toolkit/dist/listenerMiddleware/types'
import { isAnyOf } from '@reduxjs/toolkit'
import { consumerApi } from '../../lib/consumer-api/consumer-api'
import { fantasySlice } from '../../lib/fantasy/fantasy.slice'
import type { AppRootState } from '../store'

export const addFantasyStandaloneRankingsListeners = (startListening: AddListenerOverloads<unknown>) => {
  startListening({
    matcher: isAnyOf(
      fantasySlice.actions.updateFilterScoringType.match,
      fantasySlice.actions.updateFilterPosition.match,
      consumerApi.endpoints.getRankings.matchFulfilled
    ),
    effect: async (action, listenerApi) => {
      const state = listenerApi.getState() as AppRootState
      listenerApi.cancelActiveListeners()
      const { filters } = state.fantasy
      const prunedFilters = { ...filters }
      // Player name search and tags are filtered on FE
      Reflect.deleteProperty(prunedFilters, 'search')
      Reflect.deleteProperty(prunedFilters, 'tags')
      const { data, status } = consumerApi.endpoints.getRankings.select(prunedFilters)(state)
      const players = data?.players || []
      const lastUpdatedAt = data?.lastUpdatedAt || null
      if (status === 'fulfilled') {
        listenerApi.dispatch(fantasySlice.actions.updateRankings(players))
        listenerApi.dispatch(fantasySlice.actions.updateRankingsUpdatedTime(lastUpdatedAt))
      }
    },
  })
}
