export interface SeasonStat {
  statKey: string
  statValue: number
  rank: number
  rankTotal: number
}

export interface PlayerSeasonStat {
  playerId: number
  firstName: string
  lastName: string
  position: string
  teamId: number
  teamAbbreviation: string
  stats: SeasonStat[]
}

export enum SeasonStatPosition {
  QB = 'QB',
  RB = 'RB',
  HB = 'HB',
  FB = 'FB',
  WR = 'WR',
  TE = 'TE',
  T = 'T',
  G = 'G',
  C = 'C',
  ID = 'ID',
  ED = 'ED',
  LB = 'LB',
  CB = 'CB',
  S = 'S',
  K = 'K',
  P = 'P',
}

export enum SeasonStatKeys {
  ADOT = 'avg_depth_of_target',
  ANY_TIME_TD = 'any_time_td',
  ASSISTS = 'assists',
  AVERAGE_TIME_TO_THROW = 'avg_time_to_throw',
  ATTEMPTS = 'attempts',
  AVERAGE_STARTING_FIELD_POSITION = 'average_starting_field_position',
  BATTLED_PASSES = 'battled_passes',
  BIG_TIME_THROWS = 'big_time_throws',
  CARRIES = 'carries',
  COVERAGE_SNAPS = 'snap_counts_coverage',
  COVERAGE_SNAPS_PER_RECEPTION = 'coverage_snaps_per_reception',
  COVERAGE_SNAPS_PER_TARGET = 'coverage_snaps_per_target',
  DEFENSE_PENALITIES = 'defense_penalities',
  DEFENSE_SNAPS = 'defense_snaps',
  DOWNED = 'downeds',
  DROPBACKS = 'dropbacks',
  DROPS = 'drops',
  EXPLOSIVE_RUNS = 'explosive_runs',
  FAIR_CATCHES = 'fair_catches',
  FG_ATT = 'fg_att',
  FG_FIFTY_ATTEMPTED = 'fg_fifty_attempted',
  FG_FIFTY_MADE = 'fg_fifty_made',
  FG_MADE = 'fg_made',
  FG_PERC = 'fg_perc',
  FORCED_FUMBLES = 'forced_fumbles',
  FORCED_MISSED_TACKLES = 'forced_missed_tackles',
  FUMBLES = 'fumbles',
  HANGTIME_AVERAGE = 'average_hangtime',
  HITS = 'hits',
  HITS_ALLOWED = 'hits_allowed',
  HURRIES = 'hurries',
  INTERCEPTIONS = 'interceptions',
  KICKOFFS = 'kickoff_attempts',
  KICKOFFS_RETURNED = 'kicks_returned',
  MATCHUPS = 'matchups',
  MISSED_TACKLES = 'missed_tackles',
  NEXT_OPPONENT = 'nextOpponent',
  OFFENSE = 'offense',
  OFFENSE_PENALITIES = 'offense_penalities',
  OFFENSE_SNAPS = 'offense_snaps',
  OUT_OF_BOUNDS = 'out_of_bounds',
  OVERALL_GRADE = 'overallGrade',
  PASS_BLOCK_GRADE = 'passBlockGrade',
  PASS_BLOCK_SNAPS = 'pass_block_snaps',
  PASS_BREAK_UPS = 'pass_break_ups',
  PASS_RUSH_SNAPS = 'snap_counts_pass_rush',
  PASS_TD = 'pass_td',
  PASS_YD = 'pass_yd',
  PASSER_RATING_ALLOWED = 'passer_rating',
  PASSER_RATING_WHEN_TARGETED = 'passer_rating_when_targeted',
  PASSING_SNAPS = 'passing_snaps',
  PAT_ATTEMPTED = 'pat_attempts',
  PAT_MADE = 'pat_made',
  PERCENT_RETURNED = 'percent_returned',
  PINNED_INSIDE_TWENTY = 'inside_twenties',
  PRESSURES = 'pressures',
  PRESSURE_TO_SACK_RATE = 'pressure_to_sack_rate',
  PUNTS = 'punts',
  PUNT_GROSS_AVG = 'punt_yds_att',
  PUNT_LONG = 'punt_long',
  PUNT_YDS_ATT = 'punt_yds_att',
  RECEPTIONS = 'receptions',
  RECEPTIONS_ALLOWED = 'yards_per_reception_allowed',
  RECV_TD = 'recv_td',
  RECV_YD = 'recv_yd',
  RUN_BLOCK_GRADE = 'runBlockGrade',
  RUN_BLOCK_SNAPS = 'run_block_snaps',
  RUN_DEFENSE_SNAPS = 'snap_counts_run_defense',
  RUSH_SNAPS = 'total_rush_snaps',
  RUSH_TD = 'rush_td',
  RUSH_YD = 'rush_yd',
  SACKS = 'sacks',
  SACKS_ALLOWED = 'sacks_allowed',
  SCRAMBLES = 'scrambles',
  SCRAMBLE_YARDS = '',
  SNAPS = 'snaps',
  SNAPS_PLAYED_AT_C = 'snap_counts_c',
  SNAPS_PLAYED_AT_LG = 'snap_counts_lg',
  SNAPS_PLAYED_AT_LT = 'snap_counts_lt',
  SNAPS_PLAYED_AT_RG = 'snap_counts_rg',
  SNAPS_PLAYED_AT_RT = 'snap_counts_rt',
  SNAPS_PLAYED_AT_TE = 'snap_counts_te',
  STOPS = 'stops',
  TACKLES = 'tackles',
  TARGETS = 'targets',
  TOTAL_PRESSURES = 'total_pressures',
  TOTAL_PRESSURES_ALLOWED = 'total_pressures_allowed',
  TURNOVER_WORTHY_PLAYS = 'turnover_worthy_plays',
  TOUCHBACKS = 'touchbacks',
  YARDS_AFTER_CATCH_PER_RECEPTION = 'yards_after_catch_per_reception',
  YARDS_AFTER_CONTACT_PER_ATTEMPT = 'yards_after_contact_per_attempt',
  YARDS_PER_ATTEMPT = 'yards_per_attempt',
  YARDS_PER_CARRY = 'yards_per_carry',
  YARDS_PER_COVERAGE_SNAP = 'yards_per_coverage_snap',
  YARDS_PER_RECEPTION = 'yards_per_reception',
  YARDS_PER_RETURN = 'average_yards_per_return',
}

export const SeasonStatLabels: Record<SeasonStatKeys, string> = {
  [SeasonStatKeys.ADOT]: 'Avg Depth Of Target',
  [SeasonStatKeys.ANY_TIME_TD]: 'Touchdowns',
  [SeasonStatKeys.ASSISTS]: 'Assists',
  [SeasonStatKeys.AVERAGE_TIME_TO_THROW]: 'Avg Time To Throw',
  [SeasonStatKeys.ATTEMPTS]: 'Attempts',
  [SeasonStatKeys.AVERAGE_STARTING_FIELD_POSITION]: 'Average Starting Field Position',
  [SeasonStatKeys.BATTLED_PASSES]: 'Battled Passes',
  [SeasonStatKeys.BIG_TIME_THROWS]: 'Big Time Throws',
  [SeasonStatKeys.CARRIES]: 'Carries',
  [SeasonStatKeys.COVERAGE_SNAPS]: 'Coverage Snaps',
  [SeasonStatKeys.COVERAGE_SNAPS_PER_RECEPTION]: 'Coverage Snaps Per Reception',
  [SeasonStatKeys.COVERAGE_SNAPS_PER_TARGET]: 'Coverage Snaps Per Target',
  [SeasonStatKeys.DEFENSE_PENALITIES]: 'Defense Penalties',
  [SeasonStatKeys.DEFENSE_SNAPS]: 'Defense Snaps',
  [SeasonStatKeys.DOWNED]: 'Downeds',
  [SeasonStatKeys.DROPBACKS]: 'Dropbacks',
  [SeasonStatKeys.DROPS]: 'Drops',
  [SeasonStatKeys.EXPLOSIVE_RUNS]: 'Explosive Runs',
  [SeasonStatKeys.FAIR_CATCHES]: 'Fair Catches',
  [SeasonStatKeys.FG_ATT]: 'Field Goals Att',
  [SeasonStatKeys.FG_FIFTY_ATTEMPTED]: 'Field Goals (50+ Yds) Att',
  [SeasonStatKeys.FG_FIFTY_MADE]: 'Field Goals (50+ Yds)',
  [SeasonStatKeys.FG_MADE]: 'Field Goals Made',
  [SeasonStatKeys.FG_PERC]: 'Field Goals %',
  [SeasonStatKeys.FORCED_FUMBLES]: 'Forced Fumbles',
  [SeasonStatKeys.FORCED_MISSED_TACKLES]: 'Forced Missed Tackles',
  [SeasonStatKeys.FUMBLES]: 'Fumbles',
  [SeasonStatKeys.HANGTIME_AVERAGE]: 'Average Hangtime',
  [SeasonStatKeys.HITS]: 'Hits',
  [SeasonStatKeys.HITS_ALLOWED]: 'Hits Allowed',
  [SeasonStatKeys.HURRIES]: 'Hurries',
  [SeasonStatKeys.INTERCEPTIONS]: 'Interceptions',
  [SeasonStatKeys.KICKOFFS]: 'Kickoff Attempts',
  [SeasonStatKeys.KICKOFFS_RETURNED]: 'Kicks Returned',
  [SeasonStatKeys.MATCHUPS]: 'Matchups',
  [SeasonStatKeys.MISSED_TACKLES]: 'Missed Tackles',
  [SeasonStatKeys.NEXT_OPPONENT]: 'Next Opponent',
  [SeasonStatKeys.OFFENSE]: 'Overall Grade',
  [SeasonStatKeys.OFFENSE_PENALITIES]: 'Offense Penalties',
  [SeasonStatKeys.OFFENSE_SNAPS]: 'Offense Snaps Played',
  [SeasonStatKeys.OUT_OF_BOUNDS]: 'Out Of Bounds',
  [SeasonStatKeys.OVERALL_GRADE]: 'Overall Grade',
  [SeasonStatKeys.PASS_BLOCK_GRADE]: 'Pass Block Grade',
  [SeasonStatKeys.PASS_BLOCK_SNAPS]: 'Pass Block Snaps',
  [SeasonStatKeys.PASS_BREAK_UPS]: 'Pass Break Ups',
  [SeasonStatKeys.PASS_RUSH_SNAPS]: 'Pass Rush Snaps',
  [SeasonStatKeys.PASS_TD]: 'Passing Tds',
  [SeasonStatKeys.PASS_YD]: 'Passing Yds',
  [SeasonStatKeys.PASSER_RATING_ALLOWED]: 'Passer Rating Allowed',
  [SeasonStatKeys.PASSER_RATING_WHEN_TARGETED]: 'Passer Rating When Targeted',
  [SeasonStatKeys.PASSING_SNAPS]: 'Passing Snaps',
  [SeasonStatKeys.PAT_ATTEMPTED]: 'Pat Attempted',
  [SeasonStatKeys.PAT_MADE]: 'Pat Made',
  [SeasonStatKeys.PERCENT_RETURNED]: 'Percent Returned',
  [SeasonStatKeys.PINNED_INSIDE_TWENTY]: 'Pinned Inside Twenty',
  [SeasonStatKeys.PRESSURES]: 'Pressures',
  [SeasonStatKeys.PRESSURE_TO_SACK_RATE]: 'Pressure To Sack Rate',
  [SeasonStatKeys.PUNTS]: 'Punts',
  [SeasonStatKeys.PUNT_LONG]: 'Longest Punts',
  [SeasonStatKeys.PUNT_YDS_ATT]: 'Punt Yds Per Att',
  [SeasonStatKeys.RECEPTIONS]: 'Receptions',
  [SeasonStatKeys.RECEPTIONS_ALLOWED]: 'Receptions Allowed',
  [SeasonStatKeys.RECV_TD]: 'Rec Tds',
  [SeasonStatKeys.RECV_YD]: 'Rec Yds',
  [SeasonStatKeys.RUN_BLOCK_GRADE]: 'Run Block Grade',
  [SeasonStatKeys.RUN_BLOCK_SNAPS]: 'Run Block Snaps',
  [SeasonStatKeys.RUN_DEFENSE_SNAPS]: 'Run Defense Snaps',
  [SeasonStatKeys.RUSH_SNAPS]: 'Rush Snaps',
  [SeasonStatKeys.RUSH_TD]: 'Rush Tds',
  [SeasonStatKeys.RUSH_YD]: 'Rush Yds',
  [SeasonStatKeys.SACKS]: 'Sacks',
  [SeasonStatKeys.SACKS_ALLOWED]: 'Sacks Allowed',
  [SeasonStatKeys.SCRAMBLES]: 'Scrambles',
  [SeasonStatKeys.SCRAMBLE_YARDS]: 'Scramble Yards',
  [SeasonStatKeys.SNAPS]: 'Snaps',
  [SeasonStatKeys.SNAPS_PLAYED_AT_C]: 'Snaps Played At C',
  [SeasonStatKeys.SNAPS_PLAYED_AT_LG]: 'Snaps Played At Lg',
  [SeasonStatKeys.SNAPS_PLAYED_AT_LT]: 'Snaps Played At Lt',
  [SeasonStatKeys.SNAPS_PLAYED_AT_RG]: 'Snaps Played At Rg',
  [SeasonStatKeys.SNAPS_PLAYED_AT_RT]: 'Snaps Played At Rt',
  [SeasonStatKeys.SNAPS_PLAYED_AT_TE]: 'Snaps Played At Te',
  [SeasonStatKeys.STOPS]: 'Stops',
  [SeasonStatKeys.TACKLES]: 'Tackles',
  [SeasonStatKeys.TARGETS]: 'Targets',
  [SeasonStatKeys.TOTAL_PRESSURES]: 'Total Pressures',
  [SeasonStatKeys.TOTAL_PRESSURES_ALLOWED]: 'Total Pressures Allowed',
  [SeasonStatKeys.TURNOVER_WORTHY_PLAYS]: 'Turnover Worthy Plays',
  [SeasonStatKeys.TOUCHBACKS]: 'Touchbacks',
  [SeasonStatKeys.YARDS_AFTER_CATCH_PER_RECEPTION]: 'Yards After Catch Per Reception',
  [SeasonStatKeys.YARDS_AFTER_CONTACT_PER_ATTEMPT]: 'Yards After Contact Per Attempt',
  [SeasonStatKeys.YARDS_PER_ATTEMPT]: 'Yds Per Att',
  [SeasonStatKeys.YARDS_PER_CARRY]: 'Yds Per Carry',
  [SeasonStatKeys.YARDS_PER_COVERAGE_SNAP]: 'Yards Per Coverage Snap',
  [SeasonStatKeys.YARDS_PER_RECEPTION]: 'Yds Per Rec',
  [SeasonStatKeys.YARDS_PER_RETURN]: 'Yards Per Return',
}

export type SeasonStatSpecialKeys = SeasonStatKeys.CARRIES | SeasonStatKeys.RUSH_YD | SeasonStatKeys.PUNT_YDS_ATT

export const SeasonStatSpecialLabels: Record<SeasonStatSpecialKeys, string> = {
  [SeasonStatKeys.CARRIES]: 'Designed Run',
  [SeasonStatKeys.RUSH_YD]: 'Designed Run Yards',
  [SeasonStatKeys.PUNT_YDS_ATT]: 'Punt Gross Avg',
}

export const PlayerHeroStatsInSeason = {
  [SeasonStatPosition.QB]: [
    SeasonStatKeys.OFFENSE,
    SeasonStatKeys.PASS_YD,
    SeasonStatKeys.PASS_TD,
    SeasonStatKeys.MATCHUPS,
  ],
  [SeasonStatPosition.RB]: [
    SeasonStatKeys.OFFENSE,
    SeasonStatKeys.RUSH_YD,
    SeasonStatKeys.ANY_TIME_TD,
    SeasonStatKeys.MATCHUPS,
  ],
  [SeasonStatPosition.HB]: [
    SeasonStatKeys.OFFENSE,
    SeasonStatKeys.RUSH_YD,
    SeasonStatKeys.RUSH_TD,
    SeasonStatKeys.MATCHUPS,
  ],
  [SeasonStatPosition.FB]: [
    SeasonStatKeys.OFFENSE,
    SeasonStatKeys.RUSH_YD,
    SeasonStatKeys.ANY_TIME_TD,
    SeasonStatKeys.MATCHUPS,
  ],
  [SeasonStatPosition.WR]: [
    SeasonStatKeys.OFFENSE,
    SeasonStatKeys.RECV_YD,
    SeasonStatKeys.ANY_TIME_TD,
    SeasonStatKeys.MATCHUPS,
  ],
  [SeasonStatPosition.TE]: [
    SeasonStatKeys.OFFENSE,
    SeasonStatKeys.RECV_YD,
    SeasonStatKeys.ANY_TIME_TD,
    SeasonStatKeys.MATCHUPS,
  ],
  [SeasonStatPosition.T]: [
    SeasonStatKeys.OVERALL_GRADE,
    SeasonStatKeys.PASS_BLOCK_GRADE,
    SeasonStatKeys.RUN_BLOCK_GRADE,
    SeasonStatKeys.NEXT_OPPONENT,
  ],
  [SeasonStatPosition.G]: [
    SeasonStatKeys.OVERALL_GRADE,
    SeasonStatKeys.PASS_BLOCK_GRADE,
    SeasonStatKeys.RUN_BLOCK_GRADE,
    SeasonStatKeys.NEXT_OPPONENT,
  ],
  [SeasonStatPosition.C]: [
    SeasonStatKeys.OVERALL_GRADE,
    SeasonStatKeys.PASS_BLOCK_GRADE,
    SeasonStatKeys.RUN_BLOCK_GRADE,
    SeasonStatKeys.NEXT_OPPONENT,
  ],
  [SeasonStatPosition.ID]: [
    SeasonStatKeys.OVERALL_GRADE,
    SeasonStatKeys.DEFENSE_SNAPS,
    SeasonStatKeys.TOTAL_PRESSURES,
    SeasonStatKeys.MATCHUPS,
  ],
  [SeasonStatPosition.ED]: [
    SeasonStatKeys.OVERALL_GRADE,
    SeasonStatKeys.DEFENSE_SNAPS,
    SeasonStatKeys.TOTAL_PRESSURES,
    SeasonStatKeys.MATCHUPS,
  ],
  [SeasonStatPosition.LB]: [
    SeasonStatKeys.OVERALL_GRADE,
    SeasonStatKeys.DEFENSE_SNAPS,
    SeasonStatKeys.TACKLES,
    SeasonStatKeys.MATCHUPS,
  ],
  [SeasonStatPosition.CB]: [
    SeasonStatKeys.OVERALL_GRADE,
    SeasonStatKeys.DEFENSE_SNAPS,
    SeasonStatKeys.INTERCEPTIONS,
    SeasonStatKeys.MATCHUPS,
  ],
  [SeasonStatPosition.S]: [
    SeasonStatKeys.OVERALL_GRADE,
    SeasonStatKeys.DEFENSE_SNAPS,
    SeasonStatKeys.INTERCEPTIONS,
    SeasonStatKeys.MATCHUPS,
  ],
  [SeasonStatPosition.K]: [
    SeasonStatKeys.OFFENSE,
    SeasonStatKeys.FG_ATT,
    SeasonStatKeys.FG_PERC,
    SeasonStatKeys.MATCHUPS,
  ],
  [SeasonStatPosition.P]: [
    SeasonStatKeys.OVERALL_GRADE,
    SeasonStatKeys.PUNTS,
    SeasonStatKeys.PUNT_YDS_ATT,
    SeasonStatKeys.MATCHUPS,
  ],
}

export const PlayerHeroStatsOffSeason = {
  [SeasonStatPosition.QB]: [
    SeasonStatKeys.OFFENSE,
    SeasonStatKeys.PASS_YD,
    SeasonStatKeys.PASS_TD,
    SeasonStatKeys.INTERCEPTIONS,
  ],
  [SeasonStatPosition.RB]: [
    SeasonStatKeys.OFFENSE,
    SeasonStatKeys.PASS_YD,
    SeasonStatKeys.ANY_TIME_TD,
    SeasonStatKeys.YARDS_PER_ATTEMPT,
  ],
  [SeasonStatPosition.HB]: [
    SeasonStatKeys.OFFENSE,
    SeasonStatKeys.PASS_YD,
    SeasonStatKeys.ANY_TIME_TD,
    SeasonStatKeys.YARDS_PER_ATTEMPT,
  ],
  [SeasonStatPosition.FB]: [
    SeasonStatKeys.OFFENSE,
    SeasonStatKeys.PASS_YD,
    SeasonStatKeys.ANY_TIME_TD,
    SeasonStatKeys.YARDS_PER_ATTEMPT,
  ],
  [SeasonStatPosition.WR]: [
    SeasonStatKeys.OFFENSE,
    SeasonStatKeys.RECV_YD,
    SeasonStatKeys.ANY_TIME_TD,
    SeasonStatKeys.YARDS_PER_RECEPTION,
  ],
  [SeasonStatPosition.TE]: [
    SeasonStatKeys.OFFENSE,
    SeasonStatKeys.RECV_YD,
    SeasonStatKeys.ANY_TIME_TD,
    SeasonStatKeys.YARDS_PER_RECEPTION,
  ],
  [SeasonStatPosition.T]: [
    SeasonStatKeys.OVERALL_GRADE,
    SeasonStatKeys.PASS_BLOCK_GRADE,
    SeasonStatKeys.RUN_BLOCK_GRADE,
    SeasonStatKeys.SNAPS,
  ],
  [SeasonStatPosition.G]: [
    SeasonStatKeys.OVERALL_GRADE,
    SeasonStatKeys.PASS_BLOCK_GRADE,
    SeasonStatKeys.RUN_BLOCK_GRADE,
    SeasonStatKeys.SNAPS,
  ],
  [SeasonStatPosition.C]: [
    SeasonStatKeys.OVERALL_GRADE,
    SeasonStatKeys.PASS_BLOCK_GRADE,
    SeasonStatKeys.RUN_BLOCK_GRADE,
    SeasonStatKeys.SNAPS,
  ],
  [SeasonStatPosition.ID]: [
    SeasonStatKeys.OVERALL_GRADE,
    SeasonStatKeys.DEFENSE_SNAPS,
    SeasonStatKeys.TOTAL_PRESSURES,
    SeasonStatKeys.SACKS,
  ],
  [SeasonStatPosition.ED]: [
    SeasonStatKeys.OVERALL_GRADE,
    SeasonStatKeys.DEFENSE_SNAPS,
    SeasonStatKeys.TOTAL_PRESSURES,
    SeasonStatKeys.SACKS,
  ],
  [SeasonStatPosition.LB]: [
    SeasonStatKeys.OVERALL_GRADE,
    SeasonStatKeys.DEFENSE_SNAPS,
    SeasonStatKeys.TACKLES,
    SeasonStatKeys.PRESSURES,
  ],
  [SeasonStatPosition.CB]: [
    SeasonStatKeys.OVERALL_GRADE,
    SeasonStatKeys.DEFENSE_SNAPS,
    SeasonStatKeys.INTERCEPTIONS,
    SeasonStatKeys.PASS_BREAK_UPS,
  ],
  [SeasonStatPosition.S]: [
    SeasonStatKeys.OVERALL_GRADE,
    SeasonStatKeys.DEFENSE_SNAPS,
    SeasonStatKeys.INTERCEPTIONS,
    SeasonStatKeys.PASS_BREAK_UPS,
  ],
  [SeasonStatPosition.K]: [
    SeasonStatKeys.OFFENSE,
    SeasonStatKeys.FG_ATT,
    SeasonStatKeys.FG_PERC,
    SeasonStatKeys.FG_FIFTY_MADE,
  ],
  [SeasonStatPosition.P]: [
    SeasonStatKeys.OVERALL_GRADE,
    SeasonStatKeys.PUNTS,
    SeasonStatKeys.PUNT_YDS_ATT,
    SeasonStatKeys.PUNT_LONG,
  ],
}

export const PlayerSeasonStats = {
  [SeasonStatPosition.QB]: [
    SeasonStatKeys.ATTEMPTS,
    SeasonStatKeys.PASS_TD,
    SeasonStatKeys.PASS_YD,
    SeasonStatKeys.INTERCEPTIONS,
  ],
  [SeasonStatPosition.RB]: [
    SeasonStatKeys.CARRIES,
    SeasonStatKeys.RUSH_YD,
    SeasonStatKeys.RUSH_TD,
    SeasonStatKeys.YARDS_PER_CARRY,
  ],
  [SeasonStatPosition.HB]: [
    SeasonStatKeys.CARRIES,
    SeasonStatKeys.RUSH_YD,
    SeasonStatKeys.RUSH_TD,
    SeasonStatKeys.YARDS_PER_CARRY,
  ],
  [SeasonStatPosition.FB]: [
    SeasonStatKeys.CARRIES,
    SeasonStatKeys.RUSH_YD,
    SeasonStatKeys.RUSH_TD,
    SeasonStatKeys.YARDS_PER_CARRY,
  ],
  [SeasonStatPosition.WR]: [
    SeasonStatKeys.TARGETS,
    SeasonStatKeys.RECEPTIONS,
    SeasonStatKeys.RECV_YD,
    SeasonStatKeys.RECV_TD,
  ],
  [SeasonStatPosition.TE]: [
    SeasonStatKeys.TARGETS,
    SeasonStatKeys.RECEPTIONS,
    SeasonStatKeys.RECV_YD,
    SeasonStatKeys.RECV_TD,
  ],
  [SeasonStatPosition.T]: [
    SeasonStatKeys.OFFENSE_SNAPS,
    SeasonStatKeys.OFFENSE_PENALITIES,
    SeasonStatKeys.SACKS_ALLOWED,
  ],
  [SeasonStatPosition.G]: [
    SeasonStatKeys.OFFENSE_SNAPS,
    SeasonStatKeys.OFFENSE_PENALITIES,
    SeasonStatKeys.SACKS_ALLOWED,
  ],
  [SeasonStatPosition.C]: [
    SeasonStatKeys.OFFENSE_SNAPS,
    SeasonStatKeys.OFFENSE_PENALITIES,
    SeasonStatKeys.SACKS_ALLOWED,
  ],
  [SeasonStatPosition.ID]: [
    SeasonStatKeys.TACKLES,
    SeasonStatKeys.INTERCEPTIONS,
    SeasonStatKeys.ASSISTS,
    SeasonStatKeys.FORCED_FUMBLES,
  ],
  [SeasonStatPosition.ED]: [
    SeasonStatKeys.TACKLES,
    SeasonStatKeys.INTERCEPTIONS,
    SeasonStatKeys.ASSISTS,
    SeasonStatKeys.FORCED_FUMBLES,
  ],
  [SeasonStatPosition.LB]: [
    SeasonStatKeys.TACKLES,
    SeasonStatKeys.INTERCEPTIONS,
    SeasonStatKeys.ASSISTS,
    SeasonStatKeys.FORCED_FUMBLES,
  ],
  [SeasonStatPosition.CB]: [
    SeasonStatKeys.TACKLES,
    SeasonStatKeys.INTERCEPTIONS,
    SeasonStatKeys.TARGETS,
    SeasonStatKeys.RECEPTIONS_ALLOWED,
  ],
  [SeasonStatPosition.S]: [
    SeasonStatKeys.TACKLES,
    SeasonStatKeys.INTERCEPTIONS,
    SeasonStatKeys.TARGETS,
    SeasonStatKeys.RECEPTIONS_ALLOWED,
  ],
  [SeasonStatPosition.K]: [
    SeasonStatKeys.FG_ATT,
    SeasonStatKeys.FG_MADE,
    SeasonStatKeys.PAT_ATTEMPTED,
    SeasonStatKeys.PAT_MADE,
  ],
  [SeasonStatPosition.P]: [
    SeasonStatKeys.PUNTS,
    SeasonStatKeys.PUNT_GROSS_AVG,
    SeasonStatKeys.PUNT_LONG,
    SeasonStatKeys.PINNED_INSIDE_TWENTY,
  ],
}

export const SeasonStatsTable = {
  [SeasonStatPosition.QB]: [
    {
      title: 'Passing',
      statKeys: [
        SeasonStatKeys.PASSING_SNAPS,
        SeasonStatKeys.DROPBACKS,
        SeasonStatKeys.ATTEMPTS,
        SeasonStatKeys.PASS_YD,
        SeasonStatKeys.PASS_TD,
        SeasonStatKeys.INTERCEPTIONS,
        SeasonStatKeys.ADOT,
        SeasonStatKeys.AVERAGE_TIME_TO_THROW,
        SeasonStatKeys.PRESSURE_TO_SACK_RATE,
        SeasonStatKeys.BIG_TIME_THROWS,
        SeasonStatKeys.TURNOVER_WORTHY_PLAYS,
      ],
    },
    {
      title: 'Rushing',
      statKeys: [
        SeasonStatKeys.RUSH_SNAPS,
        SeasonStatKeys.CARRIES,
        SeasonStatKeys.RUSH_YD,
        SeasonStatKeys.SCRAMBLES,
        SeasonStatKeys.SCRAMBLE_YARDS,
        SeasonStatKeys.RUSH_TD,
      ],
    },
  ],
  [SeasonStatPosition.RB]: [
    {
      title: 'Rushing',
      statKeys: [
        SeasonStatKeys.CARRIES,
        SeasonStatKeys.RUSH_YD,
        SeasonStatKeys.RUSH_TD,
        SeasonStatKeys.YARDS_PER_ATTEMPT,
        SeasonStatKeys.FUMBLES,
        SeasonStatKeys.FORCED_MISSED_TACKLES,
        SeasonStatKeys.YARDS_AFTER_CONTACT_PER_ATTEMPT,
        SeasonStatKeys.EXPLOSIVE_RUNS,
      ],
    },
    {
      title: 'Receiving',
      statKeys: [
        SeasonStatKeys.TARGETS,
        SeasonStatKeys.RECEPTIONS,
        SeasonStatKeys.RECV_YD,
        SeasonStatKeys.RECV_TD,
        SeasonStatKeys.DROPS,
        SeasonStatKeys.FORCED_MISSED_TACKLES,
      ],
    },
  ],
  [SeasonStatPosition.FB]: [
    {
      title: 'Rushing',
      statKeys: [
        SeasonStatKeys.CARRIES,
        SeasonStatKeys.RUSH_YD,
        SeasonStatKeys.RUSH_TD,
        SeasonStatKeys.YARDS_PER_ATTEMPT,
        SeasonStatKeys.FUMBLES,
        SeasonStatKeys.FORCED_MISSED_TACKLES,
        SeasonStatKeys.YARDS_AFTER_CONTACT_PER_ATTEMPT,
        SeasonStatKeys.EXPLOSIVE_RUNS,
      ],
    },
    {
      title: 'Receiving',
      statKeys: [
        SeasonStatKeys.TARGETS,
        SeasonStatKeys.RECEPTIONS,
        SeasonStatKeys.RECV_YD,
        SeasonStatKeys.RECV_TD,
        SeasonStatKeys.DROPS,
      ],
    },
  ],
  [SeasonStatPosition.HB]: [
    {
      title: 'Rushing',
      statKeys: [
        SeasonStatKeys.CARRIES,
        SeasonStatKeys.RUSH_YD,
        SeasonStatKeys.RUSH_TD,
        SeasonStatKeys.YARDS_PER_ATTEMPT,
        SeasonStatKeys.FUMBLES,
        SeasonStatKeys.FORCED_MISSED_TACKLES,
        SeasonStatKeys.YARDS_AFTER_CONTACT_PER_ATTEMPT,
        SeasonStatKeys.EXPLOSIVE_RUNS,
      ],
    },
    {
      title: 'Receiving',
      statKeys: [
        SeasonStatKeys.TARGETS,
        SeasonStatKeys.RECEPTIONS,
        SeasonStatKeys.RECV_YD,
        SeasonStatKeys.RECV_TD,
        SeasonStatKeys.DROPS,
      ],
    },
  ],
  [SeasonStatPosition.WR]: [
    {
      title: 'Receiving',
      statKeys: [
        SeasonStatKeys.TARGETS,
        SeasonStatKeys.RECEPTIONS,
        SeasonStatKeys.RECV_YD,
        SeasonStatKeys.RECV_TD,
        SeasonStatKeys.DROPS,
        SeasonStatKeys.YARDS_PER_RECEPTION,
        SeasonStatKeys.YARDS_AFTER_CATCH_PER_RECEPTION,
        SeasonStatKeys.PASSER_RATING_WHEN_TARGETED,
        SeasonStatKeys.ADOT,
      ],
    },
    {
      title: 'Rushing',
      statKeys: [
        SeasonStatKeys.CARRIES,
        SeasonStatKeys.RUSH_YD,
        SeasonStatKeys.RUSH_TD,
        SeasonStatKeys.YARDS_PER_ATTEMPT,
        SeasonStatKeys.FORCED_MISSED_TACKLES,
      ],
    },
  ],
  [SeasonStatPosition.TE]: [
    {
      title: 'Receiving',
      statKeys: [
        SeasonStatKeys.TARGETS,
        SeasonStatKeys.RECEPTIONS,
        SeasonStatKeys.RECV_YD,
        SeasonStatKeys.RECV_TD,
        SeasonStatKeys.DROPS,
        SeasonStatKeys.YARDS_PER_RECEPTION,
        SeasonStatKeys.YARDS_AFTER_CATCH_PER_RECEPTION,
        SeasonStatKeys.PASSER_RATING_WHEN_TARGETED,
        SeasonStatKeys.ADOT,
      ],
    },
    {
      title: 'Rushing',
      statKeys: [
        SeasonStatKeys.CARRIES,
        SeasonStatKeys.RUSH_YD,
        SeasonStatKeys.RUSH_TD,
        SeasonStatKeys.YARDS_PER_ATTEMPT,
        SeasonStatKeys.FORCED_MISSED_TACKLES,
      ],
    },
  ],
  [SeasonStatPosition.T]: [
    {
      title: 'Bocking',
      statKeys: [
        SeasonStatKeys.OFFENSE_SNAPS,
        SeasonStatKeys.PASS_BLOCK_SNAPS,
        SeasonStatKeys.RUN_BLOCK_SNAPS,
        SeasonStatKeys.DEFENSE_PENALITIES,
        SeasonStatKeys.SACKS_ALLOWED,
        SeasonStatKeys.HITS_ALLOWED,
        SeasonStatKeys.TOTAL_PRESSURES_ALLOWED,
      ],
    },
    {
      title: 'Positional Pivots',
      statKeys: [
        SeasonStatKeys.SNAPS_PLAYED_AT_LT,
        SeasonStatKeys.SNAPS_PLAYED_AT_LG,
        SeasonStatKeys.SNAPS_PLAYED_AT_C,
        SeasonStatKeys.SNAPS_PLAYED_AT_RG,
        SeasonStatKeys.SNAPS_PLAYED_AT_RT,
        SeasonStatKeys.SNAPS_PLAYED_AT_TE,
      ],
    },
  ],
  [SeasonStatPosition.G]: [
    {
      title: 'Blocking',
      statKeys: [
        SeasonStatKeys.OFFENSE_SNAPS,
        SeasonStatKeys.PASS_BLOCK_SNAPS,
        SeasonStatKeys.RUN_BLOCK_SNAPS,
        SeasonStatKeys.DEFENSE_PENALITIES,
        SeasonStatKeys.SACKS_ALLOWED,
        SeasonStatKeys.HITS_ALLOWED,
        SeasonStatKeys.TOTAL_PRESSURES_ALLOWED,
      ],
    },
    {
      title: 'Positional Pivots',
      statKeys: [
        SeasonStatKeys.SNAPS_PLAYED_AT_LT,
        SeasonStatKeys.SNAPS_PLAYED_AT_LG,
        SeasonStatKeys.SNAPS_PLAYED_AT_C,
        SeasonStatKeys.SNAPS_PLAYED_AT_RG,
        SeasonStatKeys.SNAPS_PLAYED_AT_RT,
        SeasonStatKeys.SNAPS_PLAYED_AT_TE,
      ],
    },
  ],
  [SeasonStatPosition.C]: [
    {
      title: 'Blocking',
      statKeys: [
        SeasonStatKeys.OFFENSE_SNAPS,
        SeasonStatKeys.PASS_BLOCK_SNAPS,
        SeasonStatKeys.RUN_BLOCK_SNAPS,
        SeasonStatKeys.DEFENSE_PENALITIES,
        SeasonStatKeys.SACKS_ALLOWED,
        SeasonStatKeys.HITS_ALLOWED,
        SeasonStatKeys.TOTAL_PRESSURES_ALLOWED,
      ],
    },
    {
      title: 'Positional Pivots',
      statKeys: [
        SeasonStatKeys.SNAPS_PLAYED_AT_LT,
        SeasonStatKeys.SNAPS_PLAYED_AT_LG,
        SeasonStatKeys.SNAPS_PLAYED_AT_C,
        SeasonStatKeys.SNAPS_PLAYED_AT_RG,
        SeasonStatKeys.SNAPS_PLAYED_AT_RT,
        SeasonStatKeys.SNAPS_PLAYED_AT_TE,
      ],
    },
  ],
  [SeasonStatPosition.ID]: [
    {
      title: 'Blocking',
      statKeys: [
        SeasonStatKeys.TACKLES,
        SeasonStatKeys.ASSISTS,
        SeasonStatKeys.FORCED_FUMBLES,
        SeasonStatKeys.STOPS,
        SeasonStatKeys.SACKS,
        SeasonStatKeys.HURRIES,
        SeasonStatKeys.HITS_ALLOWED,
        SeasonStatKeys.TOTAL_PRESSURES,
        SeasonStatKeys.BATTLED_PASSES,
      ],
    },
    {
      title: 'Positional Pivots',
      statKeys: [SeasonStatKeys.SNAPS, SeasonStatKeys.RUN_DEFENSE_SNAPS, SeasonStatKeys.PASS_RUSH_SNAPS],
    },
  ],
  [SeasonStatPosition.ED]: [
    {
      title: 'Blocking',
      statKeys: [
        SeasonStatKeys.TACKLES,
        SeasonStatKeys.ASSISTS,
        SeasonStatKeys.FORCED_FUMBLES,
        SeasonStatKeys.STOPS,
        SeasonStatKeys.SACKS,
        SeasonStatKeys.HURRIES,
        SeasonStatKeys.HITS,
        SeasonStatKeys.TOTAL_PRESSURES,
        SeasonStatKeys.BATTLED_PASSES,
      ],
    },
    {
      title: 'Positional Pivots',
      statKeys: [SeasonStatKeys.SNAPS, SeasonStatKeys.RUN_DEFENSE_SNAPS, SeasonStatKeys.PASS_RUSH_SNAPS],
    },
  ],
  [SeasonStatPosition.LB]: [
    {
      title: 'Defense',
      statKeys: [
        SeasonStatKeys.TACKLES,
        SeasonStatKeys.ASSISTS,
        SeasonStatKeys.FORCED_FUMBLES,
        SeasonStatKeys.STOPS,
        SeasonStatKeys.SACKS,
        SeasonStatKeys.MISSED_TACKLES,
        SeasonStatKeys.PASSER_RATING_ALLOWED,
        SeasonStatKeys.YARDS_PER_RECEPTION,
        SeasonStatKeys.TOTAL_PRESSURES,
      ],
    },
    {
      title: 'Snaps',
      statKeys: [
        SeasonStatKeys.SNAPS,
        SeasonStatKeys.RUN_DEFENSE_SNAPS,
        SeasonStatKeys.PASS_RUSH_SNAPS,
        SeasonStatKeys.COVERAGE_SNAPS,
        SeasonStatKeys.YARDS_PER_COVERAGE_SNAP,
        SeasonStatKeys.COVERAGE_SNAPS_PER_TARGET,
        SeasonStatKeys.COVERAGE_SNAPS_PER_RECEPTION,
      ],
    },
  ],
  [SeasonStatPosition.CB]: [
    {
      title: 'Defense',
      statKeys: [
        SeasonStatKeys.TACKLES,
        SeasonStatKeys.ASSISTS,
        SeasonStatKeys.FORCED_FUMBLES,
        SeasonStatKeys.STOPS,
        SeasonStatKeys.SACKS,
        SeasonStatKeys.MISSED_TACKLES,
        SeasonStatKeys.INTERCEPTIONS,
        SeasonStatKeys.PASSER_RATING_ALLOWED,
        SeasonStatKeys.YARDS_PER_RECEPTION,
        SeasonStatKeys.TOTAL_PRESSURES,
        SeasonStatKeys.PASS_BREAK_UPS,
      ],
    },
    {
      title: 'Snaps',
      statKeys: [
        SeasonStatKeys.SNAPS,
        SeasonStatKeys.RUN_DEFENSE_SNAPS,
        SeasonStatKeys.PASS_RUSH_SNAPS,
        SeasonStatKeys.COVERAGE_SNAPS,
        SeasonStatKeys.YARDS_PER_COVERAGE_SNAP,
        SeasonStatKeys.COVERAGE_SNAPS_PER_TARGET,
        SeasonStatKeys.COVERAGE_SNAPS_PER_RECEPTION,
      ],
    },
  ],
  [SeasonStatPosition.S]: [
    {
      title: 'Defense',
      statKeys: [
        SeasonStatKeys.TACKLES,
        SeasonStatKeys.ASSISTS,
        SeasonStatKeys.FORCED_FUMBLES,
        SeasonStatKeys.STOPS,
        SeasonStatKeys.SACKS,
        SeasonStatKeys.MISSED_TACKLES,
        SeasonStatKeys.INTERCEPTIONS,
        SeasonStatKeys.PASSER_RATING_ALLOWED,
        SeasonStatKeys.YARDS_PER_RECEPTION,
        SeasonStatKeys.TOTAL_PRESSURES,
        SeasonStatKeys.PASS_BREAK_UPS,
      ],
    },
    {
      title: 'Snaps',
      statKeys: [
        SeasonStatKeys.SNAPS,
        SeasonStatKeys.RUN_DEFENSE_SNAPS,
        SeasonStatKeys.PASS_RUSH_SNAPS,
        SeasonStatKeys.COVERAGE_SNAPS,
        SeasonStatKeys.YARDS_PER_COVERAGE_SNAP,
        SeasonStatKeys.COVERAGE_SNAPS_PER_TARGET,
        SeasonStatKeys.COVERAGE_SNAPS_PER_RECEPTION,
      ],
    },
  ],
  [SeasonStatPosition.K]: [
    {
      title: 'Field Goals',
      statKeys: [
        SeasonStatKeys.FG_ATT,
        SeasonStatKeys.FG_MADE,
        SeasonStatKeys.FG_FIFTY_ATTEMPTED,
        SeasonStatKeys.FG_FIFTY_MADE,
        SeasonStatKeys.PAT_ATTEMPTED,
        SeasonStatKeys.PAT_MADE,
      ],
    },
    {
      title: 'Kickoffs',
      statKeys: [
        SeasonStatKeys.KICKOFFS,
        SeasonStatKeys.KICKOFFS_RETURNED,
        SeasonStatKeys.YARDS_PER_RETURN,
        SeasonStatKeys.AVERAGE_STARTING_FIELD_POSITION,
      ],
    },
  ],
  [SeasonStatPosition.P]: [
    {
      title: 'Punting',
      statKeys: [
        SeasonStatKeys.PUNTS,
        SeasonStatKeys.PUNT_GROSS_AVG,
        SeasonStatKeys.PUNT_LONG,
        SeasonStatKeys.PINNED_INSIDE_TWENTY,
        SeasonStatKeys.PERCENT_RETURNED,
        SeasonStatKeys.YARDS_PER_RETURN,
        SeasonStatKeys.TOUCHBACKS,
        SeasonStatKeys.DOWNED,
        SeasonStatKeys.OUT_OF_BOUNDS,
        SeasonStatKeys.FAIR_CATCHES,
        SeasonStatKeys.HANGTIME_AVERAGE,
      ],
    },
    {
      title: 'Kickoffs',
      statKeys: [
        SeasonStatKeys.KICKOFFS,
        SeasonStatKeys.KICKOFFS_RETURNED,
        SeasonStatKeys.YARDS_PER_RETURN,
        SeasonStatKeys.AVERAGE_STARTING_FIELD_POSITION,
      ],
    },
  ],
}
