import { NFLSvgMap } from '../../nfl-svg-map'
import { FootballTeam } from '../football-team'

export enum NFLTeamAbbreviation {
  NYG = 'NYG',
  JAX = 'JAX ',
  NO = 'NO',
  LAC = 'LAC',
  MIN = 'MIN',
  PHI = 'PHI',
  SEA = 'SEA',
  TB = 'TB',
  MIA = 'MIA',
  NYJ = 'NYJ',
  KC = 'KC',
  BUF = 'BUF',
  CHI = 'CHI',
  LV = 'LV',
  IND = 'IND',
  NE = 'NE',
  GB = 'GB',
  CIN = 'CIN',
  DET = 'DET',
  PIT = 'PIT',
  DEN = 'DEN',
  TEN = 'TEN',
  DAL = 'DAL',
  ATL = 'ATL',
  SF = 'SF',
  CAR = 'CAR',
  WAS = 'WAS',
  CLE = 'CLE',
  HOU = 'HOU',
  BAL = 'BAL',
  LAR = 'LAR',
  ARI = 'ARI',
}

export interface NFLTeam extends FootballTeam {
  conference?: 'AFC' | 'NFC'
  division?: 'North' | 'East' | 'South' | 'West'
  league: 'nfl'
}

export interface NFLTeamWithLogo extends NFLTeam {
  logo: NFLSvgMap
}
