export enum OffensePositions {
  Quarterback = 'QB',
  WideReceiver = 'WR',
  TightEnd = 'TE',
  RunningBack = 'RB',
  HalfBack = 'HB',
  Fullback = 'FB',
  RightGuard = 'RG',
  LeftGuard = 'LG',
  RightTackle = 'RT',
  LeftTackle = 'LT',
  Center = 'C',
}

export enum DefensePositions {
  InteriorDefender = 'DI',
  EdgeDefender = 'ED',
  Linebacker = 'LB',
  Cornerback = 'CB',
  Safety = 'S',
}

export type PositionGroup = 'offense' | 'defense' | 'special-teams'

export interface PositionWithGroup {
  position: OffensePositions | DefensePositions
  group: PositionGroup
}

export enum PositionFilter {
  QB = 'QB',
  RB = 'RB',
  WR = 'WR',
  TE = 'TE',
  DEF = 'D',
  K = 'K',
}

export type PositionFilterItem = {
  label: PositionFilter
  value: PositionFilter
}
