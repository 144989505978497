import FuzzySearch from 'fuzzy-search'
import type { RankingsEntity } from '../fantasy.slice'

export const getPlayersFilteredByName = (players: RankingsEntity[], searchTerm: string) => {
  // Create new searcher whenever the data changes
  const fuzzySearcher = new FuzzySearch(
    players || [],
    ['first_name', 'last_name', 'long_name', 'teamName', 'teamCity'],
    {
      caseSensitive: false,
    }
  )

  // Stop fuzzy searching breaking when users use apostrophes that are in players names, so strip them out and fuzzy will still work
  const prunedSearchTerm = searchTerm.replaceAll('’', '').replaceAll(`'`, '').replaceAll('`', '')
  const filteredResults = fuzzySearcher.search(prunedSearchTerm)
  return filteredResults
}
