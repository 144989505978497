import type { NFLSvgMap } from '@pff-consumer/schema'

type NflMap = {
  [key: string]: NFLSvgMap
}

const NFL_TEAM_NAME_MAP: NflMap = {
  ARI: 'arizona-cardinals',
  ARZ: 'arizona-cardinals',
  ATL: 'atlanta-falcons',
  BAL: 'baltimore-ravens',
  BLT: 'baltimore-ravens',
  BUF: 'buffalo-bills',
  CAR: 'carolina-panthers',
  CHI: 'chicago-bears',
  CIN: 'cincinnati-bengals',
  CLE: 'cleveland-browns',
  CLV: 'cleveland-browns',
  DAL: 'dallas-cowboys',
  DEN: 'denver-broncos',
  DET: 'detroit-lions',
  GB: 'green-bay-packers',
  HOU: 'houston-texans',
  HST: 'houston-texans',
  IND: 'indianapolis-colts',
  JAC: 'jacksonville-jaguars',
  JAX: 'jacksonville-jaguars',
  KC: 'kansas-city-chiefs',
  LV: 'las-vegas-raiders',
  LAC: 'los-angeles-chargers',
  LA: 'los-angeles-rams',
  LAR: 'los-angeles-rams',
  MIA: 'miami-dolphins',
  MIN: 'minnesota-vikings',
  NE: 'new-england-patriots',
  NO: 'new-orleans-saints',
  NYG: 'new-york-giants',
  NYGORIGINAL: 'new-york-giants-original',
  NYJ: 'new-york-jets',
  PHI: 'philadelphia-eagles',
  PIT: 'pittsburgh-steelers',
  SF: 'san-francisco-49ers',
  SEA: 'seattle-seahawks',
  TB: 'tampa-bay-buccaneers',
  TEN: 'tennessee-titans',
  WAS: 'washington-commanders',
}

export const getTeamIconSlugFromAbbreviation = (abbreviation: string, alternateSuffix = '') => {
  return NFL_TEAM_NAME_MAP?.[`${abbreviation}${alternateSuffix}`] || ''
}
