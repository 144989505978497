import { toString, reduce, keys } from 'lodash'

export const formatIfPositive = (num: number) => (num > 0 ? `+${num}` : toString(num))

// refer to https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
export const formatToOrdinalForm = (num: number) => {
  const ordinalStr = ['st', 'nd', 'rd'][((((num + 90) % 100) - 10) % 10) - 1] || 'th'
  return `${toString(num)}${ordinalStr}`
}

export const formatText = (text: string, keywords: Record<string, string>) =>
  reduce(
    keys(keywords),
    (prev, cur) => {
      let currentText = prev
      const regExp = new RegExp(`{{${cur}}}`, 'g')
      currentText = currentText.replace(regExp, keywords[cur])
      return currentText
    },
    text
  )

export const prependPlusSign = (value: string | number): string => {
  return `${Number(value) > 0 ? '+' : ''}${value}`
}

export const formatToTime = (timestamp: number, inlcudeTimezone = false, hour12 = true) => {
  return new Date(timestamp).toLocaleTimeString('en-US', {
    // en-US can be set to 'default' to use user's browser settings
    hour: 'numeric',
    minute: '2-digit',
    hour12,
    timeZoneName: inlcudeTimezone ? 'short' : undefined,
  })
}

// note- timestamp param in milliseconds. returns local time and local timezone
export const timestampToMonthDayTime = (timestamp: number) => {
  const dateObj = new Date(timestamp)
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
  const month = months[dateObj.getMonth()]
  const date = dateObj.getDate()
  const time = dateObj.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
  })
  const timeDisplay = `${month} ${date}, ${time}`
  return timeDisplay
}

/**
 * @param timestamp - milliseconds
 * @returns date time in local timezone ex: 29/4/2022, 10:10 AM
 */
export const formatToDateTime = (timestamp: number) => {
  const timeZoneFormattedDate = new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(timestamp))

  return timeZoneFormattedDate
}

export function camelCase(str: string): string {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

export function convertTimeToMinutes(time: string): number {
  const [hours, minutes] = time.split(':')
  return Number(hours) * 60 + Number(minutes)
}

/**
 * @param timestamp - milliseconds
 * @returns day and time in given  (or default 'America/New_York') timezone (2h hrs format) ex: Tuesday, 20:30
 */
export function getDayAndTime(timestamp: number, timeZone?: string): string[] {
  return new Date(timestamp)
    .toLocaleDateString('en-US', {
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: timeZone || 'America/New_York',
    })
    .split(' ')
}

/**
 * @param timestamp - milliseconds
 * @returns day and time in given (or default local) timezone ex: Fri 6:45am
 */
export function getShortDayAndTime(dateString: number, timeZone?: string): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    minute: 'numeric',
    hour: 'numeric',
    hour12: true,
  }

  if (timeZone) {
    options.timeZone = timeZone
  }

  return new Date(dateString)
    .toLocaleDateString('en-US', options)
    .replace(' AM', 'am')
    .replace(' PM', 'pm')
    .replaceAll(',', '')
}

export function getTimeGroup(time: string): string {
  const minutes = convertTimeToMinutes(time)
  // 14:00 hours
  if (minutes <= 840) return 'Early'
  // 18:00 hours
  if (minutes >= 1080) return 'Night'
  // between 14: 00 & 18:00 hours
  return 'Late'
}

export function unCamelCase(str: string): string {
  return (
    str
      // insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, (firstChar) => {
        return firstChar.toUpperCase()
      })
  )
}
